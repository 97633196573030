import React from 'react';

const RedGreenText = ({ text, value }) => {
    if (Number(value) >= 0) {
        return <span className="text-primary" style={{ fontSize: "12px" }}>{text}</span>
    } else if (Number(value) < 0) {
        return <span className="text-danger" style={{ fontSize: "12px" }}>{text}</span>
    } else return <span style={{ color: "var(--text)", fontSize: "12px" }}>{text}</span>
}

export default RedGreenText;