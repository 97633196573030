import React, { useEffect, useRef } from "react";
import { Button, Card, Divider, Drawer, Image, Input, Modal, Select, Tag, Tooltip, Upload } from "antd";
import { useState } from "react";
import { AiFillCloseCircle, AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { BiCodeAlt } from "react-icons/bi";
import { MdPublish } from "react-icons/md";
import AceEditor from 'react-ace';
import formatter from 'html-formatter';
import Editor from "./Editor";
import AdminServices from "../../services/api/AdminServices";
import { toast } from "react-toastify";
import TextArea from "antd/es/input/TextArea";
import { CardHeader } from "../../Components/Atoms/CardHeader";

const imageBaseUrl = 'https://d3etfstcuyoos5.cloudfront.net/app-images/blogimages/';

const CreateBlog = () => {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [category, setCategory] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [file, setFile] = useState();
    const [keywords, setKeywords] = useState([]);
    const [content, setContent] = useState('');
    const [tag, setTag] = useState('')
    const [allCategories, setAllCategories] = useState([]);
    const [allAuthors, setAllAuthors] = useState([{ value: "Shubham Satyarth", label: "Shubham Satyarth" }, { value: "Avinash Bhatt", label: "Avinash Bhatt" },
    { value: "Chetan Wagh", label: "Chetan Wagh" }]);
    const [allBlogs, setAllBlogs] = useState([])
    const [description, setDescription] = useState('')
    const [previewHTML, setPreviewHTML] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    const onChangeAuthor = (value) => setAuthor(value);

    const onChangeCategory = (value) => {
        setCategory(value);
    }

    const onChangeContent = (value) => {
        setContent(value);
    };

    const onChangeDescription = (value) => {
        setDescription(value);
    }

    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];
        console.log(file)
        setFile(file);
        const data = new FormData();
        data.append('file', file);
        let res = await AdminServices.uploadImg(file.name, data);
        if (res.type === true) {
            setImageUrl(imageBaseUrl + file.name);
        }
        console.log(res);
    };

    const getAllCategories = async () => {
        let res = await AdminServices.getBlogCategories().then(res => res);
        if (res.type === true) {
            setAllCategories(res.data?.filter((el) => el?.IsKnowledgeBase !== 1));
        }
    }

    const getAllBlogs = async () => {
        let res = await AdminServices.getBlogs().then(res => res);
        if (res.type === true) {
            setAllBlogs(res.data?.filter((el) => el?.IsKnowledgeBase !== 1));
        }
    }

    const handleValidations = () => {
        let temp = allBlogs.filter((item) => {
            return item.Title.toLowerCase() === title.trim().toLowerCase();
        })
        if (!title.trim()) {
            toast.warn("Enter valid title");
            return false;
        }
        else if (!author) {
            toast.warn("Choose a valid author!");
            return false;
        }
        else if (!category) {
            toast.warn(" Choose a valid category");
            return false;
        }
        else if (!content) {
            toast.warn("Content is empty!");
            return false
        }
        else if (!imageUrl) {
            toast.warn("Upload a thumbnail image");
            return false;
        }
        else if (temp.length) {
            toast.error("Blog with same title already exists!");
            return false;
        }
        else {
            return true;
        }
    }

    const handlePublish = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (handleValidations()) {
            let data = {
                title: title.trim(), categoryId: category.join('|'), subCategoryId: 0, author, content,
                keywords: keywords.join(',') || "", category: allCategories?.filter(ele => category.includes((ele?.UID)))?.map((el) => el?.Category)?.join(', ') || '',
                subCategory: '',
                description: description, isKnowledgeBase: 0, imageUrl: imageBaseUrl + file.name
            }
            let res = await AdminServices.saveBlog(data);
            if (res.type === true) {
                toast.success("Resource published.")
                await getAllBlogs();
            }
            else {
                toast.error('Somethinng went wrong!')
            }
        }
        setLoading(false);
    }

    const handleAddTag = (e) => {
        if (e.key === 'Enter') {
            setKeywords([...keywords, e.target.value])
            setTag("");
        }
    }

    const handleTagRemove = (e, i) => {
        let temp = [...keywords];
        temp.splice(i, 1);
        setKeywords(temp)
    }

    const loadData = async () => {
        await getAllCategories();
        await getAllBlogs();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (openDrawer) {
            document.getElementById('blog-preview-drawer').innerHTML = content;
        }
    }, [openDrawer])

    return (
        <React.Fragment>
            <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                <div className='d-flex justify-content-between flex-column flex-md-row ' style={{ gap: "10px" }}>
                    <CardHeader heading={'Create resource'} />
                    <div style={{ display: "flex", gap: "20px" }}>
                        <button className='btnPrimary' onClick={() => {
                            setOpenDrawer(true)
                        }}>
                            <AiFillEye className='mr-2' /> Preview
                        </button>
                        <button className='btnBlack' onClick={() => setPreviewHTML(true)}>
                            <BiCodeAlt className='mr-2' />See HTML
                        </button>
                    </div>
                </div>
                <Divider style={{ margin: "1rem 0" }} />
                <div className='mt-3'>
                    <label>Title:</label>
                    <br />
                    <Input placeholder='Title' value={title} name='title' onChange={onChangeTitle} style={{ padding: "5px 10px" }} className='textMD' />
                </div>
                <div className='mt-3'>
                    <label>Thumbnail:</label>
                    <br />
                    <input type="file" onChange={handleFileInputChange} accept="image/*" />
                    {imageUrl && <Image src={imageUrl} alt="Uploaded" style={{ width: "5rem", height: "5rem", objectFit: "contain" }} />}
                </div>
                <div className="grid-container" style={{ '--itemMinWidth': "280px", marginTop: "10px" }}>
                    <div className='mt-2'>
                        <label>
                            Author:
                        </label>
                        <br />
                        <Select placeholder='Select a author' showSearch onChange={onChangeAuthor}
                            style={{ width: "280px" }} optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                            } >
                            {allAuthors.map((item) => (
                                <Select.Option key={item.value} value={item.value}>
                                    {item.value}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className='mt-2'>
                        <label>
                            Category:
                        </label>
                        <br />
                        <Select placeholder='Select a category' showSearch onChange={onChangeCategory}
                            style={{ width: "280px" }} optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                            } mode='multiple' value={category} >
                            {allCategories.map((item) => (
                                <Select.Option key={item.UID} value={item.UID}>
                                    {item.Category}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className='mt-2'>
                        <label>
                            Keywords:
                        </label>
                        <br />
                        <div className="d-flex align-items-center flex-wrap rounded" style={{ width: "280px", gap: "5px" }}>
                            {keywords.length ? <div className="d-flex flex-wrap rounded" style={{ gap: "3px" }}>
                                {keywords.map((item, i) => (
                                    <span key={item + i} style={{ display: 'inline-block' }}>
                                        <Tag closable={true} onClose={(e) => handleTagRemove(e, i)} closeIcon={<AiFillCloseCircle color='var(--black)' />}>
                                            {item}
                                        </Tag>
                                    </span>
                                ))}
                            </div> : null}
                            <Input className='flex-grow-1 ' placeholder='Add keywords (Press Enter to add)'
                                onChange={(e) => setTag(e.target.value)} onKeyDown={handleAddTag} value={tag} />
                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    <label>Description:</label>
                    <br />
                    <TextArea className='' style={{ width: "100%", height: "100px", resize: "none", fontSize: "16px" }}
                        placeholder='Enter description' value={description} onChange={(e) => onChangeDescription(e.target.value)} />
                </div>
                <div className='mt-3'>
                    <label>Content:</label>
                    <Editor onChange={onChangeContent} />
                </div>
                <div className='mt-3'>
                    <button className='btnPrimary' onClick={handlePublish} disabled={loading}>
                        <MdPublish className='mr-2' /> Publish
                    </button>
                </div>
                <Modal onOk={() => setPreviewHTML(false)} open={previewHTML} title='HTML Content' onCancel={() => setPreviewHTML(false)}
                    zIndex={1021} bodyStyle={{}}>
                    <AceEditor
                        placeholder="Write something"
                        mode="html"
                        fontSize={14}
                        onLoad={(editor) => {
                            editor.setReadOnly(true)
                        }}
                        width='100%'
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={formatter.render(content)}
                        setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            wrap: true,
                        }} />
                </Modal>
                <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} placement='right' title='Preview Blog'
                    bodyStyle={{ color: "black", margin: "0px", padding: "0px", background: "white" }}
                    closable={true}
                    closeIcon={<AiFillCloseCircle size='24px' />} width='100%' zIndex={1021}
                >
                    <div className='ql-editor'>
                        <h2>
                            {title}
                        </h2>
                        <div id='blog-preview-drawer'>
                        </div>
                    </div>
                </Drawer>
            </Card>
        </React.Fragment>
    )
}

export default CreateBlog;
