import React from 'react';
import { AiFillBank } from 'react-icons/ai';
import { BiCategory } from 'react-icons/bi';
import { BsDot } from 'react-icons/bs';
import { FaBlogger, FaRupeeSign } from 'react-icons/fa';
import { GiGiftOfKnowledge } from 'react-icons/gi';
import { HiDocument, HiUsers } from 'react-icons/hi';
import { IoIosArrowDown } from 'react-icons/io';
import { MdDashboard, MdOutlineAttachMoney } from 'react-icons/md';
import { TbSettingsAutomation } from 'react-icons/tb';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const ItemsListRenderer = React.memo(({ items = [], activeItem, level = 1, onClose, collapsed }) => {

    const history = useHistory();
    const [activeKeys, setActiveKeys] = React.useState([]);

    return (
        <React.Fragment>
            <div style={{ gap: "5px", display: "flex", flexDirection: "column" }}>
                {items?.map((item, itemIndex) => {
                    return (
                        <div key={itemIndex}>
                            <div style={{
                                display: "flex", alignItems: "center", gap: "15px", justifyContent: collapsed ? "center" : "space-between", padding: "0.75rem 1.25rem",
                                cursor: "pointer", userSelect: "none", borderRadius: "var(--borderRadius)"
                            }} className={`${level === 1 ? "textSM" : level === 2 ? "textESM" : level === 3 ? "textXS" : ""} w-500 navMenuItem ${activeItem === `/${item?.key}` ? "navMenuItemActive" : ""}`} onClick={() => {
                                if (item?.children && Array.isArray(item?.children) && item?.children?.length > 0) {
                                    let temp = [...activeKeys];
                                    if (temp.includes(item?.key)) {
                                        temp = temp?.filter((el) => el !== item?.key);
                                    }
                                    else {
                                        temp.push(item.key)
                                    }
                                    setActiveKeys(temp);
                                }
                                else {
                                    history.push(`/${item.key}`);
                                    if (onClose) {
                                        onClose();
                                    }
                                }
                            }}>
                                <div style={{
                                    flex: 1, display: "flex", alignItems: "center", gap: "15px", color: "var(--white)", marginLeft: (level - 1) * 16,
                                    justifyContent: collapsed ? "center" : ""
                                }}>
                                    {item?.icon}
                                    {!collapsed && <div style={{ color: "var(--white)" }} className='textEllipsis1'>
                                        {item?.label}
                                    </div>}
                                </div>
                                {item?.children && Array.isArray(item?.children) && item?.children?.length > 0 && !collapsed && <IoIosArrowDown color='var(--white)' style={{
                                    transition: "all 0.25s linear", transform: activeKeys?.includes(item?.key) ? "rotate(180deg)" : ""
                                }} />}
                            </div>
                            {item?.children && Array.isArray(item?.children) && item?.children?.length > 0 && !collapsed && <div style={{
                                transition: "all 0.25s linear", maxHeight: activeKeys?.includes(item?.key) ? 1000 : 0, overflow: "hidden",
                            }}>
                                <div style={{ padding: "0.25rem 0" }}>
                                    <ItemsListRenderer items={item?.children} activeItem={activeItem} level={level + 1} onClose={onClose} collapsed={collapsed} />
                                </div>
                            </div>}
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
})

const SidebarMain = ({ onClose, collapsed }) => {
    const history = useHistory();
    const { ifaData } = useAuth();

    const items = [
        getItem('Dashboard', 'dashboard', <MdDashboard />),
        getItem('Clients', 'clients', <HiUsers />, [
            getItem('Manage Clients', 'clients/list', <BsDot />),
            getItem('Paid Clients Dashboard', 'clients/paid-clients-dashboard', <FaRupeeSign />)
        ]),
        getItem('Banks', 'banks', <AiFillBank />, [
            getItem('Bank NPA', 'banks/banknpa', <BsDot />)
        ]),
        getItem('Knowledge Base', 'knowledgeBase', <GiGiftOfKnowledge />, [
            getItem('Create Knowledge Base Doc', 'knowledgeBase/create', <BsDot />),
            getItem('Create Category', 'knowledgeBase/create-category', <BiCategory />),
            // getItem('Edit Category', 'knowledgeBase/edit-category', <BsDot />),
            getItem('Create Sub-Category', 'knowledgeBase/create-subcategory', <BsDot />),
            getItem('Create Resource', 'knowledgeBase/create-resource', <BsDot />),
            getItem('All Resources', 'knowledgeBase/all-resources', <BsDot />),
        ]),
        getItem('Blogs', 'blogs', <FaBlogger />, [
            getItem('Create Category', 'blogs/create-category', <BiCategory />),
            getItem('All Categories', 'blogs/all-categories', <BsDot />),
            getItem('Create Blog', 'blogs/create-blog', <BsDot />),
            getItem('All Blogs', 'blogs/all-blogs', <BsDot />)
        ]),
        getItem('sharpely', 'sharpely', <BsDot />, [
            getItem('Functions Docs', 'sharpely/functions-docs', <HiDocument />)
        ]),
        getItem('Automation', 'automation', <TbSettingsAutomation />)
    ]

    const otherItems = [getItem('Clients', 'clients', <HiUsers />, [
        getItem('Manage Clients', 'clients/list', <BsDot />)
    ]),]

    return (
        <React.Fragment>
            <div style={{ padding: "0.25rem" }}>
                <ItemsListRenderer items={ifaData?.email?.endsWith('@mintbox.ai') ? items : otherItems} activeItem={history.location.pathname} onClose={onClose}
                    collapsed={collapsed} />
            </div>
        </React.Fragment>
    );
};

export default SidebarMain;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
