import { Card, Divider, Input, Select } from "antd";
import ActivityLoaderMintbox from "Assets/styles/components/ActivityLoaderMintbox";
import React from "react";
import { toast } from "react-toastify";
import { CardHeader } from "../../Components/Atoms/CardHeader";
import { convertToJson, decodeData } from "../../libs/utils";
import AdminServices from "../../services/api/AdminServices";
import AdminServicesPY from "../../services/api/AdminServicesPY";

export default function BankNPA() {

    const [quarter, setQuarter] = React.useState();
    const [allBanks, setAllBanks] = React.useState([]);
    const [selectedBank, setSelectedBank] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [updating, setUpdating] = React.useState(false);

    const initFormData = {
        gross_npa: null, net_npa: null, gross_npa_per: null, net_npa_per: null,
        // gross_total_asset: 0, net_total_asset: 0, 
        casa_ratio: null, net_int_margin_ttm: null, car_ratio: null
    }

    const [formData, setFormData] = React.useState(initFormData);

    const formFields = [
        { label: "Gross NPA", key: "gross_npa" },
        { label: "Net NPA", key: "net_npa" },
        { label: "Gross NPA (%)", key: "gross_npa_per" },
        { label: "Net NPA (%)", key: "net_npa_per" },
        // { label: "Gross total asset", key: "gross_total_asset" },
        // { label: "Net total asset", key: "net_total_asset" },
        { label: "CASE ratio", key: "casa_ratio" },
        { label: "Net interest margin TTM", key: "net_int_margin_ttm" },
        { label: "Car ratio", key: "car_ratio" },
    ]

    const loadData = async () => {
        try {
            let res = await AdminServicesPY.getStocks();
            if (res.type === true) {
                let d = JSON.parse(convertToJson(decodeData(res.data)));
                d = d?.filter((el) => el.gen_ind_code === 4) || [];
                console.log(d);
                setAllBanks(d);
            }
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    let quarterOptions = [];

    for (let i = 2023; i <= 2030; i++) {
        quarterOptions.push({ value: `31/03/${i}`, key: `${i}-03-31 00:00:00`, label: `31st Mar, ${i}` });
        quarterOptions.push({ value: `30/06/${i}`, key: `${i}-06-30 00:00:00`, label: `30th Jun, ${i}` });
        quarterOptions.push({ value: `30/09/${i}`, key: `${i}-09-30 00:00:00`, label: `30th Sep, ${i}` });
        quarterOptions.push({ value: `31/12/${i}`, key: `${i}-12-31 00:00:00`, label: `31st Dec, ${i}` });
    }

    const handleUpdate = async () => {
        setUpdating(true);
        try {
            let d = { ...formData, symbol: selectedBank, quarter_end: quarter };
            // console.log(JSON.stringify(d));
            let res = await AdminServices.insUpBanksNPA(JSON.stringify(d));
            console.log(res);
            if (res?.type === true) {
                toast.success('Updated.');
                let temp = [...allBanks];
                temp = temp.map((el) => {
                    if (el.symbol === selectedBank) {
                        return {
                            ...el,
                            ...formData
                        }
                    }
                    return el;
                })
            }
        }
        catch (err) {
            console.log(err);
            toast.error('Something went wrong. Try again!')
        }
        setUpdating(false);
    }

    console.log(formData, quarter, selectedBank);

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, [])
    return (
        <React.Fragment>
            <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                <CardHeader heading={'Bank NPA'} />
                <Divider style={{ margin: "1rem 0" }} />
                {loading ? <ActivityLoaderMintbox /> :
                    <div>
                        <div className="d-flex align-items-center" style={{ gap: "1.25rem" }}>
                            <div>
                                <label className="textSM w-400 dark4">Select quarter:</label>
                                <br />
                                <Select style={{ minWidth: "20rem" }} placeholder='Select quarter' value={quarter} options={quarterOptions} onChange={e => setQuarter(e)} />
                            </div>
                            <div>
                                <label className="textSM w-400 dark4">Select bank:</label>
                                <br />
                                <Select style={{ minWidth: "20rem" }} placeholder='Select bank' value={selectedBank} onChange={e => setSelectedBank(e)} showSearch filterOption={(input, option) => {
                                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }} optionFilterProp="children" >
                                    {allBanks?.map((el) => {
                                        return (
                                            <Select.Option key={el?.symbol} value={el.symbol}>
                                                {el?.proper_name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>

                        <div className="grid-container margin20Top" style={{ '--itemMinWidth': "280px" }} >
                            {formFields?.map((field) => {
                                return (
                                    <div key={field?.key}>
                                        <label className="textSM dark4">{field?.label}</label>
                                        <br />
                                        <Input type="number" value={formData?.[field?.key]} onChange={(e) => {
                                            let v = e?.target?.value;
                                            let temp = { ...formData, [field?.key]: v ? Number(v) : null };
                                            setFormData(temp);
                                        }} />
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <button className="btnPrimary" disabled={updating} onClick={handleUpdate}>Update</button>
                        </div>
                    </div>}
            </Card>
        </React.Fragment>
    )
}