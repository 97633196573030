import React from "react";
import { Button, Divider, Popover } from "antd"
import { FaUserCircle } from "react-icons/fa"
import { AiOutlineLogout } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const ProfilePopover = ({ ifaData }) => {
    const history = useHistory();
    const { setIsAuth } = useAuth();
    const content = (
        <div style={{ wordBreak: "break-word" }}>
            <h4>{ifaData.name}</h4>
            <p>{ifaData.email}</p>
            <p>MXIFA{ifaData.id}</p>
            <Divider className='mt-1 mb-1' />
            <Button type='primary' style={{ width: "100%" }}
                onClick={() => { localStorage.clear(); setIsAuth(false); history.push('/login'); }}>
                <AiOutlineLogout className='mr-2' />
                Logout
            </Button>
        </div>
    );
    return (
        <Popover content={content} placement='bottomRight' trigger='hover' showArrow={false}>
            <FaUserCircle size='23px' cursor='pointer' />
        </Popover>
    )
}

export default ProfilePopover;