import { Card, Divider, Spin } from "antd";
import { CurrencyFormat } from "Assets/styles/components/CurrencyFormat";
import FundCard from "Assets/styles/components/FundCard";
import GoalIcon from "Assets/styles/components/GoalIcon";
import NotFound from "Assets/styles/components/NotFound";
import RedGreenText from "Assets/styles/components/RedGreenText";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { CgArrowBottomLeft, CgArrowTopRight } from "react-icons/cg";
import { FaCloudDownloadAlt, FaUserAlt } from "react-icons/fa";
import { HiUserGroup } from 'react-icons/hi';
import { ImProfile } from 'react-icons/im';
import { IoMdBriefcase } from 'react-icons/io';
import { MdEmail, MdOutlinePhoneIphone } from 'react-icons/md';
import { toast } from "react-toastify";
import { CardHeader } from "../../Components/Atoms/CardHeader";
import AdminServices from "../../services/api/AdminServices";
import CustomSegmentedTab from "../Components/CustomSegmentedTabs";
import AssetAllocationChart from "./Charts/AssetAllocationChart";
import ClientInfo from "./ClientInfo";

export default function ClientDetails(props) {

    const [clientData, setClientData] = React.useState({})
    const [portfolio, setPortfolio] = React.useState({});
    const [portfolioPerformance, setPortfolioPerformance] = React.useState({});
    const [assetAllocation, setAssetAllocation] = React.useState([]);
    const [portfolioXIRR, setPortfolioXIRR] = React.useState({});
    const [transactions, setTransactions] = React.useState([]);

    const [loading, setLoading] = React.useState(true);
    const [downloadingTransactionReport, setDownloadingTransactionReport] = React.useState(false);
    const [downloadingPortfolioReport, setDownloadingPortfolioReport] = React.useState(false);

    const CLIENT_ID = props.match.params.id

    React.useEffect(() => {
        loadData(CLIENT_ID);
    }, []);

    const loadData = async (id) => {
        await getClientData(id)
        await getPortfolio(id)
        await getPortfolioXIRR(id)
        await getPortfolioPerformance(id)
        await getPortfolioAssetAllocation(id)
        await getTransactions(id)
        setLoading(false)
    };

    const getClientData = async (id) => {
        let res = await AdminServices.getClient(id).then(res => res);
        if (res.type === true) {
            const a = JSON.parse(res.data);
            let aa = a.reduce((aa, f) => { return { ...aa, ...f } }, {})
            console.log("Get Client API :", aa)
            setClientData(aa)
        }
    };

    const getPortfolio = async (id) => {
        try {
            let res = await AdminServices.getPortfolio(id).then(res => res);
            console.log("Get portfolio api:", res)
            setPortfolio(res.Portfolio)
        }
        catch (err) {
            toast.error("Something went wrong!");
            console.log(err);
        }
    }

    const getPortfolioXIRR = async (id) => {
        try {
            let res = await AdminServices.getPortfolioXIRRv1(id).then(res => res);
            console.log("Get portfolio XIRR API :", res)
            setPortfolioXIRR(res)
        }
        catch (err) {
            toast.error("Something went wrong!");
            console.log(err);
        }
    }

    const getPortfolioPerformance = async (id) => {
        try {
            let res = await AdminServices.getPortfolioPerformancev1(id).then(res => res);
            console.log("Get portfolioPerformance API :", res)
            setPortfolioPerformance(res)
        }
        catch (err) {
            toast.error("Something went wrong!");
            console.log(err);
        }
    }

    const getPortfolioAssetAllocation = async (id) => {
        try {
            let res = await AdminServices.getPortfolioAssetAllocation(id).then(res => res);
            console.log("Get portfolio asset allocation API :", res)
            formatAssetAllo(res)
        }
        catch (err) {
            toast.error("Something went wrong!");
            console.log(err);
        }
    }

    const formatAssetAllo = (aa) => {
        const assetClasses = [
            {
                name: 'Indian Equity',
                color: 'var(--indianEquity)'
            },
            {
                name: 'Gold',
                color: 'var(--gold)'
            },
            {
                name: 'International Equity',
                color: 'var(--internationalEquity)'
            },
            {
                name: 'Debt',
                color: 'var(--debt)'
            },
            {
                name: 'Cash',
                color: 'var(--cash)'
            },
            {
                name: 'Silver',
                color: 'var(--silver)'
            }
        ];
        let percentArr = []
        const totalAllocation = aa.reduce((total, r) => total + r.AssetBalance, 0)
        // aa.forEach((item) => console.log(item.AssetClass))
        if (aa.length !== 0) {
            assetClasses.forEach(a => {
                const subTotal = aa.filter(f => f.AssetClass === a.name).reduce((total, r) => total + r.AssetBalance, 0) * 100 / totalAllocation
                if (subTotal) {
                    percentArr.push({ ...a, y: Number(subTotal.toFixed(2)) })
                }
            })
        }
        setAssetAllocation(percentArr)
    }

    const getTransactions = async (id) => {
        try {
            let res = await AdminServices.getTransactions(id).then(res => res);
            console.log("Get transactions API :", res)
            setTransactions(res)
        }
        catch (err) {
            toast.error("Something went wrong!");
            console.log(err);
        }
    }

    const getDayChange = (currentBalance, previousBalance) => {
        let change = 0;
        let changePercentage = 0;
        if (previousBalance) {
            change = currentBalance - previousBalance;
            changePercentage = change * 100 / previousBalance;
        }
        return ([change.toFixed(1), changePercentage.toFixed(1)])
    }

    const onClickPortfolioReport = async () => {
        if (downloadingPortfolioReport) {
            return
        }
        setDownloadingPortfolioReport(true);
        const res = await AdminServices.getPortfolioReport(CLIENT_ID).then(res => res)
        console.log(res)
        if (res.type) {
            window.open(res.data.Report_URL)
            setDownloadingPortfolioReport(false)
        } else {
            toast.error('Report not found')
            setDownloadingPortfolioReport(false)
        }
    }

    const onClickTransactionReport = async () => {
        if (downloadingTransactionReport) {
            return
        }
        setDownloadingTransactionReport(true);
        const res = await AdminServices.getTransactionReport(CLIENT_ID).then(res => res)
        console.log(res)
        if (res.type) {
            window.open(res.data.Report_URL)
            setDownloadingTransactionReport(false)
        } else {
            toast.error('Report not found')
            setDownloadingTransactionReport(false)
        }
    }

    const Portfolio = () => {

        const yourMintboxes = () => {
            return (
                <div className="preview-list">
                    {
                        portfolio.MintboxConsolidated?.some(m => m.GoalId === 0 && m.MintboxBalance !== 0) ?
                            portfolio.MintboxConsolidated?.filter(m => m.GoalId === 0 && m.MintboxBalance !== 0)?.map((data) => {
                                const mintboxConstituents = portfolio?.MintboxETF?.filter(f => f.MintboxId === data.MintboxId && f.GoalId === 0 && f.MintboxETFBalance !== 0).map((item) => {
                                    item.CurrentWeightage = item.MintboxETFBalance * 100 / data.MintboxBalance
                                    return item
                                })
                                return <FundCard key={data.MintboxId}
                                    onClick={() => props.history.push({
                                        pathname: '/portfolio/mintboxScreen',
                                        state: { MINTBOXID: data.MintboxId, FUNDDATA: data }
                                    })}
                                    fundName={data.MintboxName}
                                    mintboxId={data.MintboxId}
                                    balance={data.MintboxBalance}
                                    previousBalance={portfolio?.PrevMintboxConsolidated?.find(m => m.MintboxId === data.MintboxId)?.PrevMintboxBalance}
                                    xirr={Object.values(portfolioXIRR?.MintboxXIRR || {}).find(m => m.MintboxId === data.MintboxId)?.XIRR}
                                    goalName={data.GoalName}
                                    mintboxConstituents={mintboxConstituents}
                                />
                            })
                            :
                            <NotFound type='mintbox' text="You don't have any investments in mintboxes" buttonText='Invest in mintboxes' onClick={() => { props.history.push({ pathname: "/list", state: { type: 3 } }) }} />}
                </div>
            )
        }

        const etf = () => {
            return (
                <div className="preview-list">
                    {
                        portfolio.ETFConsolidated?.some(e => e.ETFBalance !== 0) ?
                            portfolio.ETFConsolidated?.filter(f => f.ETFBalance !== 0)?.map((data) => (
                                <FundCard key={data.FundCode}
                                    onClick={() => props.history.push({
                                        pathname: '/portfolio/etfScreen',
                                        state: { FUNDCODE: data.FundCode, MINTBOXID: 0, TYPE: 'ETFConsolidated', FUNDDATA: data }
                                    })}
                                    fundName={data.ETFName}
                                    AMCName={data.AMCName}
                                    balance={data.ETFBalance}
                                    previousBalance={portfolio?.PrevETFConsolidated?.find(m => m.FundCode === data.FundCode)?.PrevETFBalance}
                                    xirr={Object.values(portfolioXIRR?.ETFXIRR || {}).find(m => m.FundCode === data.FundCode)?.XIRR}
                                    type={1}
                                />
                            ))
                            :
                            <NotFound text="No investments in ETFs" buttonText='Invest in ETFs' onClick={() => { props.history.push({ pathname: "/list", state: { type: 1 } }) }} />
                    }
                </div>
            )
        }

        const funds = () => {
            return (
                <div className="preview-list">
                    {
                        portfolio.FundsConsolidated?.some(f => f.MFBalance !== 0) ?
                            portfolio.FundsConsolidated?.filter(f => f.MFBalance !== 0)?.map((data) => (
                                <FundCard key={data.FundCode}
                                    onClick={() => props.history.push({
                                        pathname: '/portfolio/fundScreen',
                                        state: { FUNDCODE: data.FundCode, FOLIO: data.FolioNumber, FUNDDATA: data }
                                    })}
                                    fundName={data.SchemeName}
                                    AMCName={data.AMCName}
                                    balance={data.MFBalance}
                                    previousBalance={portfolio?.PrevFundsConsolidated?.find(m => m.FundCode === data.FundCode)?.PrevMFBalance}
                                    xirr={Object.values(portfolioXIRR?.MFXIRR || {}).find(m => m.FundCode === data.FundCode)?.XIRR}
                                    type={2}
                                />
                            ))
                            :
                            <NotFound text="No investments in mutual funds" buttonText='Invest in mutual funds' onClick={() => { props.history.push({ pathname: "/list", state: { type: 2 } }) }} />
                    }
                </div>
            );
        }

        const goals = () => {
            return (
                <div className="preview-list" style={{ marginTop: "2%" }}>
                    {
                        portfolio.MintboxConsolidated?.some(m => m.GoalId !== 0 && m.MintboxBalance !== 0) ?
                            portfolio.MintboxConsolidated?.filter(m => m.GoalId !== 0 && m.MintboxBalance !== 0).map((goal, i) => {
                                const percentComplete = Number(goal.MintboxBalance * 100 / goal.GoalTargetCorpus)
                                return (
                                    <div key={i} style={{ padding: "4%", borderBottom: "1px solid var(--border)", display: "flex", gap: "4%" }} onClick={() => { props.history.push({ pathname: '/portfolio/goalScreen', state: { GOALID: goal.GoalId, MINTBOXID: goal.MintboxId, GOALDATA: goal } }) }}>
                                        {/* <MintboxIcon risk={goal.RiskLevel} width='40px' height='40px' /> */}
                                        <GoalIcon type={goal.GoalType} width='40px' height='40px' />
                                        <div style={{ flex: 1 }}>
                                            <div className="header2dark" style={{ display: 'flex', gap: "4%" }}>
                                                <div style={{ flex: 1 }}>{goal.GoalName}</div>
                                                <div>{CurrencyFormat(goal.MintboxBalance)} <AiOutlineRight size={14} className='secondaryGreen' style={{ marginLeft: '4px' }} /></div>
                                            </div>
                                            <ProgressBar now={percentComplete} label={<span>&nbsp;&nbsp;{percentComplete.toFixed(0)}% complete</span>}
                                                className="progress-bar-goals" style={{ margin: "3% 0" }} />
                                            <div className="smallTextLight" style={{ display: 'flex', gap: "4%" }}>
                                                <div style={{ flex: 1 }}>Due {new Date(goal.GoalTargetDate).toLocaleDateString('en-us', { year: "numeric", month: "short" })}</div>
                                                <div>Target: {CurrencyFormat(goal.GoalTargetCorpus, 'short')}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <NotFound type="goal" text="No active goal investments" buttonText='Create a Goal' onClick={() => { props.history.push('/Goals') }} />
                    }
                </div>
            );
        }

        const growth = () => {
            const options = {
                chart: {
                    backgroundColor: 'transparent'
                },
                boost: {
                    useGPUTranslations: true,
                    seriesThreshold: 5
                },

                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxHeight: 100
                        }
                    }]
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    // min: Math.min.apply(Math, growthChartData.map(o => o[1])),
                    startOnTick: true
                    // visible: false
                },

                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },

                    labels: {
                        step: 2
                    },
                    crosshair: {
                        width: 1,
                        color: 'grey'
                    },
                    // endOnTick: true,

                    //  categories: [''],
                    // visible: false
                }
                ],

                legend: {
                    enabled: false
                },

                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
                    }
                },
                plotOptions: {
                    series: {
                        turboThreshold: 51000,//larger threshold or set to 0 to disable
                        marker: {
                            enabled: false
                        }
                    }

                },
                tooltip: {
                    shared: true
                },
                credits: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                series: [{
                    name: 'Balance',
                    color: 'teal',
                    data: portfolioPerformance?.Portfolio?.map(d => [new Date(d["NavDateStr"]).getTime(), d["Balance"]])
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                }
            };
            return (
                <div style={{}}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />

                </div>)
        }

        const tabItems = [
            { label: "Mintboxes", key: "av", "value": 0, children: yourMintboxes() },
            { label: "ETFs", children: etf(), key: "b", value: 1 },
            { label: "Mutual Funds", children: funds(), key: "c", value: 2 },
            { label: "Goals", children: goals(), key: "d", value: 3 },
            { label: "Growth", children: growth(), key: "e", value: 4 },
        ]
        const [selectedTab, setSelectedTab] = React.useState(tabItems[0].value);

        return (
            <Card style={{ padding: 0, background: "var(--grey4)" }} bodyStyle={{ padding: "1rem" }} className=''>
                <CustomSegmentedTab options={tabItems} value={selectedTab} onChange={(e) => setSelectedTab(e)} isBlock={false} style={{ padding: 0 }} />
                <div className='margin20Top'>
                    {tabItems?.find((ele) => ele.value === selectedTab)?.children}
                </div>
            </Card>
        )
    }

    // const Transactions = () => {
    //     const trx = (type) => {
    //         return (
    //             <div className="preview-list">
    //                 {
    //                     type === 1 ?
    //                         transactions.filter(t => t.FundType === 1 && t.MintboxId === 0).length ?
    //                             transactions.filter(t => t.FundType === 1 && t.MintboxId === 0).reverse().map((trx, i) => {
    //                                 return <TransactionCard trx={trx} i={i} type={1} key={i} />
    //                             })
    //                             :
    //                             <NotFound text="No transactions" />
    //                         :
    //                         type === 2 ?
    //                             transactions.filter(t => t.FundType === 2).length ?
    //                                 transactions.filter(t => t.FundType === 2).reverse().map((trx, i) => {
    //                                     return <TransactionCard trx={trx} i={i} type={2} key={i} />
    //                                 })
    //                                 :
    //                                 <NotFound text="No transactions" />
    //                             :
    //                             type === 3 ?
    //                                 transactions.filter(t => t.FundType === 1 && t.MintboxId !== 0).length ?
    //                                     transactions.filter(t => t.FundType === 1 && t.MintboxId !== 0).reverse().map((trx, i) => {
    //                                         return <TransactionCard trx={trx} i={i} type={1} />
    //                                     })
    //                                     :
    //                                     <NotFound text="No transactions" />
    //                                 :
    //                                 type === 4 ?
    //                                     transactions.filter(t => t.GoalId !== 0).length ?
    //                                         transactions.filter(t => t.GoalId !== 0).reverse().map((trx, i) => {
    //                                             return <TransactionCard trx={trx} i={i} type={4} key={i} />
    //                                         })
    //                                         :
    //                                         <NotFound text="No transactions" type="goal" />
    //                                     :
    //                                     <></>
    //                 }
    //             </div>
    //         )
    //     }
    //     const keyTitles = [
    //         { title: "Mintboxes", view: trx(3) },
    //         { title: "ETFs", view: trx(1) },
    //         { title: "Mutual Funds", view: trx(2) },
    //         { title: "Goals", view: trx(4) }
    //     ];

    //     return <AdminTabs tabsObject={keyTitles} />
    // }

    const ScheduledOrders = () => {

        // const SIPs = (TYPE) => {
        //     const [selectedScheduleItem, setSelectedScheduleOption] = React.useState({})

        //     const [ORDERS, setORDERS] = React.useState([]);

        //     React.useEffect(()=>{
        //       if (TYPE === 3) {
        //         setORDERS(portfolio.ScheduledOrders.filter(s => s.MintboxId !== 0 && s.GoalId === 0))
        //       } else if (TYPE === 4) {
        //           setORDERS(portfolio.ScheduledOrders.filter(s => s.GoalId !==))
        //       } else {
        //           setORDERS(portfolio.ScheduledOrders.filter(s => s.ToFundCode !== FUNDCODE))
        //       }
        //     },[])
        //     return (
        //         <div style={{ marginTop: '2%' }}>
        //             {
        //                 ORDERS?.length === 0 ?
        //                     <NotFound text="You don't have any active SIPs" buttonText='Start SIP now' />
        //                     :
        //                     ORDERS?.map((t, i) => {
        //                         return (
        //                             <>
        //                                 <div key={i} style={{ padding: "4%", borderBottom: '1px solid ' + cssVar.getPropertyValue('--light') }}>
        //                                     <div className='chip-sip' style={{ marginBottom: "1%" }}>
        //                                         <BsDot size={24} color={t.IsDeleted === 1 ? cssVar.getPropertyValue('--red') : t.IsActive === 0 ? cssVar.getPropertyValue('--gold') : cssVar.getPropertyValue('--secondaryGreen')} style={{ marginLeft: "-10px" }} />
        //                                         {t.IsDeleted === 1 ? 'Stopped' : t.IsActive === 0 ? 'Paused' : 'Active'}
        //                                     </div>
        //                                     <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
        //                                         <div style={{ flex: 1 }}>
        //                                             <div className='smallTextLight' style={{ marginBottom: "1%" }}>
        //                                                 {ordinal_suffix_of(t.OrderDate)} of every {t.Frequency === 3?'three':t.Frequency===6?'six':t.Frequency===1?'twelve':''} month(s)
        //                                             </div>
        //                                             <div className='header1dark'>{CurrencyFormat(t.Amount)}</div>
        //                                         </div>
        //                                         <div className='header2dark secondaryGreen'
        //                                             onClick={() => {
        //                                                 setSelectedScheduleOption(t)
        //                                                 setShowModifySipModal(true);
        //                                             }}>Modify <AiOutlineDown size={12} /></div>
        //                                     </div>
        //                                 </div>
        //                             </>
        //                         )
        //                     })
        //             }
        //         </div>
        //     )
        // }

        // const keyTitles = [
        //   { title: "Mintboxes", view: SIPs(3) },
        //   { title: "ETFs", view: SIPs(1) },
        //   { title: "Mutual Funds", view: SIPs(2) },
        //   { title: "Goals", view: SIPs(4) }
        // ];

        // return <AdminTabs tabsObject={keyTitles} />
        return <NotFound type='mintbox' text="You don't have any scheduled orders" />
    }

    const tabItems = [
        { label: "Portfolio", key: "portfolio", value: 1, children: <Portfolio /> },
        { label: "Scheduled orders", key: "Scheduled orders", value: 2, children: <ScheduledOrders /> },
        { label: "Client Info", key: "Client Info", value: 3, children: ClientInfo(clientData) }
    ]

    const [selectedTab, setSelectedTab] = React.useState(tabItems[0].value);
    // if (loading) {
    //     return <ActivityLoaderMintbox />
    // }
    return (
        <React.Fragment>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))", gap: "1.25rem" }}>
                <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h3 className="mb-0 textMD w-500">
                            {CurrencyFormat(portfolio?.PortfolioBalance)}
                        </h3>
                        <IoMdBriefcase color='darkgreen' style={{ background: "lightgreen", padding: "7px", borderRadius: "4px" }}
                            size='34px' />
                    </div>
                    <div className='textSM dark3'>
                        Portfolio Balance
                    </div>
                </Card>
                <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center' style={{ gap: "5px" }}>
                            <h3 className="mb-0 textMD w-500">
                                {getDayChange(portfolio?.PortfolioBalance, portfolio?.PrevPortfolioBalance)[0]}
                            </h3>
                            <RedGreenText text={getDayChange(portfolio?.PortfolioBalance, portfolio?.PrevPortfolioBalance)[1] + '%'} value={getDayChange(portfolio?.PortfolioBalance, portfolio?.PrevPortfolioBalance)[1]} />
                        </div>
                        {getDayChange(portfolio?.PortfolioBalance, portfolio?.PrevPortfolioBalance)[0] >= 0 ?
                            <CgArrowTopRight color='green' size='30px' style={{
                                background: "lightgreen",
                                borderRadius: "5px",
                                padding: "4px"
                            }} /> :
                            <CgArrowBottomLeft color='red' size='30px' style={{
                                background: "#ffcccb",
                                borderRadius: "5px",
                                padding: "4px"
                            }} />
                        }
                    </div>
                    <div className='textSM dark3'>
                        Day change
                    </div>
                </Card>
                <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h3 className="mb-0 textMD w-500">
                            {portfolioXIRR?.PortfolioXIRR?.XIRR}%</h3>
                        {portfolioXIRR?.PortfolioXIRR?.XIRR >= 0 ?
                            <CgArrowTopRight color='green' size='30px' style={{
                                background: "lightgreen",
                                borderRadius: "5px",
                                padding: "4px"
                            }} /> :
                            <CgArrowBottomLeft color='red' size='30px' style={{
                                background: "#ffcccb",
                                borderRadius: "5px",
                                padding: "4px"
                            }} />
                        }
                    </div>
                    <div className='textSM dark3'>
                        Annualized Returns
                    </div>
                </Card>
                <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h3 className="mb-0 textMD w-500">
                            MB{clientData?.Client?.[0]?.ClientId}</h3>
                        <FaUserAlt color='green' size='30px' style={{
                            background: "lightgreen",
                            borderRadius: "5px",
                            padding: "7px"
                        }} />
                    </div>
                    <div className='textSM dark3'>
                        Client Id
                    </div>
                </Card>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))", gap: "1.25rem" }} className='margin20Top'>
                <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                    <CardHeader heading={"Portfolio Allocation"} />
                    <AssetAllocationChart assetAllocation={assetAllocation} />
                    <div className='' style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))", gap: "1rem" }}>
                        <Card style={{ padding: 0, background: "var(--grey4)", border: "1px solid var(--grey2)" }} bodyStyle={{ padding: "0.5rem" }}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <div className='textSM '>Portfolio Report</div>
                                    <div className='textXS dark4'>Download</div>
                                </div>
                                {downloadingPortfolioReport ?
                                    <Spin style={{ width: "40px", height: "40px" }} className='d-flex align-items-center justify-content-center' /> :
                                    <FaCloudDownloadAlt color='lightgreen' style={{ padding: "8px", borderRadius: "5px", background: "#152a26" }} size='40px' cursor='pointer' onClick={() => onClickPortfolioReport()} />
                                }
                            </div>
                        </Card>
                        <Card style={{ padding: 0, background: "var(--grey4)", border: "1px solid var(--grey2)" }} bodyStyle={{ padding: "0.5rem" }}>
                            <div className=' d-flex align-items-center justify-content-between'>
                                <div>
                                    <div className='textSM w-500'>Transaction Report</div>
                                    <div className='textXS dark4'>Download</div>
                                </div>
                                {downloadingTransactionReport ?
                                    <Spin style={{ width: "40px", height: "40px" }} className='d-flex align-items-center justify-content-center' /> :
                                    <FaCloudDownloadAlt color='lightgreen' style={{ padding: "8px", borderRadius: "5px", background: "#152a26" }} size='40px' cursor='pointer' onClick={() => onClickTransactionReport()} />
                                }
                            </div>
                        </Card>
                    </div>
                </Card>
                <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                    <CardHeader heading='User details' />
                    <div className='d-flex align-items-center justify-content-between margin20Top' style={{ gap: "15px" }}>
                        <ImProfile color='var(--white)' style={{ background: "dodgerblue", padding: "10px", borderRadius: "5px" }} size='40px' />
                        <div className='d-flex justify-content-between flex-grow-1 flex-column' style={{ gap: "5px" }}>
                            <div className='textSM'>{clientData?.Client?.[0]?.DisplayName}</div>
                            <div className='textXS dark4'>Display Name</div>
                        </div>
                    </div>
                    <Divider style={{ margin: "0.75rem 0" }} />
                    <div className='d-flex align-items-center justify-content-between' style={{ gap: "15px" }}>
                        <MdOutlinePhoneIphone color='var(--white)' style={{ background: "violet", padding: "10px", borderRadius: "5px" }} size='40px' />
                        <div className='d-flex justify-content-between flex-grow-1 flex-column' style={{ gap: "5px" }}>
                            <div className='textSM'>{clientData?.Client?.[0]?.MobileNumber}</div>
                            <div className='textXS dark4'>Mobile Number</div>
                        </div>
                    </div>
                    <Divider style={{ margin: "0.75rem 0" }} />
                    <div className='d-flex align-items-center justify-content-between' style={{ gap: "15px" }}>
                        <MdEmail color='var(--white)' style={{ background: "orangered", padding: "10px", borderRadius: "5px" }} size='40px' />
                        <div className='d-flex justify-content-between flex-grow-1 flex-column' style={{ gap: "5px" }}>
                            <div className='textSM'>{clientData?.Client?.[0]?.Email}</div>
                            <div className='textXS dark4'>Email</div>
                        </div>
                    </div>
                    <Divider style={{ margin: "0.75rem 0" }} />
                    <div className='d-flex align-items-center justify-content-between' style={{ gap: "15px" }}>
                        <HiUserGroup color='var(--white)' style={{ background: "teal", padding: "10px", borderRadius: "5px" }} size='40px' />
                        <div className='d-flex justify-content-between flex-grow-1 flex-column' style={{ gap: "5px" }}>
                            <div style={{ textTransform: "capitalize" }} className='textSM'>{clientData?.SmallcaseInfo?.[0]?.Broker}</div>
                            <div className='textXS dark4'>Broker</div>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="margin20Top">
                <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                    <CustomSegmentedTab options={tabItems} value={selectedTab} onChange={(e) => setSelectedTab(e)} isBlock={false} />
                    <div className='margin20Top'>
                        {tabItems?.find((ele) => ele.value === selectedTab)?.children}
                    </div>
                </Card>
                {/* <AdminTabs tabsObject={keyTitles} type="out" /> */}
            </div>
        </React.Fragment>
    )
}
