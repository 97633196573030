import React from 'react';
import axios from 'axios';
import { addDay, addMonths, addWeek, convertAnyDatetoDDMMYYYY, formatDate, formatDateddmmyyyy, formatDateyyyymmdd, monthDiff } from '../shared/components/DateFormatter';
import { Form } from 'react-bootstrap';
import { Form as AntForm, Upload, Card } from 'antd';
import { changeObjKey, removeSelectedKeyVal, removeUnwantedKeyVal } from '../shared/components/DataCustomizer';
import AdminServicesPY from '../../services/api/AdminServicesPY';
import AdminServices from '../../services/api/AdminServices';
import { toast } from 'react-toastify';
import { InboxOutlined } from '@ant-design/icons';
import { BiUpload } from "react-icons/bi";
import { Col, Row } from "reactstrap";
import csvToJson from 'csvtojson';
import { convertToJson, decodeData } from '../../libs/utils';
import { CardHeader } from '../../Components/Atoms/CardHeader';

export default function Automation({ navigation, props }) {
    const [start_value, setStartValue] = React.useState(null);
    const [end_value, setEndValue] = React.useState(null);
    const [vr_start_value, setVRStartValue] = React.useState(null);
    const [vr_end_value, setVREndValue] = React.useState(null);
    const [vrTable, setvrTable] = React.useState(-1);

    const [benchDate, setbenchDate] = React.useState(null);
    const [benchId, setbenchId] = React.useState(null);
    const [benchPrice, setbenchPrice] = React.useState(0);
    const timer = ms => new Promise(res => setTimeout(res, ms))


    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    var allbenchmarks = [
        {
            "UID": 1,
            "BenchmarkName": "Nifty IT Index TRI",
            "Ticker": "NIFTY IT",
            "Origin": "NSE"
        },
        {
            "UID": 2,
            "BenchmarkName": "Hang Seng TECH Total Return Index",
            "Ticker": null,
            "Origin": "PY"
        },
        {
            "UID": 3,
            "BenchmarkName": "NYSE FANG+ Total Return Index",
            "Ticker": null,
            "Origin": "PY"
        },
        {
            "UID": 4,
            "BenchmarkName": "Nifty PSU Bank TRI",
            "Ticker": "NIFTY PSU BANK",
            "Origin": "NSE"
        },
        {
            "UID": 5,
            "BenchmarkName": "S&P 500 Top 50 Total Return Index",
            "Ticker": "SPBSS5IP",
            "Origin": "BSE"
        },
        {
            "UID": 6,
            "BenchmarkName": "Shariah TRI",
            "Ticker": "SPBSE5S",
            "Origin": "BSE"
        },
        {
            "UID": 7,
            "BenchmarkName": "Nifty 50 TRI",
            "Ticker": "NIFTY 50",
            "Origin": "NSE"
        },
        {
            "UID": 8,
            "BenchmarkName": "Nifty 200 Momentum 30 TRI",
            "Ticker": "NIFTY200MOMENTM30",
            "Origin": "NSE"
        },
        {
            "UID": 9,
            "BenchmarkName": "Nifty Midcap 150 TRI",
            "Ticker": "NIFTY MIDCAP 150",
            "Origin": "NSE"
        },
        {
            "UID": 10,
            "BenchmarkName": "Nifty50 Value 20 TRI",
            "Ticker": "NIFTY50 VALUE 20",
            "Origin": "NSE"
        },
        {
            "UID": 11,
            "BenchmarkName": "NIFTY Alpha 50 Index TRI",
            "Ticker": "NIFTY ALPHA 50",
            "Origin": "NSE"
        },
        {
            "UID": 12,
            "BenchmarkName": "Nifty Midcap 100 TRI",
            "Ticker": "NIFTY MIDCAP 100",
            "Origin": "NSE"
        },
        {
            "UID": 13,
            "BenchmarkName": "BSE Sensex Next 50 TRI",
            "Ticker": "SPBSN5IP",
            "Origin": "BSE"
        },
        {
            "UID": 14,
            "BenchmarkName": "Nifty Next 50 TRI",
            "Ticker": "NIFTY NEXT 50",
            "Origin": "NSE"
        },
        {
            "UID": 15,
            "BenchmarkName": "Nifty Alpha Low-Volatility 30 Index TRI",
            "Ticker": "NIFTY ALPHALOWVOL",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 17,
            "BenchmarkName": "Nifty Auto Index TRI",
            "Ticker": "NIFTY AUTO",
            "Origin": "NSE"
        },
        {
            "UID": 18,
            "BenchmarkName": "S&P BSE Midcap Select Index TRI",
            "Ticker": "SPBMDSIP",
            "Origin": "BSE"
        },
        {
            "UID": 19,
            "BenchmarkName": "Nifty Midcap 150 Quality 50 Index TRI",
            "Ticker": "NIFTY MIDCAP150 QUALITY 50",
            "Origin": "NSE"
        },
        {
            "UID": 20,
            "BenchmarkName": "Nasdaq Q-50 Total Return Index",
            "Ticker": null,
            "Origin": "PY"
        },
        {
            "UID": 21,
            "BenchmarkName": "Nifty Infra TRI",
            "Ticker": "NIFTY INFRASTRUCTURE",
            "Origin": "NSE"
        },
        {
            "UID": 22,
            "BenchmarkName": "NIFTY100 ESG SECTOR LEADERS TRI",
            "Ticker": "NIFTY100ESGSECLDR",
            "Origin": "NSE"
        },
        {
            "UID": 23,
            "BenchmarkName": "Nifty 200 Quality 30 Index TRI",
            "Ticker": "NIFTY200 QUALITY 30",
            "Origin": "NSE"
        },
        {
            "UID": 24,
            "BenchmarkName": "Nifty 100 TRI",
            "Ticker": "NIFTY 100",
            "Origin": "NSE"
        },
        {
            "UID": 25,
            "BenchmarkName": "NIFTY 50 Equal Weight Index TRI",
            "Ticker": "NIFTY50 EQL WGT",
            "Origin": "NSE"
        },
        {
            "UID": 26,
            "BenchmarkName": "Hang Seng HSI GR USD",
            "Ticker": null,
            "Origin": "PY"
        },
        {
            "UID": 27,
            "BenchmarkName": "NIFTY India Consumption Index TRI",
            "Ticker": "NIFTY INDIA CONSUMPTION",
            "Origin": "NSE"
        },
        {
            "UID": 28,
            "BenchmarkName": "Nifty Private Bank Index TRI",
            "Ticker": "NIFTY PRIVATE BANK",
            "Origin": "NSE"
        },
        {
            "UID": 29,
            "BenchmarkName": "Nifty India Manufacturing Total Return Index",
            "Ticker": "NIFTY INDIA MANUFACTURING",
            "Origin": "NSE"
        },
        {
            "UID": 30,
            "BenchmarkName": "Nifty 100 Low Volatility 30 Index TRI",
            "Ticker": "NIFTY100 LowVol30",
            "Origin": "NSE"
        },
        {
            "UID": 31,
            "BenchmarkName": "Nifty Bank TRI",
            "Ticker": "NIFTY BANK",
            "Origin": "NSE"
        },
        {
            "UID": 32,
            "BenchmarkName": "S&P BSE 500 index TRI",
            "Ticker": "BSE500",
            "Origin": "BSE"
        },
        {
            "UID": 33,
            "BenchmarkName": "Nifty Financial Services Index TRI",
            "Ticker": "NIFTY FINANCIAL SERVICES",
            "Origin": "NSE"
        },
        {
            "UID": 34,
            "BenchmarkName": "BSE SENSEX Index TRI",
            "Ticker": "SENSEX",
            "Origin": "BSE"
        },
        {
            "UID": 35,
            "BenchmarkName": "S&P BSE BHARAT 22 Index TRI",
            "Ticker": "SPBSB2IP",
            "Origin": "BSE"
        },
        {
            "UID": 36,
            "BenchmarkName": "Nifty Healthcare Index TRI",
            "Ticker": "NIFTY HEALTHCARE",
            "Origin": "NSE"
        },
        {
            "UID": 37,
            "BenchmarkName": "Nasdaq 100 TRI",
            "Ticker": null,
            "Origin": "PY"
        },
        {
            "UID": 38,
            "BenchmarkName": "Nifty CPSE TRI",
            "Ticker": "NIFTY CPSE",
            "Origin": "NSE"
        },
        {
            "UID": 39,
            "BenchmarkName": "Domestic Price of  Gold",
            "Ticker": null,
            "Origin": null
        },
        {
            "UID": 40,
            "BenchmarkName": "Nifty AAA Bond Plus SDL Apr 2026 50:50 Index",
            "Ticker": "Nifty AAA Bond Plus SDL Apr 2026 50:50 Index",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 41,
            "BenchmarkName": "Nifty 5 yr Benchmark G-Sec Index",
            "Ticker": "NIFTY 5YR BENCHMARK G-SEC INDEX",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 42,
            "BenchmarkName": "Nifty BHARAT Bond Index - April 2023",
            "Ticker": "Nifty BHARAT Bond Index - April 2023",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 43,
            "BenchmarkName": "Nifty FMCG Index TRI",
            "Ticker": "NIFTY FMCG",
            "Origin": "NSE"
        },
        {
            "UID": 44,
            "BenchmarkName": "Nifty1D rate index",
            "Ticker": "NIFTY 1D RATE INDEX",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 45,
            "BenchmarkName": "S&P BSE Liquid Rate Index",
            "Ticker": "SPICBLOT",
            "Origin": "BSE"
        },
        {
            "UID": 46,
            "BenchmarkName": "Nifty BHARAT Bond Index - April 2030",
            "Ticker": "Nifty BHARAT Bond Index - April 2030",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 48,
            "BenchmarkName": "NIFTY 8-13 yr G-Sec Index",
            "Ticker": "Nifty GS 8 13Yr",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 49,
            "BenchmarkName": "Nifty BHARAT Bond Index - April 2025",
            "Ticker": "Nifty BHARAT Bond Index - April 2025",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 50,
            "BenchmarkName": "Domestic Price of Silver",
            "Ticker": null,
            "Origin": null
        },
        {
            "UID": 51,
            "BenchmarkName": "Nifty BHARAT Bond Index - April 2031",
            "Ticker": "Nifty BHARAT Bond Index - April 2031",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 52,
            "BenchmarkName": "Nifty 10 yr Benchmark G-Sec Index",
            "Ticker": "Nifty GS 10Yr",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 53,
            "BenchmarkName": "Nifty BHARAT Bond Index - April 2032",
            "Ticker": "NIFTY BHARAT BOND INDEX - APRIL 2032",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 54,
            "BenchmarkName": "BSE Sensex 100",
            "Ticker": "BSE100",
            "Origin": "BSE"
        },
        {
            "UID": 55,
            "BenchmarkName": "NIFTY Div Opps 50 TRI",
            "Ticker": "Nifty Div Opps 50",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 56,
            "BenchmarkName": "NIFTY Midcap 50 TRI",
            "Ticker": "NIFTY MIDCAP 50",
            "Origin": "NSE"
        },
        {
            "UID": 57,
            "BenchmarkName": "Nifty CPSE Bond Plus SDL Sep 2024 50:50 Index",
            "Ticker": "Nifty CPSE Bond Plus SDL Sep 2024 50:50 Index",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 58,
            "BenchmarkName": "Nifty Pharma TRI",
            "Ticker": "NIFTY PHARMA",
            "Origin": "NSE"
        },
        {
            "UID": 59,
            "BenchmarkName": "S&P BSE Low Volatility TRI",
            "Ticker": "SPBSLVIP",
            "Origin": "BSE"
        },
        {
            "UID": 60,
            "BenchmarkName": "Nifty India Digital TRI",
            "Ticker": "NIFTY INDIA DIGITAL",
            "Origin": "NSE"
        },
        {
            "UID": 61,
            "BenchmarkName": "Nifty CPSE Bond Plus SDL Sep 2026",
            "Ticker": "Nifty CPSE Bond Plus SDL Sep 2026 50:50 Index",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 62,
            "BenchmarkName": "Nifty 500 TRI",
            "Ticker": "NIFTY 500",
            "Origin": "NSE"
        },
        {
            "UID": 63,
            "BenchmarkName": "Nifty Smallcap 250 TRI",
            "Ticker": "NIFTY SMALLCAP 250",
            "Origin": "NSE"
        },
        {
            "UID": 64,
            "BenchmarkName": "NIFTY Large Midcap 250 TRI",
            "Ticker": "NIFTY LARGEMIDCAP 250",
            "Origin": "NSE"
        },
        {
            "UID": 65,
            "BenchmarkName": "Nifty MNC TRI",
            "Ticker": "NIFTY MNC",
            "Origin": "NSE"
        },
        {
            "UID": 66,
            "BenchmarkName": "Nifty 50 Arbitrage TRI",
            "Ticker": "NIFTY 50 ARBITRAGE",
            "Origin": "NSE-Bond"
        },
        {
            "UID": 67,
            "BenchmarkName": "Nifty 500 Multicap 50:25:25 TRI",
            "Ticker": "NIFTY500 MULTICAP 50:25:25",
            "Origin": "NSE"
        },
        {
            "UID": 68,
            "BenchmarkName": "Nifty 100 ESG TRI",
            "Ticker": "NIFTY100 ESG",
            "Origin": "NSE"
        }
    ]






    var vrtables = [
        "nav",
        "amc_aum",
        "avg_aum",
        "benchmark_returns_annual",
        "benchmark_returns_monthly",
        "benchmark_returns_quarterly",
        "benchmark_returns_weekly",
        "cat_avg_return_latest",
        "fund_aum",
        "fund_holdings_debtstated_latest",
        "fund_holdings_sectorwise_imputed_latest",
        "schemes_rollingreturns",
        "fund_return_latest",
        "fund_returns_annual",
        "fund_returns_monthly",
        "fund_returns_quarterly",
        "fund_returns_weekly",
        "fund_sip_returns",
        "amc_external_codes",
        "colour_code",
        "companies",
        "countries",
        "credit_rating_score",
        "deleted_logs",
        "entity_auditor",
        "entity_custodian",
        "entity_groups",
        "entity_transfer_agent",
        "event_list",
        "frequency",
        "fund_categories",
        "fund_daily_update",
        "fund_dividends",
        "fund_events",
        "fund_jn_index",
        "fund_load_age",
        "fund_load_cdsc_latest",
        "fund_rights",
        "fund_split",
        "fund_status",
        "fund_type",
        "holdings_averagecreditrating",
        "instrument",
        "rta_codes",
        "sebi_categories",
        "sector",
        "segregated_fund_mapping",
        "segregated_scheme_mapping",
        "transaction_status_master",
        "amcs",
        "category_cutoff_timings",
        "composition",
        "fund_expense",
        "fund_holdings_maturity_latest",
        "fund_holdings_rating",
        "fund_load_latest",
        "fund_manager_latest",
        "fund_plans",
        "fund_rank_latest",
        "holdings_detailed",
        "indices",
        "industry",
        "person",
        "schemes_sebicategory",
        "schemewise_taxation",
        "sip_swp_stp_details",
        "stats_variables",
        "subplan_isin",
        "subplans",
        "turnover"
    ]

    const downloadFiles = (url, filename) => {
        fetch(url).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename);
                a.click();
            }
            );
        });
    }

    const toCsv = function (table) {
        // Query all rows
        var doc = new DOMParser().parseFromString(table, "text/xml");

        console.log(doc)
        const rows = doc.querySelectorAll('tr');

        return [].slice
            .call(rows)
            .map(function (row) {
                // Query all cells
                const cells = row.querySelectorAll('th,td');
                return [].slice
                    .call(cells)
                    .map(function (cell) {
                        return cell.textContent;
                    })
                    .join(',');
            })
            .join('\n');
    };


    const VRBenchPerfotoCSV = function (table, date, headerneeded = true) {
        // Query all rows
        var doc = new DOMParser().parseFromString(table, "text/xml");
        const rows = doc.querySelectorAll('tr');

        return [].slice
            .call(rows)
            .map(function (row, indexRow) {
                // Query all cells
                const cells = row.querySelectorAll('th,td');
                return [].slice
                    .call(cells)
                    .map(function (cell, index) {

                        if (cell.attributes.length > 2) {
                            var aaa = []
                            for (var key of Object.keys(cell.attributes)) {
                                if (Number(key) !== 0) {
                                    aaa.push(cell.attributes[key].textContent)
                                }
                            }
                            return aaa.join(",")
                        } else {
                            if (headerneeded === true) {
                                if (index === 1 && indexRow !== 0) {
                                    return [cell.textContent, date].join(",");
                                } else {
                                    return cell.textContent
                                }
                            } else {
                                if (index === 1) {
                                    return [cell.textContent, date].join(",");
                                } else {
                                    return cell.textContent
                                }
                            }


                        }
                    })
                    .join(',');
            })
            .join('\n');
    };

    const download = function (text, fileName) {
        const link = document.createElement('a');
        link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
        link.setAttribute('download', fileName);

        link.style.display = 'none';
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        console.log(fileName)
    };

    const getETDATAfromNSE = async () => {

        var etfs = ["NETFDIVOPP", "UTISXN50", "IDBIGOLD", "MOM100", "AXISTECETF", "HEALTHY", "TECH", "CPSEETF", "SBIETFIT", "ICICINV20", "ICICIPHARM", "NETFNV20", "AXISCETF", "HNGSNGBEES", "NETFMID150", "ICICICONSU", "ICICIB22", "NETFIT", "NETFNIF100", "AXISGOLD", "KOTAKIT", "NETFLTGILT", "KOTAKNV20", "HDFCNIFETF", "IVZINGOLD", "SBIETFQLTY", "NETFCONSUM", "NETFPHARMA", "ICICIMCAP", "MAMFGETF", "ICICITECH", "ICICI500", "UTINIFTETF", "SETFNN50", "KOTAKGOLD", "UTINEXT50", "GOLDSHARE", "KOTAKNIFTY", "ICICINIFTY", "KOTAKPSUBK", "KOTAKALPHA", "NETFAUTO", "EBBETF0425", "ICICINXT50", "ABSLNN50ET", "SETFGOLD", "MANXT50", "EBBETF0431", "SHARIABEES", "GOLDBEES", "ICICIGOLD", "NIFTYBEES", "IDFNIFTYET", "QGOLDHALF", "SETFNIF50", "SBIETFCON", "DSPN50ETF", "DSPQ50ETF", "MAN50ETF", "EBBETF0423", "INFRABEES", "NETFSILVER", "HDFCMFGETF", "MAFANG", "ICICIM150", "BBETF0432", "ICICINF100", "ICICIFMCG", "NETFSDL26", "ICICILOVOL", "LICNFNHGP", "LIQUIDBEES", "ICICILIQ", "SETF10GILT", "LIQUIDETF", "MOM50", "ICICISILVE", "NCPSESDL24", "HDFCSENETF", "EBBETF0430", "BSLNIFTY", "PSUBNKBEES", "MOGSEC", "JUNIORBEES", "NETFGILT5Y", "DSPNEWETF", "MOMOMENTUM", "LICNETFSEN", "QNIFTY", "ICICIALPLV", "SILVER", "NETF", "MAESGETF", "LICNETFGSC", "LICNETFN50", "AXISBPSETF", "BSLSENETFG", "AXISNIFTY", "ICICIBANKN", "ICICISENSX", "MASPTOP50", "EBANK", "BSLGOLDETF", "ABSLBANETF", "ICICIAUTO", "KOTAKBKETF", "AXISBNKETF", "MAFSETF", "BANKBEES", "HBANKETF", "UTISENSETF", "SETFNIFBK", "MONQ50", "ICICIBANKP", "IBMFNIFTY", "IVZINNIFTY", "AXISHCETF", "NPBET", "UTIBANKETF", "SBIETFPB", "MAHKTECH", "MON100"]

        try {
            const promises = etfs.map(async (etf, index) => {
                new Promise(resolve =>
                    setTimeout(() => {
                        var config = {
                            method: 'get',
                            timeout: 10000,
                            url: `https://www1.nseindia.com/live_market/dynaContent/live_watch/get_quote/getHistoricalData.jsp?symbol=${etf}&series=EQ&fromDate=${formatDate(start_value, "", "-")}&toDate=${formatDate(end_value, "", "-")}&hiddDwnld=false&datePeriod=`
                        };

                        axios(config)
                            .then(function (response) {
                                var tableData = JSON.stringify(response.data)
                                tableData = tableData.substring(tableData.indexOf("<table>"), tableData.lastIndexOf("</table>") + 8).replace(/\s/g, "").replaceAll(',', "");
                                download(toCsv(tableData), etf + ".csv")
                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                        resolve()
                    }, 1000 * etfs.length - 1000 * index)
                )
            })
            Promise.all(promises).then(() => console.log('done'))

        } catch (error) {
            console.log("SELECT A DATE")
        }







    }


    function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
        var CSV = 'sep=,' + '\r\n\n';
        if (ShowLabel) {
            var row = "";
            for (var index in arrData[0]) {
                row += index + ',';
            }
            row = row.slice(0, -1);
            CSV += row + '\r\n';
        }

        for (var i = 0; i < arrData.length; i++) {
            var row = "";
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }

            row.slice(0, row.length - 1);
            CSV += row + '\r\n';
        }

        if (CSV == '') {
            alert("Invalid data");
            return;
        }

        var fileName = "";
        fileName += ReportTitle.replace(/ /g, "_");

        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

        var link = document.createElement("a");
        link.href = uri;

        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const getIndexDATAfromNSE = async () => {

        var etfs = ["Nifty 50", "Nifty IT", "Nifty Next 50", "Nifty50 USD", "Nifty Bank", "NIFTY MIDCAP 100", "Nifty 500", "Nifty 100", "Nifty Midcap 50", "Nifty Realty", "Nifty Infra", "INDIA VIX", "Nifty Energy", "Nifty FMCG", "Nifty MNC", "Nifty Pharma", "Nifty PSE", "Nifty PSU Bank", "Nifty Serv Sector", "NIFTY SMLCAP 100", "Nifty 200", "Nifty Auto", "Nifty Media", "Nifty Metal", "Nifty Div Opps 50", "Nifty Commodities", "Nifty Consumption", "Nifty Fin Service", "Nifty50 Div Point", "Nifty100 Liq 15", "Nifty CPSE", "Nifty GrowSect 15", "Nifty50 TR 2x Lev", "Nifty50 PR 2x Lev", "Nifty50 TR 1x Inv", "Nifty50 PR 1x Inv", "Nifty50 Value 20", "NIFTY100 Qualty30", "Nifty Mid Liq 15", "Nifty Pvt Bank", "Nifty GS 8 13Yr", "Nifty GS 10Yr", "Nifty GS 10Yr Cln", "Nifty GS 4 8Yr", "Nifty GS 11 15Yr", "Nifty GS 15YrPlus", "Nifty GS Compsite", "NIFTY50 EQL Wgt", "NIFTY100 EQL Wgt", "NIFTY100 LowVol30", "NIFTY Alpha 50", "NIFTY Midcap 150", "NIFTY Smallcap 50", "NIFTY Smallcap 250", "NIFTY MidSmallcap 400", "NIFTY200 Quality 30", "Nifty FinSrv25 50", "NIFTY AlphaLowVol", "Nifty200Momentm30", "Nifty100ESGSecLdr", "NIFTY HEALTHCARE", "NIFTY CONSR DURBL", "NIFTY OIL AND GAS", "NIFTY500 MULTICAP", "NIFTY LARGEMID250"]





        const promises = etfs.map(async (etf, index) => {

            new Promise(resolve =>
                setTimeout(() => {



                    //         var data = {
                    //             "cinfo": `{
                    //     'name': ${etf},
                    //     'startDate': ${formatDate(start_value, "", "-")},
                    //     'endDate': ${formatDate(end_value, "", "-")}
                    // }`}

                    var data = {
                        "cinfo": `{'name': '${etf}','indexName': '${etf}','startDate': '${formatDate(start_value, "", "-")}','endDate': '${formatDate(end_value, "", "-")}'}`
                    }


                    var config = {
                        method: 'post',
                        timeout: 10000,
                        url: 'https://www.niftyindices.com/Backpage.aspx/getHistoricaldatatabletoString',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: data
                    };


                    axios(config)
                        .then(function (response) {
                            var tableData = JSON.parse(response.data.d)
                            JSONToCSVConvertor(tableData, etf, true)
                            // tableData = tableData.substring(tableData.indexOf("<table>"), tableData.lastIndexOf("</table>") + 8).replace(/\s/g, "").replaceAll(',', "");
                            // download(toCsv(tableData), etf + ".csv")
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    resolve()
                }, 1000 * etfs.length - 1000 * index)
            )


        })
        Promise.all(promises).then(() => console.log('done'))






    }


    const getIndexNAVDATAfromNSE = async () => {

        var etfs = ["Nifty 50", "Nifty IT", "Nifty Next 50", "Nifty50 USD", "Nifty Bank", "NIFTY MIDCAP 100", "Nifty 500", "Nifty 100", "Nifty Midcap 50", "Nifty Realty", "Nifty Infra", "INDIA VIX", "Nifty Energy", "Nifty FMCG", "Nifty MNC", "Nifty Pharma", "Nifty PSE", "Nifty PSU Bank", "Nifty Serv Sector", "NIFTY SMLCAP 100", "Nifty 200", "Nifty Auto", "Nifty Media", "Nifty Metal", "Nifty Div Opps 50", "Nifty Commodities", "Nifty Consumption", "Nifty Fin Service", "Nifty50 Div Point", "Nifty100 Liq 15", "Nifty CPSE", "Nifty GrowSect 15", "Nifty50 TR 2x Lev", "Nifty50 PR 2x Lev", "Nifty50 TR 1x Inv", "Nifty50 PR 1x Inv", "Nifty50 Value 20", "NIFTY100 Qualty30", "Nifty Mid Liq 15", "Nifty Pvt Bank", "Nifty GS 8 13Yr", "Nifty GS 10Yr", "Nifty GS 10Yr Cln", "Nifty GS 4 8Yr", "Nifty GS 11 15Yr", "Nifty GS 15YrPlus", "Nifty GS Compsite", "NIFTY50 EQL Wgt", "NIFTY100 EQL Wgt", "NIFTY100 LowVol30", "NIFTY Alpha 50", "NIFTY Midcap 150", "NIFTY Smallcap 50", "NIFTY Smallcap 250", "NIFTY MidSmallcap 400", "NIFTY200 Quality 30", "Nifty FinSrv25 50", "NIFTY AlphaLowVol", "Nifty200Momentm30", "Nifty100ESGSecLdr", "NIFTY HEALTHCARE", "NIFTY CONSR DURBL", "NIFTY OIL AND GAS", "NIFTY500 MULTICAP", "NIFTY LARGEMID250"]





        const promises = etfs.map(async (etf, index) => {

            new Promise(resolve =>
                setTimeout(() => {
                    // var data = JSON.stringify({
                    //     "name": etf,
                    //     "startDate": formatDate(start_value, "", "-"),
                    //     "endDate": formatDate(end_value, "", "-")
                    // });


                    var data = {
                        "cinfo": `{'name': '${etf}','indexName': '${etf}','startDate': '${formatDate(start_value, "", "-")}','endDate': '${formatDate(end_value, "", "-")}'}`
                    }

                    var config = {
                        method: 'post',
                        timeout: 10000,
                        url: 'https://www.niftyindices.com/Backpage.aspx/getTotalReturnIndexString',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: data
                    };


                    axios(config)
                        .then(function (response) {
                            var tableData = JSON.parse(response.data.d)
                            JSONToCSVConvertor(tableData, etf, true)
                            // tableData = tableData.substring(tableData.indexOf("<table>"), tableData.lastIndexOf("</table>") + 8).replace(/\s/g, "").replaceAll(',', "");
                            // download(toCsv(tableData), etf + ".csv")
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    resolve()
                }, 1000 * etfs.length - 1000 * index)
            )


        })
        Promise.all(promises).then(() => console.log('done'))






    }



    async function make_bse_api_call(etf) {

        let numWeeks = 7;
        let now = new Date();
        now.setDate(now.getDate() - numWeeks * 7);
        var startDate = new Date(now.setDate(now.getDate() - numWeeks * 7))
        var endDate = new Date()
        var config = {
            method: 'get',
            timeout: 10000,
            url: `https://api.bseindia.com/BseIndiaAPI/api/IndexArchDaily/w?fmdt=${formatDateddmmyyyy(startDate).replaceAll("/", "%2F")}&index=${etf.Ticker}&period=D&todt=${formatDateddmmyyyy(endDate).replaceAll("/", "%2F")}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return await axios(config)
            .then(function (response) {
                var tableData = response.data.Table.reverse()
                if (tableData[0]) {
                    tableData = tableData.map((item, index) => {
                        return {
                            ...item,
                            ...etf
                        }

                    });
                    console.log(tableData);

                    return tableData
                }
            })
            .catch(function (error) {
                console.log(error)
                return []

            });
    }

    async function bseTRI(etfs) {
        let result;
        var finalData = []

        for (let i = 0; i < etfs.length; i++) {
            result = await make_bse_api_call(etfs[i]);
            Array.prototype.push.apply(finalData, result)
        }
        return finalData;
    }


    function GetCSVCells(row, separator) {
        return row.split(separator);
    }

    async function make_nse_api_call(etf) {

        // let numWeeks = 3;
        // let now = new Date();
        // // now.setDate(now.getDate() - numWeeks * 7);
        // var startDate = now.setDate(now.getDate() - numWeeks * 7)
        // var endDate = new Date()

        // // var data = JSON.stringify({
        // //     "name": etf.Ticker,
        // //     "startDate": formatDateddmmyyyy(startDate, "", "-"),
        // //     "endDate": formatDateddmmyyyy(endDate, "", "-")
        // // });

        // var finalArr = []



        // var config = {
        //     method: 'GET',
        //     url: `https://www1.nseindia.com/products/dynaContent/equities/indices/total_returnindices.jsp?indexType=${etf.Ticker}&fromDate=${convertAnyDatetoDDMMYYYY(startDate)}&toDate=${convertAnyDatetoDDMMYYYY(endDate)}`,
        // };
        // // var config = {
        // //     method: 'GET',
        // //     url: `https://www1.nseindia.com/products/dynaContent/equities/indices/total_returnindices.jsp?indexType=${etf.Ticker}&fromDate=08-22-1422&toDate=08-22-1422`,
        // // };
        // return await axios(config)
        //     .then(function (response) {
        //         var doc = new DOMParser().parseFromString(response.data, "text/html")
        //         console.log(doc.getElementById("csvContentDiv").innerHTML)
        //         var rows = doc.getElementById("csvContentDiv").innerText.split(":");

        //         if (rows.length > 0) {
        //             var firstRowCells = GetCSVCells(rows[0], ",");

        //             var dataArray = new Array();
        //             for (var i = 1; i < rows.length - 1; i++) {
        //                 var cells = GetCSVCells(rows[i], ",");
        //                 var obj = {};
        //                 for (var j = 0; j < cells.length; j++) {
        //                     obj[firstRowCells[j].replaceAll("\"", "")] = cells[j].replaceAll("\"", "");
        //                 }
        //                 dataArray.push({ ...obj, ...etf });
        //             }

        //             console.log(dataArray);
        //             return (dataArray)
        //         }
        //     })
        //     .catch(function (error) {
        //         return []
        //     });


        let numWeeks = 7;
        let now = new Date();
        // now.setDate(now.getDate() - numWeeks * 7);
        var startDate = now.setDate(now.getDate() - numWeeks * 7);
        var endDate = new Date()

        // var data = JSON.stringify({
        //     "name": etf.Ticker,
        //     "startDate": formatDate(startDate, "", "-"),
        //     "endDate": formatDate(endDate, "", "-")
        // });

        var data = {
            "cinfo": `{'name': '${etf.Ticker}','indexName': '${etf.Ticker}','startDate': '${formatDate(startDate, "", "-")}','endDate': '${formatDate(endDate, "", "-")}'}`
        }
        var config = {
            method: 'post',
            timeout: 10000,
            url: 'https://www.niftyindices.com/Backpage.aspx/getTotalReturnIndexString',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };
        return await axios(config)
            .then(function (response) {
                var tableData = JSON.parse(response.data.d)
                if (tableData[0]) {
                    tableData = tableData.map((item, index) => {
                        return {
                            ...item,
                            ...etf
                        }

                    });
                    console.log("ASDDSADSAD", tableData)
                    return tableData
                    // return ({
                    //     ...tableData[0],
                    //     ...etf
                    // })
                }
            })
            .catch(function (error) {
                console.log(error)
                return []
            });
    }

    async function nseTRI(etfs) {
        let result;
        var finalData = []

        for (let i = 0; i < etfs.length; i++) {
            result = await make_nse_api_call(etfs[i]);
            Array.prototype.push.apply(finalData, result)
        }
        return finalData;
    }


    async function make_nse_api_call_bond(etf) {

        let numWeeks = 7;
        let now = new Date();
        // now.setDate(now.getDate() - numWeeks * 7);
        var startDate = now.setDate(now.getDate() - numWeeks * 7);
        var endDate = new Date()

        // var data = {
        //     "cinfo": `{
        //     'name': ${etf.Ticker},
        //     'startDate': ${formatDate(startDate, "", "-")},
        //     'endDate': ${formatDate(endDate, "", "-")}
        // }`}
        var data = {
            "cinfo": `{'name': '${etf.Ticker}','indexName': '${etf.Ticker}','startDate': '${formatDate(startDate, "", "-")}','endDate': '${formatDate(endDate, "", "-")}'}`
        }
        var config = {
            method: 'post',
            timeout: 10000,
            url: 'https://www.niftyindices.com/Backpage.aspx/getHistoricaldatatabletoString',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };
        return await axios(config)
            .then(function (response) {
                var tableData = JSON.parse(response.data.d)
                if (tableData[0]) {
                    tableData = tableData.map((item, index) => {
                        return {
                            ...item,
                            ...etf
                        }

                    });
                    console.log("ASDDSADSAD", tableData)
                    return tableData
                    // return ({
                    //     ...tableData[0],
                    //     ...etf
                    // })
                }
            })
            .catch(function (error) {
                console.log(error)
                return []
            });
    }

    async function nseTRI_bond(etfs) {
        let result;
        var finalData = []

        for (let i = 0; i < etfs.length; i++) {
            result = await make_nse_api_call_bond(etfs[i]);
            Array.prototype.push.apply(finalData, result)
        }
        return finalData;
    }



    const updateDailyBenchmarkData = async () => {

        try {
            //nse
            var nse = allbenchmarks.map((a) => {
                if (a.Origin === "NSE") {
                    return a
                }
            })
            nse = nse.filter(e => e)
            console.log(nse)
            let result = await nseTRI(nse);
            console.log(result)
            result = result.filter(value => Object.keys(value).length !== 0);
            console.log(result)

            result = changeObjKey(result, ["UID", "TotalReturnsIndex", "Date"], ["benchmarkId", "price", "Date"])

            result = removeUnwantedKeyVal(result, ["benchmarkId", "price", "Date"])
            result = result.map((v) => {
                return {
                    ...v,
                    price: Number(v.price),
                    Date: convertAnyDatetoDDMMYYYY(v.Date, "monStr")
                }
            })
            console.log(result.length)

            // nse-bond
            var nse_bond = allbenchmarks.map((a) => {
                if (a.Origin === "NSE-Bond") {
                    return a
                }
            })
            nse_bond = nse_bond.filter(e => e)
            let result_bond = await nseTRI_bond(nse_bond);
            result_bond = result_bond.filter(e => e)
            result_bond = changeObjKey(result_bond, ["UID", "CLOSE", "HistoricalDate"], ["benchmarkId", "price", "Date"])
            result_bond = removeUnwantedKeyVal(result_bond, ["benchmarkId", "price", "Date"])
            result_bond = result_bond.map((v) => {
                return {
                    ...v,
                    price: Number(v.price),
                    Date: convertAnyDatetoDDMMYYYY(v.Date, "monStr")
                }
            })
            console.log(result_bond)

            // bse
            var bse = allbenchmarks.map((a) => {
                if (a.Origin === "BSE") {
                    return a
                }
            })
            bse = bse.filter(e => e)
            let bresult = await bseTRI(bse);
            bresult = bresult.filter(e => e)
            bresult = changeObjKey(bresult, ["UID", "I_close", "tdate"], ["benchmarkId", "price", "Date"])
            bresult = removeUnwantedKeyVal(bresult, ["benchmarkId", "price", "Date"])
            bresult = bresult.map((v) => {
                return {
                    ...v,
                    price: Number(v.price),
                    Date: convertAnyDatetoDDMMYYYY(v.Date)
                }
            })
            console.log(bresult)

            // international
            // var internationalBench = await AdminServicesPY.updateBenchmarksNAV().then((res) => { return res.data })
            // internationalBench = JSON.parse(internationalBench).map((v) => {
            //     return {
            //         ...v,
            //         benchmarkId: v.BenchmarkId,
            //         price: Number(v.Price),
            //         Date: convertAnyDatetoDDMMYYYY(v.Date)
            //     }
            // })
            // internationalBench = removeUnwantedKeyVal(internationalBench, ["benchmarkId", "price", "Date"])
            // console.log(internationalBench.length)


            var finalRes = result.concat(bresult).concat(result_bond)

            // var finalRes = internationalBench

            finalRes = changeObjKey(finalRes, ["Date"], ["date"])
            finalRes = removeUnwantedKeyVal(finalRes, ["benchmarkId", "price", "date"])



            console.log("finalRes")
            console.log(finalRes)

            var res = await AdminServices.updateBenchmarkTRI(finalRes).then((res) => { return res.data })
            console.log(res)
            if (res === "success") {
                toast.success("Updated");
            } else {
                toast.error("Error ocurred!!");
            }
        } catch (error) {
            console.log(error)
            toast.error("Error ocurred!!", error);
        }


    }


    const updateIndividualDailyBenchmarkData = async () => {

        try {
            // custom

            if (benchDate !== null && benchId !== null && benchPrice !== null && benchId !== -1) {
                var finalRes = [
                    {
                        "benchmarkId": benchId,
                        "date": convertAnyDatetoDDMMYYYY(benchDate),
                        "price": benchPrice
                    }
                ]
                console.log(finalRes)
                var res = await AdminServices.updateBenchmarkTRI(finalRes).then((res) => { return res.data })
                console.log(res)
                if (res === "success") {
                    toast.success("Updated");
                } else {
                    toast.error("Error ocurred!!");
                }
            } else {
                toast.error("Fill form");
            }


        } catch (error) {
            toast.error("Error ocurred!!", error);
        }


    }


    const updateIndividualVRTable = async () => {

        try {

            if (vr_start_value !== null && vr_end_value !== null && vrTable !== -1) {
                // var diff = monthDiff(new Date(vr_start_value), new Date(vr_end_value))
                var a = new Date(vr_start_value);
                var b = new Date(vr_end_value);
                var diff = Math.round((b - a) / 604800000);
                console.log(diff)
                let stD = vr_start_value
                for (let index = 0; index < diff; index++) {

                    var nextD = formatDateyyyymmdd(addWeek(new Date(stD), 1))
                    console.log(`?changed-after=${stD}-00-00-00&changed-before=${nextD}-00-00-00&output=data`)
                    // var finalRes = {
                    //     "query": `?changed-after=${stD}-00-00-00&changed-before=${nextD}-00-00-00&output=data`,
                    //     "tables": [
                    //         vrTable
                    //     ]
                    // }
                    // console.log(finalRes)
                    // var res = await AdminServices.updateVRTable(finalRes).then((res) => { return res })
                    // console.log(res)
                    // if (res.data.mailStatus === "success") {
                    //     console.log("DONE", index + 1, "=>>", finalRes.query)
                    //     toast.success("Updated");
                    // } else {
                    //     toast.error("Error ocurred!!");
                    // }

                    // setTimeout(function () {
                    //     console.log('new query starting up after 3s ....');
                    // }, 10000);

                    stD = nextD



                }
            } else {
                toast.error("Fill form");
            }




        } catch (error) {
            console.log(error)
            toast.error("Error ocurred!!", error);
        }


    }


    const getBenchmarkReturnsDaily = async (freq) => {
        // freq = 1 Daily
        // freq = 2 All data from 2018 (as per VR)

        var axios = require('axios');

        var category = [
            {
                cat_Id: "SEQ",
                sub_cat: [
                    "SEQ_LC",
                    "SEQ_LMC",
                    "SEQ_MLC",
                    "SEQ_MC",
                    "SEQ_SC",
                    "SEQ_VAL",
                    "SEQ_ELSS",
                    "SEQ_CONT",
                    "SEQ_DIVY",
                    "SEQ_FOC",
                    "SEQ_SCTH",
                ]
            }, {
                cat_Id: "SSO",
                sub_cat: [
                    "SSO_CHILD",
                    "SSO_RETR"
                ]
            }, {
                cat_Id: "SOTH",
                sub_cat: [
                    "SOTH_IXETF",
                    "SOTH_FOFS"
                ]
            }, {
                cat_Id: "SDT",
                sub_cat: [
                    "SDT_LND",
                    "SDT_MLD",
                    "SDT_MD",
                    "SDT_SD",
                    "SDT_DB",
                    "SDT_CB",
                    "SDT_CR",
                    "SDT_BPSU",
                    "SDT_FL",
                    "SDT_FMP",
                    "SDT_GL",
                    "SDT_GL10CD"
                ]
            }, {
                cat_Id: "SHY",
                sub_cat: [
                    "SHY_AH",
                    "SHY_BH",
                    "SHY_CH",
                    "SHY_EQS",
                    "SHY_AR",
                    "SHY_MAA",
                    "SHY_DAABA"
                ]
            }
        ]


        var final = []
        var i = 0


        for (let index = 0; index < category.length; index++) {
            const element = category[index];
            console.log("\n")

            for (let index = 0; index < element.sub_cat.length; index++) {
                const subCat = element.sub_cat[index];
                console.log(element.cat_Id, subCat, formatDate(new Date('2024-08-22')))




                var config = {
                    method: 'get',
                    timeout: 10000,
                    url: `https://www.valueresearchonline.com/amfi/fund-performance-data/?end-type=1&primary-category=${element.cat_Id}&category=${subCat}&amc=ALL&nav-date=${formatDate(new Date('2024-x'))}`,
                };

                axios(config)
                    .then(function (response) {
                        var tableData = JSON.stringify(response.data)
                        tableData = tableData.substring(tableData.indexOf("<tbody>"), tableData.lastIndexOf("</tbody>") + 8).replace(/\s+/g, ' ').trim().replaceAll(',', "").replace(/(\r\n|\n|\r)/gm, "").replaceAll('\\"', "\"").replaceAll("\\r", "").replaceAll("\\n", "").replaceAll(/id="(.*?(\s)*?)*?"/g, "").replaceAll(/alt="(.*?(\s)*?)*?"/g, "").replaceAll(/<a[^>]*>(.*?)<\/a>/g, "").replaceAll("&", "_|_").replace(/\s+/g, ' ').trim()

                        var headerss = `<table>
                        <thead>
                        <tr>
                            <th>Scheme</th>
                            <th>Benchmark</th>
                            <th>As on date</th>
                            <th>Scheme Riskometer</th>
                            <th>Benchmark Riskometer</th>
                            <th>Scheme Regular NAV latest</th>
                            <th>Scheme Regular NAV prev</th>
                            <th>Scheme Direct NAV prev</th>
                            <th>Scheme Direct NAV prev</th>
                            <th>Scheme Regular 1Year</th>
                            <th>Scheme Regular 3Year</th>
                            <th>Scheme Regular 5Year</th>
                            <th>Scheme Regular 10Year</th>
                            <th>Scheme Regular Since launch</th>
                            <th>Scheme Direct 1Year</th>
                            <th>Scheme Direct 3Year</th>
                            <th>Scheme Direct 5Year</th>
                            <th>Scheme Direct 10Year</th>
                            <th>Scheme Direct Since launch</th>
                            <th>Benchmark Regular 1Year</th>
                            <th>Benchmark Regular 3Year</th>
                            <th>Benchmark Regular 5Year</th>
                            <th>Benchmark Regular 10Year</th>
                            <th>Benchmark Regular Since launch</th>
                            <th>Benchmark Direct 1Year</th>
                            <th>Benchmark Direct 3Year</th>
                            <th>Benchmark Direct 5Year</th>
                            <th>Benchmark Direct 10Year</th>
                            <th>Benchmark Direct Since launch</th>
                            <th>AUM</th>
                        </tr>
                    </thead>
                        `

                        if (!tableData.includes("The underlying data is unavailable.")) {
                            console.log(tableData.length);
                            if (i === 0) {
                                i++
                                final.push(VRBenchPerfotoCSV(headerss + tableData + "</table>", formatDate(new Date('2024-08-22'))).toString().replaceAll("_|_", "&"))
                            } else {
                                final.push(VRBenchPerfotoCSV(tableData, formatDate(new Date('2024-08-22')), false).toString().replaceAll("_|_", "&"))
                            }
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                await timer(800);

            }

            await timer(800);

        }
        console.log(final)
        // download(final.join("\n"), "benchmarkPerf1.csv")

        var str1 = final.join("\n")
        var category = [
            {
                cat_Id: "SDT",
                sub_cat: [
                    "SDT_LWD",//
                    "SDT_USD",//
                    "SDT_LIQ",//
                    "SDT_MM",//
                    "SDT_OVNT",//
                ]
            }
        ]


        var final = []
        var i = 0


        for (let index = 0; index < category.length; index++) {
            const element = category[index];
            console.log("\n")

            for (let index = 0; index < element.sub_cat.length; index++) {
                const subCat = element.sub_cat[index];
                console.log(element.cat_Id, subCat, formatDate(new Date('2024-08-22')))




                var config = {
                    method: 'get',
                    timeout: 10000,
                    url: `https://www.valueresearchonline.com/amfi/fund-performance-data/?end-type=1&primary-category=${element.cat_Id}&category=${subCat}&amc=ALL&nav-date=${formatDate(new Date('2024-08-22'))}`,
                };

                axios(config)
                    .then(function (response) {
                        var tableData = JSON.stringify(response.data)
                        tableData = tableData.substring(tableData.indexOf("<tbody>"), tableData.lastIndexOf("</tbody>") + 8).replace(/\s+/g, ' ').trim().replaceAll(',', "").replace(/(\r\n|\n|\r)/gm, "").replaceAll('\\"', "\"").replaceAll("\\r", "").replaceAll("\\n", "").replaceAll(/id="(.*?(\s)*?)*?"/g, "").replaceAll(/alt="(.*?(\s)*?)*?"/g, "").replaceAll(/<a[^>]*>(.*?)<\/a>/g, "").replaceAll("&", "_|_").replace(/\s+/g, ' ').trim()

                        var headerss = `<table>
                            <thead>
                            <tr>
                                <th>Scheme</th>
                                <th>Benchmark</th>
                                <th>As on date</th>
                                <th>Scheme Riskometer</th>
                                <th>Benchmark Riskometer</th>
                                <th>Scheme Regular NAV latest</th>
                                <th>Scheme Regular NAV prev</th>
                                <th>Scheme Direct NAV prev</th>
                                <th>Scheme Direct NAV prev</th>
                                <th>Scheme Regular 7Day</th>
                                <th>Scheme Regular 15Day</th>
                                <th>Scheme Regular 1Month</th>
                                <th>Scheme Regular 3Month</th>
                                <th>Scheme Regular 6Month</th>
                                <th>Scheme Regular 1Year</th>
                                <th>Scheme Regular 3Year</th>
                                <th>Scheme Regular 5Year</th>
                                <th>Scheme Regular 10Year</th>
                                <th>Scheme Regular Since launch</th>
                                <th>Scheme Direct 7Day</th>
                                <th>Scheme Direct 15Day</th>
                                <th>Scheme Direct 1Month</th>
                                <th>Scheme Direct 3Month</th>
                                <th>Scheme Direct 6Month</th>
                                <th>Scheme Direct 1Year</th>
                                <th>Scheme Direct 3Year</th>
                                <th>Scheme Direct 5Year</th>
                                <th>Scheme Direct 10Year</th>
                                <th>Scheme Direct Since launch</th>
                                <th>Benchmark Regular 7Day</th>
                                <th>Benchmark Regular 15Day</th>
                                <th>Benchmark Regular 1Month</th>
                                <th>Benchmark Regular 3Month</th>
                                <th>Benchmark Regular 6Month</th>
                                <th>Benchmark Regular 1Year</th>
                                <th>Benchmark Regular 3Year</th>
                                <th>Benchmark Regular 5Year</th>
                                <th>Benchmark Regular 10Year</th>
                                <th>Benchmark Regular Since launch</th>
                                <th>Benchmark Direct 7Day</th>
                                <th>Benchmark Direct 15Day</th>
                                <th>Benchmark Direct 1Month</th>
                                <th>Benchmark Direct 3Month</th>
                                <th>Benchmark Direct 6Month</th>
                                <th>Benchmark Direct 1Year</th>
                                <th>Benchmark Direct 3Year</th>
                                <th>Benchmark Direct 5Year</th>
                                <th>Benchmark Direct 10Year</th>
                                <th>Benchmark Direct Since launch</th>
                                <th>Prev AUM</th>
                                <th>AUM</th>
                            </tr>
                        </thead>
                            `


                        if (!tableData.includes("The underlying data is unavailable.")) {
                            console.log(tableData.length);
                            if (i === 0) {
                                i++
                                final.push(VRBenchPerfotoCSV(headerss + tableData + "</table>", formatDate(new Date('2024-08-22'))).toString().replaceAll("_|_", "&"))
                            } else {
                                final.push(VRBenchPerfotoCSV(tableData, formatDate(new Date('2024-08-22')), false).toString().replaceAll("_|_", "&"))
                            }
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                await timer(800);
            }
            console.log(final)
        }


        var str2 = final.join("\n")

        // download(final.join("\n"), "benchmarkPerf2.csv")


        var data = {
            "str1": str1,
            "str2": str2,
        }

        var config = {
            method: 'post',
            timeout: 10000,
            url: 'https://uat.mintbox.ai/api/core/readFromRetString',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };


        axios(config)
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            });




    }


    const fileUploader = async (csv, fileName) => {
        var formData = new FormData();
        // var imagefile = document.querySelector('#file');
        formData.append("file", csv);
        var a = await AdminServicesPY.updateFile(formData, fileName).then((r) => r)
        console.log(a)
    }

    const csvToJsonConv = async (csv, type) => {

        //type => 1 pledged,2 asm, 3gsm

        if (type === 1) {
            var json = await csvToJson({
                headers: ['proper_name',
                    'num_of_shares',
                    'promoters_shares',
                    'promoters_holding_per',
                    'public_holding_per',
                    'last_q_num_shares',
                    'last_q_per_share',
                    'last_q_total_share',
                    'last_q_total_share_value',
                    'disclosure',
                    'pledged_shares',
                    'demat_shares',
                    'per_pledged_demat',
                    'value',
                    'broadcast_date'],
                delimiter: ',',
            }).fromString(csv);

            var a = await AdminServicesPY.updatePledgedData({ data: json }).then((r) => r)
            console.log(a)
        } else if (type === 2) {
            var json = await csvToJson({
                headers: ["uid",
                    "symbol",
                    "proper_name",
                    "isin",
                    "stage"],
                delimiter: ',',
            }).fromString(csv);
            json = removeSelectedKeyVal(json, ['uid'])

            var a = await AdminServicesPY.updateASMData({ data: json }).then((r) => r)
            console.log(a)
        } else if (type === 3) {
            var json = await csvToJson({
                headers: ["uid",
                    "symbol",
                    "proper_name",
                    "isin",
                    "stage"],
                delimiter: ',',
            }).fromString(csv);
            json = removeSelectedKeyVal(json, ['uid'])

            var a = await AdminServicesPY.updateGSMData({ data: json }).then((r) => r)
            console.log(a)
        } else if (type === 4) {
            var json = await csvToJson({
                headers: ["uid",
                    "symbol",
                    "proper_name",
                    "isin",
                    "stage"],
                delimiter: ',',
            }).fromString(csv);
            json = removeSelectedKeyVal(json, ['uid'])

            var a = await AdminServicesPY.updateESMData({ data: json }).then((r) => r)
            console.log(a)
        } else {
            console.log("df ")
        }

        console.log(json)

    }


    const getAnnualReportsAll = async () => {
        var finalData = []
        fetch('https://api.mintbox.ai/py-api/core/getAllStocksWithBSECode')
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(async (data) => {
                // Do something with the data
                const responseData = JSON.parse(convertToJson(decodeData(data)))
                // console.log(responseData);

                for (var i = 0; i < responseData.length; i++) {
                    var item = responseData[i]
                    console.log(item?.symbol)
                    var akak = []
                    fetch(`https://api.bseindia.com/BseIndiaAPI/api/AnnualReport_New/w?scripcode=${item?.SCRIP_CODE}`).then(response => {
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        return response.json();
                    })
                        .then(data => {
                            // Do something with the data
                            // const responseData = JSON.parse(convertToJson(decodeData(data)))
                            console.log(data);

                            if (data.Table !== undefined) {
                                data.Table.map(async (iitem, i) => {
                                    akak.push({
                                        "file_name": item?.symbol + "_" + iitem?.Year,
                                        "fileLink": iitem?.PDFDownload
                                    })
                                    await timer(1000);
                                })
                            }



                        }).then(() => {
                            finalData.push({
                                [item?.symbol]: akak
                            })
                            console.log(finalData)
                        })
                        .catch(error => console.error("Error:", error));


                    await timer(1000); // then the created Promise can be awaited
                }
                // responseData.map(async (item, i) => {


                // })
            }).then(() => {
                console.log(finalData)
            })
            .catch(error => console.error("Error:", error));







        // fetch("https://api.bseindia.com/BseIndiaAPI/api/AnnGetData/w?pageno=1&strCat=Result&strPrevDate=20220620&strScrip=500209&strSearch=P&strToDate=20230620&strType=C").then(response => {
        //     if (!response.ok) {
        //         throw new Error("Network response was not ok");
        //     }
        //     return response.json();
        // })
        //     .then(data => {
        //         // Do something with the data
        //         // const responseData = JSON.parse(convertToJson(decodeData(data)))
        //         console.log(data);

        //         var link = 'https://www.bseindia.com/xml-data/corpfiling/AttachHis/' + "14dc8636-1158-4766-a7aa-c832df58c698.pdf"

        //         // responseData.map(async (item, i) => {
        //         //     await fetch('https://api.bseindia.com/BseIndiaAPI/api/AnnualReport_New/w?scripcode=500111').then((res) => {
        //         //         return res.json();
        //         //     }).then(data => {
        //         //         console.log(data)
        //         //     })
        //         // })
        //     })
        //     .catch(error => console.error("Error:", error));


    }


    const getQuaterlyReportsAll = async () => {
        var finalData = []
        fetch('https://api.mintbox.ai/py-api/core/getAllStocksWithBSECode')
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(async (data) => {
                // Do something with the data
                const responseData = JSON.parse(convertToJson(decodeData(data)))
                // console.log(responseData);

                for (var i = 0; i < responseData.length; i++) {
                    var item = responseData[i]
                    console.log(item?.symbol)
                    var akak = []
                    fetch(`https://api.bseindia.com/BseIndiaAPI/api/AnnualReport_New/w?scripcode=${item?.SCRIP_CODE}`).then(response => {
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        return response.json();
                    })
                        .then(data => {
                            // Do something with the data
                            // const responseData = JSON.parse(convertToJson(decodeData(data)))
                            console.log(data);

                            if (data.Table !== undefined) {
                                data.Table.map(async (iitem, i) => {
                                    akak.push({
                                        "file_name": item?.symbol + "_" + iitem?.Year,
                                        "fileLink": iitem?.PDFDownload
                                    })
                                    await timer(1000);
                                })
                            }



                        }).then(() => {
                            finalData.push({
                                [item?.symbol]: akak
                            })
                            console.log(finalData)
                        })
                        .catch(error => console.error("Error:", error));


                    await timer(1000); // then the created Promise can be awaited
                }
                // responseData.map(async (item, i) => {


                // })
            }).then(() => {
                console.log(finalData)
            })
            .catch(error => console.error("Error:", error));







        // fetch("https://api.bseindia.com/BseIndiaAPI/api/AnnGetData/w?pageno=1&strCat=Result&strPrevDate=20220120&strScrip=500209&strSearch=P&strToDate=20230620&strType=C").then(response => {
        //     if (!response.ok) {
        //         throw new Error("Network response was not ok");
        //     }
        //     return response.json();
        // })
        //     .then(data => {
        //         // Do something with the data
        //         // const responseData = JSON.parse(convertToJson(decodeData(data)))
        //         console.log(data);

        //         var link = 'https://www.bseindia.com/xml-data/corpfiling/AttachHis/' + "14dc8636-1158-4766-a7aa-c832df58c698.pdf"

        //         // responseData.map(async (item, i) => {
        //         //     await fetch('https://api.bseindia.com/BseIndiaAPI/api/AnnualReport_New/w?scripcode=500111').then((res) => {
        //         //         return res.json();
        //         //     }).then(data => {
        //         //         console.log(data)
        //         //     })
        //         // })
        //     })
        //     .catch(error => console.error("Error:", error));


    }


    const callEOD = async () => {
        var res = await AdminServicesPY.runEOD().then((r) => { return r })
        console.log(res)
        var res = await AdminServicesPY.runEOD().then((r) => { return r })
        console.log(res)
    }


    const getSharpelyBenchData = async () => {

        let numWeeks = 7;
        let now = new Date();
        // now.setDate(now.getDate() - numWeeks * 7);
        var startDate = now.setDate(now.getDate() - numWeeks * 7);
        var endDate = new Date()

        var allBench = await AdminServicesPY.fetchSharpelyBenchmarkData().then((r) => r)
        console.log(allBench?.data)
        allBench = JSON.parse(allBench?.data)

        console.log(allBench)

        var allMainData = []


        for (var i = 0; i < allBench.length; i++) {
            var bench = allBench[i]

            console.log(bench)
            // var data = {
            //     "cinfo": `{
            //     'name': ${bench.Ticker},
            //     'startDate': ${formatDate(startDate, "", "-")},
            //     'endDate': ${formatDate(endDate, "", "-")}
            // }`}

            // if ([50, 58]?.includes(bench?.Id)) {
            var data = {
                "cinfo": `{'name': '${bench.Ticker}','indexName': '${bench.NSETicker}','startDate': '${formatDate(startDate, "", "-")}','endDate': '${formatDate(endDate, "", "-")}'}`
            }
            var json1 = {}
            var json2 = {}

            let config = {
                method: 'post',
                // timeout: 10000,
                maxBodyLength: Infinity,
                url: 'https://www.niftyindices.com/Backpage.aspx/getHistoricaldatatabletoString',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            await axios.request(config)
                .then((response) => {
                    json1 = JSON.parse(response?.data?.d);
                })
                .catch((error) => {
                    console.log(error);
                });



            let config2 = {
                method: 'post',
                // timeout: 10000,
                maxBodyLength: Infinity,
                url: 'https://www.niftyindices.com/Backpage.aspx/getpepbHistoricaldataDBtoString',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    "cinfo": `{'name': '${bench.Ticker}','indexName': '${bench.NSETicker}','startDate': '${formatDate(startDate, "", "-")}','endDate': '${formatDate(endDate, "", "-")}'}`
                })
            };

            await axios.request(config2)
                .then((response) => {
                    json2 = JSON.parse(response?.data?.d);
                })
                .catch((error) => {
                    console.log(error);
                });





            // console.log(json1)
            console.log(bench?.Id, json1, json2)
            let jsonFinal = []
            for (let i = 0; i < json1.length; i++) {
                // console.log(String(String(json1[i]?.INDEX_NAME)?.toLocaleUpperCase())?.replaceAll(" ", "") == String(String(bench?.Ticker)?.toLocaleUpperCase())?.replaceAll(" ", ""))
                if (String(json1[i]?.INDEX_NAME)?.toLocaleUpperCase()?.replaceAll(" ", "") == String(bench?.Ticker)?.toLocaleUpperCase()?.replaceAll(" ", "")) {
                    // console.log(json1[i])
                    var jsonA = {
                        ...json1[i],
                    }

                    var jsonB = {
                        ...(json2?.find((itmInner) => itmInner?.DATE === json1[i]?.HistoricalDate)),
                    }
                    jsonFinal.push({
                        "Index Name": "",
                        "INDEX_NAME": "",
                        "HistoricalDate": "",
                        "DATE": "",
                        ...jsonA,
                        ...jsonB,
                        "OPEN": jsonA['OPEN'] === "-" ? "" : (jsonA['OPEN'] || ""),
                        "HIGH": jsonA['HIGH'] === "-" ? "" : (jsonA['HIGH'] || ""),
                        "LOW": jsonA['LOW'] === "-" ? "" : (jsonA['LOW'] || ""),
                        "CLOSE": jsonA['CLOSE'] === "-" ? "" : (jsonA['CLOSE'] || ""),
                        "pe": jsonB['pe'] === "-" ? "" : (jsonB['pe'] || ""),
                        "pb": jsonB['pb'] === "-" ? "" : (jsonB['pb'] || ""),
                        "divYield": jsonB['divYield'] === "-" ? "" : (jsonB['divYield'] || ""),
                        "IndexId": bench?.Id
                    });
                }
                // } 


               
            }
            console.log(jsonFinal)
            // await timer(1000);
            if (jsonFinal.length > 0) {
                var a = await AdminServices.insertSharpelyBenchData(JSON.stringify(jsonFinal)).then(r => r)
                console.log(a)
            }
            jsonFinal = []
            // await timer(1000);
            // allMainData = [...allMainData, ...jsonFinal]
        }


        // console.log(allMainData)




    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} style={{ width: "100%", height: "100%" }}>
                        <CardHeader heading={'Download CSV'} hideHeaderBlueLine />
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
                            <div className="App">
                                <Form.Label>*Start date:</Form.Label>
                                <Form.Control onChange={(newValue) => {
                                    console.log(newValue);
                                    setStartValue(newValue);
                                }} type="date"></Form.Control>
                            </div>
                            <span className='mx-2' />
                            <div className="App">
                                <Form.Label>*End date:</Form.Label>
                                <Form.Control onChange={(newValue) => {
                                    setEndValue(newValue);
                                }} type="date"></Form.Control>
                            </div>
                        </div>
                        <div className='margin20Top d-flex align-items-center' style={{ gap: "1rem", flexWrap: "wrap" }}>
                            <button className="btnPrimary" onClick={() => getETDATAfromNSE()}>Download All etfs closing data</button>
                            <button className="btnPrimary" onClick={() => getIndexDATAfromNSE()}>Download All Index closing data</button>
                            <button className="btnPrimary" onClick={() => getIndexNAVDATAfromNSE()}>Download All Benchmark NAV data</button>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} style={{ width: "100%", height: "100%" }}>
                        <CardHeader heading={'Update All Benchmarks TRI'} hideHeaderBlueLine />
                        <div className='margin20Top margin20Bottom'>
                            <button className="btnPrimary" onClick={() => updateDailyBenchmarkData()}>
                                Trigger
                            </button>
                        </div>
                        <CardHeader heading={'Update All Benchmarks Returns'} hideHeaderBlueLine />
                        <div className='margin20Top d-flex align-items-center' style={{ gap: "1.25rem" }}>
                            <button className="btnPrimary" onClick={() => getBenchmarkReturnsDaily(0)}>All Data</button>
                            <button className="btnPrimary" onClick={() => getBenchmarkReturnsDaily(1)}>Daily data</button>
                        </div>
                    </Card>
                </Col>
            </Row>

            <br></br>
            <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Update individual Benchmark TRI</h4>
                            <Form.Group className="row">
                                <label className="col-sm-2 col-form-label">Benchmark</label>
                                <div className="col-sm-6">
                                    <select className="form-control" onChange={(e) => {
                                        setbenchId(Number(e.target.value))
                                    }}>
                                        <option key={"-1"} value={-1}>Select benchmark..</option>
                                        {allbenchmarks.filter((bench) => bench.Origin === "").map((bench) => {
                                            return <option key={bench.UID} value={bench.UID}>{bench.BenchmarkName}</option>
                                        })}
                                    </select>
                                </div>
                            </Form.Group>
                            <div className="row">
                                <label className="col-sm-2 col-form-label">Date</label>
                                <div className="col-sm-4"> <Form.Control onChange={(newValue) => {
                                    setbenchDate(newValue.target.value);
                                }} type="date" />
                                </div>
                                <label className="col-sm-2 col-form-label">*Price</label>
                                <div className="col-sm-4">
                                    <Form.Control value={benchPrice} onChange={(text) => {
                                        var inputVal = text.target.value.replace(/[^0-9\s]/g, '');
                                        inputVal = inputVal.replace(" ", "")
                                        if (inputVal.length > 1) {
                                            inputVal = inputVal.replace(/^0+/, '')
                                        }
                                        setbenchPrice(Number(inputVal));
                                    }} type="tel" />
                                </div>
                            </div>
                            <br></br>
                            <button type="button" className="btn btn-primary btn-fw" onClick={() => updateIndividualDailyBenchmarkData()}>Update</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Update individual Value research tables</h4>
                            <Form.Group className="row">
                                <label className="col-sm-2 col-form-label">Table</label>
                                <div className="col-sm-6">
                                    <select className="form-control" onChange={(e) => {
                                        setvrTable(e.target.value)
                                    }}>
                                        <option key={"-1"} value={-1}>Select table..</option>
                                        {vrtables.map((bench) => {
                                            return <option key={bench} value={bench}>{bench}</option>
                                        })}
                                    </select>
                                </div>
                            </Form.Group>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div className="App">
                                    <Form.Label>*Start date:</Form.Label>
                                    <Form.Control onChange={(newValue) => {
                                        setVRStartValue(newValue.target.value);
                                    }} type="date"></Form.Control>
                                </div>
                                <span className='mx-2' />
                                <div className="App">
                                    <Form.Label>*End date:</Form.Label>
                                    <Form.Control onChange={(newValue) => {
                                        setVREndValue(newValue.target.value);
                                    }} type="date"></Form.Control>
                                </div>
                            </div>
                            <br></br>
                            <button type="button" className="btn btn-primary btn-fw" onClick={() => updateIndividualVRTable()}>Update</button>
                        </div>
                    </div>
                </div>
            </div>


            <Card className='margin20Bottom mx-2'>
                <CardHeader heading='Update Stock Reports' hideHeaderBlueLine />
                <div className='margin20Top'>
                    <AntForm layout="vertical" style={{ display: "flex", gap: "2rem" }}>
                        <AntForm.Item label="Pledged data">
                            <a href='https://www.nseindia.com/companies-listing/corporate-filings-pledged-data' target={'_blank'}>
                                https://www.nseindia.com/companies-listing/corporate-filings-pledged-data</a>
                            <AntForm.Item name="dragger1" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files" beforeUpload={file => {
                                    const reader = new FileReader();
                                    reader.onload = e => {
                                        console.log()
                                        console.log(e.target.result);
                                        // const csv = e.target.result.replace('"Short Term",,,,','')
                                        csvToJsonConv(e.target.result.replace('"Short Term",,,,', ''), 1)
                                    };
                                    reader.readAsText(file);
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                        <AntForm.Item label="ASM data">
                            <a href='https://www.nseindia.com/reports/asm' target={'_blank'}>https://www.nseindia.com/reports/asm</a>
                            <AntForm.Item name="dragger2" valuePropName="fileList2" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files2" beforeUpload={file => {
                                    const reader = new FileReader();
                                    reader.onload = e => {
                                        console.log()
                                        console.log(e.target.result);
                                        // const csv = e.target.result.replace('"Short Term",,,,','')
                                        csvToJsonConv(e.target.result.replace('"Short Term",,,,', ''), 2)
                                    };
                                    reader.readAsText(file);
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                        <AntForm.Item label="GSM data">
                            <a href='https://www.nseindia.com/reports/gsm' target={'_blank'}>https://www.nseindia.com/reports/gsm</a>
                            <AntForm.Item name="dragger3" valuePropName="fileList3" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files3" beforeUpload={file => {
                                    const reader = new FileReader();
                                    reader.onload = e => {
                                        console.log()
                                        console.log(e.target.result);
                                        // const csv = e.target.result.replace('"Short Term",,,,','')
                                        csvToJsonConv(e.target.result.replace('"Short Term",,,,', ''), 3)
                                    };
                                    reader.readAsText(file);
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>

                        <AntForm.Item label="ESM data">
                            <a href='https://www.nseindia.com/reports/esm' target={'_blank'}>https://www.nseindia.com/reports/esm</a>
                            <AntForm.Item name="dragger3" valuePropName="fileList3" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files3" beforeUpload={file => {
                                    const reader = new FileReader();
                                    reader.onload = e => {
                                        console.log()
                                        console.log(e.target.result);
                                        // const csv = e.target.result.replace('"Short Term",,,,','')
                                        csvToJsonConv(e.target.result.replace('"Short Term",,,,', ''), 4)
                                    };
                                    reader.readAsText(file);
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                    </AntForm>
                </div>
            </Card>
            <Row lg={3} sm={1} xs={1}>
                <Col>
                    <Card bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} style={{ width: "100%", height: "100%" }}>
                        <CardHeader heading='Get all funds Annual reports' hideHeaderBlueLine />
                        <button className='btnPrimary margin20Top' onClick={() => getAnnualReportsAll()}>Trigger</button>
                    </Card>
                </Col>
                <Col>
                    <Card bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} style={{ width: "100%", height: "100%" }}>
                        <CardHeader heading='Get all funds Quaterly reports' hideHeaderBlueLine />
                        <button className='btnPrimary margin20Top' onClick={() => getQuaterlyReportsAll()}>Trigger</button>
                    </Card>
                </Col>
                <Col>
                    <Card bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} style={{ width: "100%", height: "100%" }}>
                        <CardHeader heading='Get all Sharpely benhcmarks data' hideHeaderBlueLine />
                        <button className='btnPrimary margin20Top' onClick={() => getSharpelyBenchData()}>Trigger</button>
                    </Card>
                </Col>
            </Row>


            <br></br>

            <Card className='margin20Bottom mx-2'>
                <CardHeader heading='EOD Data uploader' hideHeaderBlueLine />
                <div className='margin20Top'>                    
                    <AntForm layout="vertical" style={{ display: "flex", gap: "2rem" }}>
                        <AntForm.Item label="Shareholding Patterns Equity 1M">
                            <a href='https://www.nseindia.com/companies-listing/corporate-filings-shareholding-pattern' target={'_blank'}>
                                Link</a>
                            <AntForm.Item name="dragger1" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files" beforeUpload={file => {
                                    fileUploader(file, "path_to_nse_shp")
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                        <AntForm.Item label="Shareholding Patterns SME 1M">
                            <a href='https://www.nseindia.com/companies-listing/corporate-filings-shareholding-pattern' target={'_blank'}>
                                Link</a>
                            <AntForm.Item name="dragger2" valuePropName="fileList2" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files2" beforeUpload={file => {
                                    fileUploader(file, "path_to_nse_sme_shp")
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                        <AntForm.Item label="Shareholding Patterns BSE 1W">
                            <a href='https://www.bseindia.com/corporates/Sharehold_Searchnew.aspx' target={'_blank'}>
                                Link</a>
                            <AntForm.Item name="dragger3" valuePropName="fileList3" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files3" beforeUpload={file => {
                                    fileUploader(file, "path_to_bse_shp")
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                    </AntForm>
                    <AntForm layout="vertical" style={{ display: "flex", gap: "2rem" }}>
                        <AntForm.Item label="Announcements Equity 1W">
                            <a href='https://www.nseindia.com/companies-listing/corporate-filings-announcements' target={'_blank'}>
                                Link</a>
                            <AntForm.Item name="dragger4" valuePropName="fileList4" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files4" beforeUpload={file => {
                                    fileUploader(file, "path_to_nse_corp_ann")
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                        <AntForm.Item label="Announcements SME 1W">
                            <a href='https://www.nseindia.com/companies-listing/corporate-filings-announcements' target={'_blank'}>
                                Link</a>
                            <AntForm.Item name="dragger5" valuePropName="fileList5" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files5" beforeUpload={file => {
                                    fileUploader(file, "path_to_nse_sme_corp_ann")
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                    </AntForm>
                    <AntForm layout="vertical" style={{ display: "flex", gap: "2rem" }}>
                        <AntForm.Item label="Bulk 1D">
                            <a href='https://www.nseindia.com/market-data/large-deals' target={'_blank'}>
                                Link</a>
                            <AntForm.Item name="dragger1" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files" beforeUpload={file => {
                                    fileUploader(file, "bulk_nse")
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                        <AntForm.Item label="Block 1D">
                            <a href='https://www.nseindia.com/market-data/large-deals' target={'_blank'}>
                                Link</a>
                            <AntForm.Item name="dragger2" valuePropName="fileList2" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger onDownload={(f) => {
                                    console.log(f)
                                }} accept='.csv' maxCount={3} multiple name="files2" beforeUpload={file => {
                                    fileUploader(file, "block_nse")
                                    // Prevent upload
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <BiUpload />
                                    </p>
                                </Upload.Dragger>
                            </AntForm.Item>
                        </AntForm.Item>
                    </AntForm>
                </div>
                <CardHeader heading='EOD Data Runner' hideHeaderBlueLine />
                <button className='btnPrimary margin20Top' onClick={() => callEOD()}>Trigger</button>
            </Card>

        </React.Fragment>
    );
}
