import React from "react";
import moengage from "@moengage/web-sdk";
import MainLayout from "./Layout/MainLayout";
import { ToastContainer } from "react-toastify";
import { useLocation } from 'react-router-dom';
import './styles.css';
import './App.scss';

export default function App() {

    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    React.useEffect(() => {
        moengage.initialize({ app_id: 'TEJQFNH8O8N2PGNR5D51EXE9', debug_logs: 0, enableSPA: 1 });
    }, [])

    return (
        <React.Fragment>
            <ToastContainer theme='dark' />
            <MainLayout />
        </React.Fragment>
    )
}