import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

const ClientInfo = (clientData) => {
    const [isEditPressed, setIsEditPressed] = React.useState(false)
    const [originalData, setOriginalData] = React.useState({})
    const [newData, setNewData] = React.useState({})

    React.useEffect(() => {
        const type = clientData?.Client?.[0]?.TaxStatus === "Individual" ? '0' : clientData?.Client?.[0]?.TaxStatus === "Minor" ? '1' : clientData?.Client?.[0]?.TaxStatus === "Nri" ? '2' : '';
        var d = {
            IsProfileComplete: clientData?.Client?.[0]?.IsProfileComplete || '',
            clientId: clientData?.Client?.[0]?.ClientId,
            pan: type === '1' ? '' : clientData?.Client?.[0]?.PAN,
            taxStatus: type,
            displayName: clientData?.Client?.[0]?.DisplayName || '',
            clientName: clientData?.Client?.[0]?.ClientName || '',
            gender: clientData?.Client?.[0]?.Gender || '',
            dateOfBirth: clientData?.Client?.[0]?.DateofBirth ? new Date(clientData?.Client?.[0]?.DateofBirth) : null,
            mobileNumber: type === '2' ? clientData?.Client?.[0]?.MobileNumber : '',
            // kycStatus: type === '1' ? userProfile.GuardianPan.kycRegistered : userProfile.panData.kycRegistered,
            guardianPAN: type === '1' ? clientData?.Client?.[0]?.GuardianPAN : '',
            guardianName: type === '1' ? clientData?.Client?.[0]?.GuardianName : '',
            guardianDOB: clientData?.Client?.[0]?.GuardianDOB ? new Date(clientData?.Client?.[0]?.GuardianDOB) : null,
            panFileId: clientData?.Client?.[0]?.PANFileId,
            signFileId: clientData?.Client?.[0]?.BankFileId,
            // address
            address1: clientData?.Addresses?.[0]?.Address1 || '',
            address2: clientData?.Addresses?.[0]?.Address2 || '',
            pincode: clientData?.Addresses?.[0]?.Pincode || '',
            city: clientData?.Addresses?.[0]?.City || '',
            state: clientData?.Addresses?.[0]?.State || '',
            countryId: clientData?.Addresses?.[0]?.CountryId || '',
            // Fatcas
            annualIncomeSlab: clientData?.Fatcas?.[0]?.AnnualIncome,
            sourceofWealth: clientData?.Fatcas?.[0]?.SourceofWealth,
            occupation: clientData?.Fatcas?.[0]?.OccupationId,
            politicalExposure: clientData?.Fatcas?.[0]?.PoliticalExposure,
            isTaxResident: clientData?.Fatcas?.[0]?.TaxResident,
            // nominee
            nomineeName: clientData?.Nominees?.[0]?.Name,
            nomineeRelation: clientData?.Nominees?.[0]?.Relation,
            nomineeDOB: clientData?.Nominees?.[0]?.DateofBirth ? new Date(clientData?.Nominees?.[0]?.DateofBirth) : null,
            nomineeGuardianName: clientData?.Nominees?.[0]?.GuardianName,
            // bank
            bankId: clientData?.BankInfo?.[0]?.BankId,
            bankName: clientData?.BankInfo?.[0]?.BankName,
            accountType: clientData?.BankInfo?.[0]?.AccountType,
            accountNumber: clientData?.BankInfo?.[0]?.AccountNumber,
            ifsc: clientData?.BankInfo?.[0]?.IFSC,
            bankFileId: clientData?.Client?.[0]?.BankFileId,
        }
        setOriginalData(d)
        setNewData(d)
    }, [clientData])

    React.useEffect(() => {
        console.log(newData)
    }, [newData])
    const editData = (key, value) => {
        setNewData({ ...newData, [key]: value })
    }
    return (
        <React.Fragment>
            <div className="col-12 grid-margin">
                <div className="card" style={{ border: 'none' }}>
                    <div className="card-body">
                        {/* <h4 className="card-title">Horizontal Two column</h4> */}
                        {/* <div style={{ textAlign: 'right' }}>
                            {isEditPressed ?
                                <button type="button" className="btn btn-danger btn-icon-text mr-2" onClick={() => { setIsEditPressed(!isEditPressed); setNewData(originalData) }}>
                                    Cancel <i className="mdi mdi-window-close btn-icon-append"></i>
                                </button>
                                :
                                <button type="button" className="btn btn-warning btn-icon-text mr-2" onClick={() => setIsEditPressed(!isEditPressed)}>
                                    Edit <i className="mdi mdi-file-check btn-icon-append"></i>
                                </button>
                            }
                            <button type="button" className="btn btn-primary btn-icon-text" onClick={() => setIsEditPressed(false)}>
                                <i className="mdi mdi-file-check btn-icon-prepend"></i>
                                Save
                            </button>
                        </div> */}
                        <form className="form-sample">
                            <p className="card-description"> Personal info </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">PAN</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.pan} disabled={!isEditPressed || newData.IsProfileComplete === 1}
                                                onChange={(e) => editData("pan", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Tax status</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.taxStatus} disabled={!isEditPressed || newData.IsProfileComplete === 1}
                                                onChange={(e) => editData("taxStatus", e.target.value)}>
                                                <option value={""} hidden></option>
                                                <option value={'0'}>Individual</option>
                                                <option value={'1'}>Minor</option>
                                                <option value={'2'}>NRI</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Display Name</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.displayName} disabled={!isEditPressed}
                                                onChange={(e) => editData("displayName", e.target.value)}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Full Name</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.clientName} disabled={!isEditPressed}
                                                onChange={(e) => editData("clientName", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Gender</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.gender} disabled={!isEditPressed}
                                                onChange={(e) => editData("gender", e.target.value)}>
                                                <option value={""} hidden></option>
                                                <option value={1}>Male</option>
                                                <option value={2}>Female</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Date of Birth</label>
                                        <div className="col-sm-9">
                                            <DatePicker className="form-control w-100" disabled={!isEditPressed}
                                                selected={newData?.dateOfBirth}
                                                onChange={(e) => editData("dateOfBirth", e.target.value)}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            {
                                newData.taxStatus === '2' ?
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="row">
                                                <label className="text-muted col-sm-3 col-form-label">Phone</label>
                                                <div className="col-sm-9">
                                                    <Form.Control type="text" value={newData?.mobileNumber} disabled={!isEditPressed}
                                                        onChange={(e) => editData("mobileNumber", e.target.value)} />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                newData.taxStatus === '1' ?
                                    <>
                                        <p className="card-description"> Guardian </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="row">
                                                    <label className="text-muted col-sm-3 col-form-label">Pan</label>
                                                    <div className="col-sm-9">
                                                        <Form.Control type="text" value={newData?.guardianPAN} disabled={!isEditPressed}
                                                            onChange={(e) => editData("guardianPAN", e.target.value)} />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="row">
                                                    <label className="text-muted col-sm-3 col-form-label">Name</label>
                                                    <div className="col-sm-9">
                                                        <Form.Control type="text" value={newData?.guardianName} disabled={!isEditPressed}
                                                            onChange={(e) => editData("guardianName", e.target.value)} />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="row">
                                                    <label className="text-muted col-sm-3 col-form-label">Date of Birth</label>
                                                    <div className="col-sm-9">
                                                        <DatePicker className="form-control w-100" disabled={!isEditPressed}
                                                            selected={newData?.guardianDOB}
                                                            onChange={(e) => editData("guardianDOB", e.target.value)}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </>

                                    :
                                    <></>
                            }
                            <p className="card-description"> Address </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Address 1</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.address1} disabled={!isEditPressed}
                                                onChange={(e) => editData("address1", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Address 2</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.address2} disabled={!isEditPressed}
                                                onChange={(e) => editData("address2", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Pincode</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.pincode} disabled={!isEditPressed}
                                                onChange={(e) => editData("pincode", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">City</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.city} disabled={!isEditPressed || newData.taxStatus === '0' || newData.taxStatus === '1'}
                                                onChange={(e) => editData("city", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">State</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.state} disabled={!isEditPressed || newData.taxStatus === '0' || newData.taxStatus === '1'}
                                                onChange={(e) => editData("state", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Country</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.countryId} disabled={!isEditPressed || newData.taxStatus === '0' || newData.taxStatus === '1'}
                                                onChange={(e) => editData("countryId", e.target.value)} >
                                                <option value={""} hidden></option>
                                                <option value={106}>India</option>
                                                <option value={1}>America</option>
                                                <option value={2}>Italy</option>
                                                <option value={3}>Russia</option>
                                                <option value={4}>Britain</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <p className="card-description"> Fatcas </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Annual income</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.annualIncomeSlab} disabled={!isEditPressed}
                                                onChange={(e) => editData("annualIncomeSlab", e.target.value)}>
                                                <option value={""} hidden></option>
                                                <option value={1} hidden={newData.taxStatus === '2'}>Savings</option>
                                                <option value={2} hidden={newData.taxStatus === '2'}>Current</option>
                                                <option value={3} hidden={newData.taxStatus === '0' || newData.taxStatus === '1'}>NRE</option>
                                                <option value={4} hidden={newData.taxStatus === '0' || newData.taxStatus === '1'}>NRO</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Source of wealth</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.sourceofWealth} disabled={!isEditPressed}
                                                onChange={(e) => editData("sourceofWealth", e.target.value)}>
                                                <option value={""} hidden></option>
                                                <option value={1} hidden={newData.taxStatus === '2'}>Savings</option>
                                                <option value={2} hidden={newData.taxStatus === '2'}>Current</option>
                                                <option value={3} hidden={newData.taxStatus === '0' || newData.taxStatus === '1'}>NRE</option>
                                                <option value={4} hidden={newData.taxStatus === '0' || newData.taxStatus === '1'}>NRO</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Occupation</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.sourceofWealth} disabled={!isEditPressed}
                                                onChange={(e) => editData("sourceofWealth", e.target.value)}>
                                                <option value={""} hidden></option>
                                                <option value={1} hidden={newData.taxStatus === '2'}>Savings</option>
                                                <option value={2} hidden={newData.taxStatus === '2'}>Current</option>
                                                <option value={3} hidden={newData.taxStatus === '0' || newData.taxStatus === '1'}>NRE</option>
                                                <option value={4} hidden={newData.taxStatus === '0' || newData.taxStatus === '1'}>NRO</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>
                                {/* <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">IFSC</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.ifsc} disabled={!isEditPressed}
                                                onChange={(e) => editData("ifsc", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Are you a politically exposed person?</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.politicalExposure} disabled={!isEditPressed}
                                                onChange={(e) => editData("politicalExposure", e.target.value)}>
                                                <option value={""} hidden></option>
                                                <option value={'Y'}>Yes</option>
                                                <option value={'N'}>No</option>
                                                <option value={'R'}>No, but i am related to a politicaly exposed person</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Are you a tax resident of any other country than India?</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.isTaxResident} disabled={!isEditPressed}
                                                onChange={(e) => editData("isTaxResident", e.target.value)}>
                                                <option value={""} hidden></option>
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <p className="card-description"> Nominee details </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Nominee name</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.nomineeName} disabled={!isEditPressed}
                                                onChange={(e) => editData("nomineeName", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Relation with you</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.nomineeRelation} disabled={!isEditPressed}
                                                onChange={(e) => editData("nomineeRelation", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Date of birth</label>
                                        <div className="col-sm-9">
                                            <DatePicker className="form-control w-100" disabled={!isEditPressed}
                                                selected={newData?.nomineeDOB}
                                                onChange={(e) => editData("nomineeDOB", e.target.value)}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                                {/* <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Legal guardian name</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.guardianName} disabled={!isEditPressed}
                                                onChange={(e) => editData("guardianName", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div> */}
                            </div>
                            <p className="card-description"> Bank Info </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Account no.</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.accountNumber} disabled={!isEditPressed}
                                                onChange={(e) => editData("accountNumber", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Account type</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={newData?.accountType} disabled={!isEditPressed}
                                                onChange={(e) => editData("accountType", e.target.value)}>
                                                <option value={""} hidden></option>
                                                <option value={1} hidden={newData.taxStatus === '2'}>Savings</option>
                                                <option value={2} hidden={newData.taxStatus === '2'}>Current</option>
                                                <option value={3} hidden={newData.taxStatus === '0' || newData.taxStatus === '1'}>NRE</option>
                                                <option value={4} hidden={newData.taxStatus === '0' || newData.taxStatus === '1'}>NRO</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">Bank name</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.bankName} disabled={!isEditPressed}
                                                onChange={(e) => editData("bankName", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="text-muted col-sm-3 col-form-label">IFSC</label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" value={newData?.ifsc} disabled={!isEditPressed}
                                                onChange={(e) => editData("ifsc", e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ClientInfo;