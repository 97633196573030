import React, { Component, useEffect, useState } from 'react';
import { Button, Card, Divider, Modal, Radio, Select } from 'antd';
import 'quill/dist/quill.snow.css';
import Editor from './Editor';
import AdminServices from '../../services/api/AdminServices';
import AceEditor from 'react-ace';
import formatter from 'html-formatter';
import { toast } from 'react-toastify';
import { encode } from 'html-entities';
import { MdPublish } from 'react-icons/md';
import { BiCodeAlt } from 'react-icons/bi';
import { CardHeader } from '../../Components/Atoms/CardHeader';

function CreatePost() {
    const [allDescItems, setAllDescItems] = useState([]);
    const [instrumentType, setInstrumentType] = useState(1);
    const [description, setDescription] = useState('');
    const [desc_id, setDesc_id] = useState('');
    const [previewHTML, setPreviewHTML] = useState(false);

    function onChange(data) {
        setDescription(data);
        document.getElementById("new").innerHTML = data;
        document.getElementById("new").scrollTop = document.getElementById("new").scrollHeight;
    }

    function onChangeInstrument(e) {
        setInstrumentType(e.target.value);
        setDesc_id('');
        setDescription('');
    }

    function onChangeCategory(value) {
        setDesc_id(value);
        setDescription(allDescItems.filter((item) => item.desc_id === value)[0]['description']);
    }
    const updateInstrumentDescription = async (data) => {
        await AdminServices.updateInstrumentDesc(data).then(res => {
            if (res.type) {
                toast.success('Published');
                getInstrumentDescriptions();
            }
            else {
                toast.error('Something went wrong!')
            }
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
        let s = description;
        s = s.split('');
        let chars = ['\'']
        for (let i = 0; i < s.length; i++) {
            if (chars.includes(s[i])) {
                s[i] = encode(s[i]);
            }
        }
        s = s.join('');
        console.log(s);
        const data = { instrumentType, id: desc_id, description: s };
        updateInstrumentDescription(data);
    }

    const getInstrumentDescriptions = async () => {
        await AdminServices.getInstrumentDesc(instrumentType).then(res => { setAllDescItems(res.data); })
    }
    useEffect(() => {
        getInstrumentDescriptions();
    }, [instrumentType])
    return (
        <React.Fragment>
            <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                <CardHeader heading={'Create Knowledge Base Doc'} />
                <Divider style={{ margin: "1rem 0" }} />
                <Radio.Group onChange={onChangeInstrument} value={instrumentType} className='margin20Bottom'>
                    <Radio value={1}>ETF/MF</Radio>
                    <Radio value={4}>Stocks</Radio>
                </Radio.Group>
                <div className='margin20Bottom'>
                    <label>Category: </label>
                    <br />
                    <Select placeholder='Select an item' style={{ width: '280px' }} onChange={onChangeCategory}
                        className='mw-100' showSearch filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } optionFilterProp="children" >
                        {allDescItems.map((item) => (
                            <option key={item.desc_id} value={item.desc_id} label={`${item.name},Id=${item.desc_id}`}>
                                {item.name},Id={item.desc_id}
                            </option>
                        ))}
                    </Select>
                </div>
                <div>
                    <label> Description:</label>
                    <div className='d-flex mb-3 flex-column flex-md-row' style={{ gap: "20px" }}>
                        <div className="editor-kb" style={{ width: "55%", flexShrink: 0 }}>
                            <Editor onChange={onChange} description={description} desc_id={desc_id} />
                        </div>
                        <div style={{
                            border: "1px solid var(--grey1)", flexGrow: 1, overflow: "scroll", height: "370px",
                            display: "flex", flexDirection: "column", zIndex: 0,
                            borderRadius: "10px"
                        }}>
                            <div className='sticky-top p-2' style={{
                                borderBottom: "1px solid rgb(255,255,255,0.25)", fontSize: "17px", background: "var(--dark4)",
                                color: "var(--white)"
                            }}>
                                Preview Doc
                            </div>
                            <div id="new" className='ql-editor' style={{ background: "white", color: "black" }}>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <button className="btnPrimary mr-4" onClick={handleSubmit} >
                        <MdPublish /> Publish
                    </button>
                    <button className='btnBlack' onClick={() => setPreviewHTML(true)}>
                        <BiCodeAlt className='mr-2' />See HTML
                    </button>
                </div>
                <Modal onOk={() => setPreviewHTML(false)} open={previewHTML} title='HTML Content' onCancel={() => setPreviewHTML(false)}
                    zIndex={1021} >
                    <AceEditor
                        placeholder="Write something"
                        mode="html"
                        fontSize={14}
                        onLoad={(editor) => {
                            editor.setReadOnly(true)
                        }}
                        width='100%'
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={formatter.render(description)}
                        setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            wrap: true,
                        }} />
                </Modal>
            </Card>
        </React.Fragment>
    );
}

export default CreatePost;
