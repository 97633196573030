import React from 'react'
const NotFound = ({ text, buttonText, onClick, type }) => {
    return (
        <div style={{ margin: "10% 15%", textAlign: 'center' }}>
            <img style={{ width: '120px', height: "120px" }} 
                src={type==='mintbox'?'https://d3etfstcuyoos5.cloudfront.net/app-images/web-icons/no-invest-mintbox.png':type==='goal'?'https://d3etfstcuyoos5.cloudfront.net/app-images/web-icons/no-goal.png':'https://d3etfstcuyoos5.cloudfront.net/app-images/web-icons/no-invest.png'} alt="" />
            <div className="bigHeader" style={{ marginTop: "24px", marginBottom: "40px" }}>{text}</div>
            {/* <button className="outlineCardButton">
                {buttonText}
            </button> */}
        </div>
    )
}

export default NotFound