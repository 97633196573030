import React from 'react';
import { Spin } from 'antd';

const ActivityLoaderMintbox = ({ type = '', margin = '10%' }) => {

  return (
    <div style={{ marginTop: margin, marginBottom: margin, width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Spin style={{ color: "var(--primary)" }} size='large' />
    </div>
  )
}

export default React.memo(ActivityLoaderMintbox)