import React from "react";
import { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';

const Editor = ({ onChange }) => {
    const { quill, quillRef, Quill } = useQuill({
        modules: { blotFormatter: {} }
    });

    if (Quill && !quill) {
        Quill.register('modules/blotFormatter', BlotFormatter);
    }

    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldContents) => {
                onChange(quill.root.innerHTML)
                let currrentContents = quill.getContents();
            });
        }
    }, [quill, Quill]);

    return (
        <div>
            <div ref={quillRef} style={{ height: "min(80vh, 500px)", overflow: "scroll", color: "black" }} />
        </div>
    );
};

export default Editor;

export const Editor2 = ({ onChange, content = null }) => {
    const { quill, quillRef, Quill } = useQuill({
        modules: { blotFormatter: {} }
    });

    if (Quill && !quill) {
        Quill.register('modules/blotFormatter', BlotFormatter);
    }

    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldContents) => {
                onChange(quill.root.innerHTML)
                let currrentContents = quill.getContents();
            });
        }
    }, [quill, Quill]);

    useEffect(() => {
        if (quill && content) {
            quill.clipboard.dangerouslyPasteHTML(`${content}`)
        }
    }, [content]);

    return (
        <div>
            <div ref={quillRef} style={{ height: "min(80vh, 500px)", overflow: "scroll", color: "black" }} />
        </div>
    );
};