import { Card, Divider, Tag } from 'antd';
import ActivityLoaderMintbox from 'Assets/styles/components/ActivityLoaderMintbox';
import React from 'react';
import { FaUserFriends } from 'react-icons/fa';
import { TbPremiumRights } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import AdminServices from '../../services/api/AdminServices';
import ClientGrowthChart from './Charts/ClientGrowthChart';
import styles from './dashboard.module.css';

export default function Dashboard() {
  const [allMintboxes, setAllMintboxes] = React.useState([]);
  const [allClients, setAllClients] = React.useState([])
  const [client, setClients] = React.useState([])
  const [clientGrowth, setClientGrowth] = React.useState([])
  const [aumGrowth, setAumGrowth] = React.useState([])
  const [aum, setAUM] = React.useState()
  const [aumByAMC, setAumByAMC] = React.useState([])
  const [aumByCategory, setAumByCategory] = React.useState([])
  const [aumBySubCategory, setAumBySubCategory] = React.useState([])
  const [loading, setLoading] = React.useState(true);
  const [assetAllocation, setAssetAllocation] = React.useState([]);

  const getMonth = (n) => {
    if (n === 1) return "Jan";
    if (n === 2) return "Feb";
    if (n === 3) return "Mar";
    if (n === 4) return "Apr";
    if (n === 5) return "May";
    if (n === 6) return "Jun";
    if (n === 7) return "Jul";
    if (n === 8) return "Aug";
    if (n === 9) return "Sep";
    if (n === 10) return "Oct";
    if (n === 11) return "Nov";
    if (n === 12) return "Dec";
  }

  React.useEffect(() => {
    loadData()
  }, []);

  const loadData = async () => {
    // await getAum(1)
    await getIFAClients(-1);
    // await getClientCount(1)
    await getClientGrowth(1)
    // await getAumGrowth(1)
    // await getAumByAmc(1)
    // await getAumByCategory(1)
    // await getAumBySubCategory(1)
    setLoading(false)
  }

  const getIFAClients = async (id) => {
    let res = await AdminServices.getIFAClients(id).then(res => res);
    if (res.type === true) {
      let aa = res.data;
      setAllClients(aa);
    }
  }

  const getAum = async (id) => {
    let res = await AdminServices.getAUM(id).then(res => res);
    if (res.type === true) {
      const aa = res.data.AUM;
      setAUM(aa)
    }
  }

  const getAumGrowth = async (id) => {
    let res = await AdminServices.getAumGrowth(id).then(res => res);
    if (res.type === true) {
      const aa = res.data;
      const x = JSON.parse(aa)
      const y = x[0].Portfolio
      setAumGrowth(y)
    }
  }

  const getClientCount = async (id) => {
    let res = await AdminServices.getClientCount(id).then(res => res);
    if (res.type === true) {
      const aa = res.data;
      setClients(aa)
    }
  }

  const getClientGrowth = async (id) => {
    let res = await AdminServices.getClientGrowth(id).then(res => res);
    if (res.type === true) {
      const aa = res.data;
      console.log(aa);
      setClientGrowth(aa)
    }
  }

  const getAumByAmc = async (id) => {
    let res = await AdminServices.getAumByAmc(id).then(res => res);
    if (res.type === true) {
      const aa = res.data;
      setAumByAMC(aa)
    }
  }

  const getAumByCategory = async (id) => {
    let res = await AdminServices.getAumByCategory(id).then(res => res);
    if (res.type === true) {
      const aa = res.data;
      setAumByCategory(aa);
      formatAssetAllo(aa)
    }
  }

  const getAumBySubCategory = async (id) => {
    let res = await AdminServices.getAumBySubCategory(id).then(res => res);
    if (res.type === true) {
      const aa = res.data;
      setAumBySubCategory(aa)
    }
  }

  const formatAssetAllo = (aa) => {

    let percentArr = []
    let values = Object.values(aa)
    const totalAllocation = values.reduce((total, r) => total + r, 0)

    if (aa.length !== 0) {
      let subTotal
      for (let i = 0; i < values.length; i++) {
        subTotal = values[i] * 100 / totalAllocation
        percentArr.push(Number(subTotal.toFixed(2)))
      }
    }

    const assetClasses = [
      {
        name: 'Commodities',
        color: "var(--indianEquity)",
        y: percentArr[0]
      },
      {
        name: 'Debt',
        color: "var(--debt)",
        y: percentArr[1]
      },
      {
        name: 'Equity',
        color: "var(--internationalEquity)",
        y: percentArr[2]
      },
    ];
    setAssetAllocation(assetClasses)
  }

  const aumByCountry = [
    {
      name: 'India',
      color: "var(--indianEquity)",
      y: 100
    }
  ];

  const aumByGender = [
    {
      name: 'Female',
      color: "var(--indianEquity)",
      y: 50
    },
    {
      name: 'Male',
      color: "var(--debt)",
      y: 50
    }
  ];

  if (loading) {
    return <ActivityLoaderMintbox />
  }
  return (
    <div style={{ margin: "0px", width: "100%" }}>
      <div className={styles["aum-cards-container"]} style={{ gap: "2rem" }}>
        {/* <Card bordered={false} className={styles['aum-card-dashboard']}>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <div className="w-500" style={{ fontSize: "15px" }}>AUM</div>
              <div className=" textLight" style={{ fontSize: "12px" }}>(Total asset under management)</div>
            </div>
            <Tag color='#58985f' className='p-1'>
              <BiRupee color='white' size='16px' />
            </Tag>
          </div>
          <Divider style={{ margin: "8px 0px" }} />
          <div className="fontH1 w-500" style={{ marginBottom: "0px", fontSize: "20px" }}>{CurrencyFormat(aum, "short")}</div>
        </Card> */}

        <Card className={styles['aum-card-dashboard']} bordered={false}>

          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <div className="w-500" style={{ fontSize: "15px" }}>Clients</div>
              <div className=" textLight" style={{ fontSize: "12px" }}>(Total number of clients)</div>
            </div>
            <Tag color='dodgerblue' className='p-1'>
              <FaUserFriends size='16px' />
            </Tag>
          </div>
          <Divider style={{ margin: "8px 0px" }} />
          <div className="fontH1 w-500" style={{ marginBottom: "0px", fontSize: "20px" }}>{allClients?.length}</div>
        </Card>

        <Card className={styles['aum-card-dashboard']} bordered={false}>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <div className=" w-500" style={{ fontSize: "15px" }}>Paid Clients</div>
              <div className=" textLight" style={{ fontSize: "12px" }}>(Total number of clients)</div>
            </div>
            <Tag color='#dc143c' className='p-1'>
              <TbPremiumRights size='16px' />
            </Tag>
          </div>
          <Divider style={{ margin: "8px 0px" }} />
          <div className='d-flex align-items-center justify-content-between'>
            <div className="fontH1 w-500" style={{ marginBottom: "0px", fontSize: "20px" }}>{allClients?.filter((el) => el?.SharpelyAccessLevel > 1)?.length}</div>
            <Link to={'/clients/paid-clients'}>Detail analysis</Link>
          </div>
        </Card>

      </div>
      <div className={styles["aum-charts-container"]} style={{ gap: "2rem" }}>
        {/* <Card style={{ flex: 1 }} bordered={false} className={styles["aum-charts-card"]}>
          <div className="fontH2 w-500" >AUM Growth</div>
          <Divider />
          {aumGrowthChart(aumGrowth)}
        </Card> */}
        <Card style={{ flex: 1 }} bordered={false} className={styles['aum-charts-card']}>
          <div className="fontH2 w-500" >Number of Users</div>
          <Divider />
          {ClientGrowthChart(clientGrowth)}
        </Card>

      </div>
      {/* <div className={styles["aum-charts-container"]} style={{ gap: "2rem" }}>
        <Card style={{ flex: 1 }} className={styles["aum-charts-card"]} bordered={false}>
          <div className="fontH2 w-500" >AUM by AMC</div>
          <Divider />
          {AumCategoryChart(aumByAMC, "AUM", "#8087E8")}
        </Card>
        <Card style={{ flex: 1 }} className={styles["aum-charts-card"]} bordered={false}>
          <div className="fontH2 w-500" >AUM By Sub Category</div>
          <Divider />
          {AumCategoryChart(aumBySubCategory, "AUM", '#2D918F')}
        </Card>
      </div> */}
      {/* <div className={styles['aum-charts-container']} style={{ gap: "2rem" }}>
        <Card style={{ flex: 1 }} className={styles['aum-charts-card']} bordered={false}>
          <div className="fontH2 w-500" >AUM By Category</div>
          <Divider />
          {AumPieChart(assetAllocation, "Categories")}
        </Card> 
        <Card style={{ flex: 1 }} className={styles['aum-charts-card']} bordered={false}>
          <div className="fontH2 w-500" >AUM by Country</div>
          <Divider />
          {AumPieChart(aumByCountry, 'Contries')}
        </Card>
        <Card style={{ flex: 1 }} className={styles['aum-charts-card']} bordered={false}>
          <div className="fontH2 w-500" >AUM By Gender</div>
          <Divider />
          {AumPieChart(aumByGender, "Gender")}
        </Card>
      </div> */}
    </div>
  );
}