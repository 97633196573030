import { Button, Card, Divider, Input, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { MdPublish } from "react-icons/md";
import AdminServices from '../../services/api/AdminServices';
import { toast } from 'react-toastify'
import { PoweroffOutlined } from '@ant-design/icons';
import { AiFillEdit } from "react-icons/ai";

const EditCategory = () => {
    const [category, setcategory] = useState('');
    const [description, setDescription] = useState('');
    const [allCategories, setAllCategories] = useState([])
    const [loading, setLoading] = useState(false);
    const [isEditBtnPressed, setIsEditBtnPressed] = useState(false);
    const onChangecategory = (e) => setcategory(e.target.value);
    const onChangeDescription = (e) => setDescription(e.target.value);

    const handleValidation = () => {
        let temp = allCategories.filter((item) => item.Category.toLowerCase() === category.trim().toLowerCase());
        if (!category.trim()) {
            toast.warn("Enter valid category title!");
            return false;
        }
        else if (description.trim().split(' ').length > 100) {
            toast.warn("Max word limit of description is 100!");
            return false;
        }
        else if (temp.length) {
            toast.error("Category already exists!");
            return false;
        }
        else {
            return true;
        }
    }

    const getAllCategories = async () => {
        let res = await AdminServices.getBlogCategories().then(res => res);
        if (res.type === true) {
            setAllCategories(res.data)
        }
    }

    const handlePublish = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (handleValidation()) {
            let data = { category: category.trim(), description: description.trim() }
            let res = await AdminServices.saveBlogCategory(data).then(res => res);
            toast.success("Category published.")
            await getAllCategories();
        }
        setLoading(false)
    }

    React.useEffect(() => {
        getAllCategories();
    }, [])
    return (
        <React.Fragment>
            <h2 className='mb-4 font-size-h2'>
                All Categories
            </h2>

            <div className='mb-4'>
                <label>
                    Category Title:
                </label>
                <br />
                <Input placeholder='Title' style={{ fontSize: "16px", padding: "5px 10px" }} className='mw-100 custom-input-antd' value={category}
                    onChange={onChangecategory} />
            </div>
            <div className='mb-4'>
                <label>
                    Description:
                </label>
                <br />
                <TextArea placeholder='Description' value={description} onChange={onChangeDescription} style={{
                    width: "100%", height: "200px", resize: "none", fontSize: "15px", padding: "8px 10px", background: "var(--bgCard)"
                }} />
            </div>
            <div className='mb-2'>
                <Button type='primary' onClick={handlePublish} disabled={loading}>
                    <MdPublish className='mr-2' />Publish
                </Button>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: "1.25rem" }}
                className='margin20Top'>
                {allCategories?.map((item, i) => (
                    <Card style={{ background: "var(--bgCard)", border: "none", padding: "0rem" }} hoverable={true} key={i}
                        bodyStyle={{ height: "100%" }}>
                        <div className="d-flex flex-column justify-content-between" style={{ gap: "0.5rem", height: "100%" }}>
                            <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
                                <div className='textLG w-500'>
                                    {item.Category}
                                </div>
                                <Divider style={{ margin: "0" }} />
                                <div className='textSM'>
                                    {item.Description}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end" style={{ gap: "0.75rem" }}>
                                <Button danger className="d-flex align-items-center justify-content-center" style={{ gap: "0.25rem" }}
                                    type='primary'
                                // onClick={() => handleDelete(item.UID)}
                                >
                                    Delete
                                </Button>
                                <Button type='primary' className="d-flex align-items-center justify-content-center" style={{ gap: "0.25rem" }}
                                // onClick={() => onBlogTileClick(item)}
                                >
                                    <AiFillEdit /> Edit
                                </Button>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
        </React.Fragment>
    )
}

export default EditCategory;
