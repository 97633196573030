import { Button, Card, Divider, Input, Select, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdPublish } from "react-icons/md";
import { toast } from "react-toastify";
import AdminServices from "../../services/api/AdminServices";
import { CardHeader } from "../../Components/Atoms/CardHeader";

const CreateSubCategory = () => {

    const [subCategory, setsubCategory] = useState('');
    const [category, setCategory] = useState()
    const [description, setDescription] = useState('');
    const [allCategories, setAllCategories] = useState([]);
    const [allSubCategories, setAllSubCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const onChangesubCategory = (e) => setsubCategory(e.target.value);
    const onChangeCategory = (value) => setCategory(value);
    const onChangeDescription = (e) => setDescription(e.target.value);

    const handleValidation = () => {
        let temp = allSubCategories.filter((item) => item.CategoryId === Number(category) &&
            item.SubCategory.toLowerCase() === subCategory.trim().toLowerCase());
        if (!subCategory.trim()) {
            toast.warn("Enter valid sub-category title");
            return false;
        }
        else if (!category) {
            toast.warn("Choose a valid category!");
            return false;
        }
        else if (description.trim().split(' ').length > 100) {
            toast.warn("Max word limit of description is 100!");
            return false;
        }
        else if (temp.length) {
            toast.error("Category already exists!");
            return false;
        }
        else {
            return true;
        }
    }

    const handlePublish = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (handleValidation()) {
            let data = {
                categoryId: Number(category), subCategory: subCategory.trim(), description,
                category: allCategories?.filter(ele => ele.UID === Number(category))?.[0]?.Category || ""
            }
            let res = await AdminServices.saveBlogSubCategory(data)
            toast.success("Sub-Category published.");
            await getAllSubCategories();
        }
        setLoading(false)
    }

    const getAllCategories = async () => {
        let res = await AdminServices.getBlogCategories().then(res => res);
        if (res.type === true) {
            setAllCategories(res.data)
        }
    }

    const getAllSubCategories = async () => {
        let res = await AdminServices.getBlogSubCategories().then(res => res);
        if (res.type === true) {
            setAllSubCategories(res.data);
        }
    }

    const loadData = async () => {
        await getAllCategories();
        await getAllSubCategories();
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <React.Fragment>
            <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                <CardHeader heading='Create sub-category' />
                <Divider style={{ margin: "1rem 0" }} />
                <div className='mb-4'>
                    <label>
                        Sub-Category Title:
                    </label>
                    <br />
                    <Input placeholder='Title' style={{ fontSize: "16px", padding: "5px 10px", width: "280px" }} className=''
                        value={subCategory} onChange={onChangesubCategory} />
                </div>
                <div className='mb-4'>
                    <label>
                        Choose Category:
                    </label>
                    <br />
                    <Select placeholder='Choose category' showSearch onChange={onChangeCategory}
                        style={{ width: "280px" }} optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                        } allowClear clearIcon={<AiFillCloseCircle color='white' />} >
                        {allCategories.map((item) => (
                            <Select.Option key={item.UID} value={item.UID}>
                                {item.Category}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className='mb-4'>
                    <label>
                        Description:
                    </label>
                    <br />
                    <TextArea placeholder='Description' value={description} onChange={onChangeDescription} style={{
                        width: "100%", height: "200px", resize: "none", fontSize: "15px", padding: "8px 10px", background: "var(--bgCard)"
                    }} />
                </div>
                <div className='mb-2'>
                    <button className='btnPrimary' onClick={handlePublish} disabled={loading}>
                        <MdPublish className='mr-2' />Publish
                    </button>
                </div>
            </Card>
        </React.Fragment>
    )
}

export default CreateSubCategory;
