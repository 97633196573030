import { Button, Card, Divider, Drawer, Image, Input, Modal, Select, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { AiFillCloseCircle, AiFillEdit, AiFillEye } from "react-icons/ai";
import { BiCodeAlt } from "react-icons/bi";
import { MdPublish } from "react-icons/md";
import { toast } from "react-toastify";
import AdminServices from "../../services/api/AdminServices";
import { Editor2 } from "./Editor";
import AceEditor from 'react-ace';
import formatter from 'html-formatter';
import { CardHeader } from "../../Components/Atoms/CardHeader";

const imageBaseUrl = 'https://d3etfstcuyoos5.cloudfront.net/app-images/blogimages/';

function AllBlogs() {
    const [allCategories, setAllCategories] = useState([]);
    const [allBlogs, setAllBlogs] = useState([]);
    const [blog, setBlog] = useState({});
    const [keywords, setKeywords] = useState([]);
    const [file, setFile] = useState();
    const [tag, setTag] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [previewHTML, setPreviewHTML] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedCategories,setSelectedCategories] = useState([]);

    const loadContent = async (blog) => {
        let res = await AdminServices.readBlogFile(blog?.URL?.replace('/blogs/', ''));
        console.log(res)
        if (res.type) {
            setContent(res.data);
        }
        else setContent('');
    }

    const onChangeData = (key, value) => {
        setBlog((prev => {
            return {
                ...prev,
                [key]: value
            }
        }))
    }

    console.log(blog);

    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];
        const data = new FormData();
        data.append('file', file);
        let res = await AdminServices.uploadImg(file.name, data);
        console.log(res);
        if (res.type === true) {
            onChangeData('ImageUrl', imageBaseUrl + file.name);
        }
    };

    const getAllCategories = async () => {
        try {
            let res = await AdminServices.getBlogCategories().then(res => res);
            if (res.type === true) {
                setAllCategories(res.data?.filter((el) => el?.IsKnowledgeBase !== 1))
            }
        }
        catch (err) {
            setAllCategories([]);
            console.log(err);
        }
    }

    const getAllBlogs = async () => {
        try {
            let res = await AdminServices.getBlogs().then(res => res);
            if (res.type === true) {
                setAllBlogs(res.data?.filter((el) => el?.IsKnowledgeBase !== 1));
            }
        }
        catch (err) {
            setAllBlogs([])
            console.log(err)
        }
    }

    const handleAddTag = (e) => {
        if (e.key === 'Enter' && e.target.value !== '') {
            setKeywords([...keywords, e.target.value])
            setTag("");
        }
    }

    const handleTagRemove = (e, i) => {
        let temp = [...keywords];
        temp.splice(i, 1);
        setKeywords(temp)
    }

    const handleValidations = () => {
        if (!blog?.Title.trim()) {
            toast.warn("Enter valid title");
            return false;
        }
        else if (!blog?.Content) {
            toast.warn("Content is empty!");
            return false
        }
        else {
            return true;
        }
    }

    const handlePublish = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (handleValidations()) {
            let data = { ...blog, Keywords: keywords.join(',') || "" }
            let res = await AdminServices.saveBlog(data);
            if (res.type === true) {
                toast.success("Resource published.")
                await getAllBlogs();
            }
            else {
                toast.error("Something went wrong. Try again!");
            }
        }
        setLoading(false);
    }

    const loadData = async () => {
        await getAllCategories();
        await getAllBlogs();
    }

    const onBlogTileClick = async (blog) => {
        setBlog({ ...blog });
        setKeywords(blog?.Keywords?.split(',')?.filter(ele => ele) || [])
        await loadContent(blog)
    }

    const handleCancel = () => {
        setBlog({});
        setKeywords([]);
        setContent('');
    }

    const handleDelete = async (id) => {
        let res = await AdminServices.deleteBlog(id);
        if (res.type) {
            await getAllBlogs();
            toast.info('Resource deleted!')
        }
        else {
            toast.warn('Something went wrong. Try again!')
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (openDrawer && blog?.UID) {
            document.getElementById('blog-preview-drawer').innerHTML = blog?.Content;
        }
    }, [openDrawer])

    return (
        <React.Fragment>
            <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                <div className='d-flex justify-content-between flex-column flex-md-row ' style={{ gap: "10px" }}>
                    <CardHeader heading={!blog?.UID ? "All blogs" : "Edit blog"} />
                    {blog?.UID && <div style={{ display: "flex", gap: "20px" }}>
                        <button className='btnPrimary' onClick={() => {
                            setOpenDrawer(true)
                        }}>
                            <AiFillEye className='mr-2' /> Preview
                        </button>
                        <button className="btnBlack" onClick={() => setPreviewHTML(true)}>
                            <BiCodeAlt className='mr-2' />See HTML
                        </button>
                        <button onClick={() => handleCancel()} className='btnRed'>
                            <AiFillCloseCircle className='mr-2' /> Cancel
                        </button>
                    </div>}
                </div>
                <Divider style={{ margin: "1rem 0" }} />
                {!blog?.UID ? <section>
                    <div className="">
                        <div>
                            Filter by category:
                        </div>
                        <Select value={selectedCategories} onChange={(e)=>setSelectedCategories(e)} style={{minWidth:"10rem"}} placeholder='Select category' mode="multiple">
                            {allCategories?.map((el)=>{
                                return <Select.Option key={el.UID} value={el.UID}>{el.Category}</Select.Option>
                            })}
                        </Select>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: "1.25rem" }} className='margin20Top'>
                            {allBlogs?.filter(el=>{
                                if(selectedCategories?.length===0) return true;
                                else {
                                    let ct = el?.CategoryId?.split('|')?.filter((e)=>e)?.map(Number);
                                    let check = false;
                                    selectedCategories.forEach((e)=>{
                                        if(ct.includes(e)){
                                            check = true;
                                        }
                                    })
                                    return check;
                                }
                            })?.map((item, i) => (
                                <Card style={{ padding: "0rem", background: "var(--grey3)", border: "1px solid var(--grey2)" }} hoverable={false} key={i}
                                    bodyStyle={{ height: "100%", padding: "1rem" }}>
                                    <div className="d-flex flex-column justify-content-between" style={{ gap: "0.5rem", height: "100%" }}>
                                        <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
                                            <div className='textMD w-500'>
                                                {item.Title}
                                            </div>
                                            <Divider style={{ margin: "0" }} />
                                            <div className='textSM'>
                                                {item.Description}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end" style={{ gap: "0.75rem" }}>
                                            <button className="btnRed" onClick={() => handleDelete(item.UID)}>
                                                Delete
                                            </button>
                                            <button className="btnPrimary d-flex align-items-center justify-content-center" style={{ gap: "0.25rem" }} onClick={() => onBlogTileClick(item)}>
                                                <AiFillEdit /> Edit
                                            </button>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section> :
                    <section>
                        <div className='margin20Top'>
                            <label>Title:</label>
                            <br />
                            <Input placeholder='Title' value={blog?.Title} name='title' onChange={(e) => { onChangeData('Title', e.target.value) }}
                                style={{ fontSize: "16px", padding: "5px 10px" }} />
                        </div>
                        <div className='mt-3'>
                            <label>Thumbnail:</label>
                            <br />
                            <input type="file" onChange={handleFileInputChange} accept="image/*" />
                            <Image src={blog?.ImageUrl} alt="Uploaded" style={{ width: "5rem", height: "5rem", objectFit: "contain" }} />
                        </div>
                        <div className="grid-container margin20Top" style={{ '--itemMinWidth': "280px" }}>
                            <div className=''>
                                <label>
                                    Author:
                                </label>
                                <br />
                                <Input className="" value={blog?.Author} readOnly={true} style={{ background: "var(--bgCard)", cursor: "not-allowed" }} />
                            </div>
                            <div className=''>
                                <label>
                                    Category:
                                </label>
                                <br />
                                <Select placeholder='Select a category'
                                    style={{ width: "280px" }} optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                    } mode='multiple' value={blog?.CategoryId?.split('|')?.map(Number)} onChange={(value)=>{
                                        onChangeData("CategoryId",value.join('|'));
                                        onChangeData("Category",allCategories.filter((e)=>value.includes(e.UID))?.map((el)=>el.Category)?.join(', '));
                                    }}  >
                                    {allCategories.map((item) => (
                                        <Select.Option key={item.UID} value={item.UID}>
                                            {item.Category}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className=''>
                                <label>
                                    Keywords:
                                </label>
                                <br />
                                <div className="d-flex align-items-center flex-wrap rounded" style={{ width: "280px", gap: "5px" }}>
                                    {keywords?.length ? <div className="d-flex flex-wrap rounded" style={{ gap: "3px" }}>
                                        {keywords?.map((item, i) => (
                                            <span key={item + i} style={{ display: 'inline-block' }}>
                                                <Tag closable={true} onClose={(e) => handleTagRemove(e, i)}
                                                    closeIcon={<AiFillCloseCircle color='var(--black)' />}>
                                                    {item}
                                                </Tag>
                                            </span>
                                        ))}
                                    </div> : null}
                                    <Input className='flex-grow-1' placeholder='Add keywords (Press Enter to add)'
                                        onChange={(e) => setTag(e.target.value)} onKeyDown={handleAddTag} value={tag} />
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <label>Description:</label>
                            <br />
                            <TextArea className='' style={{ width: "100%", height: "100px", resize: "none", fontSize: "16px" }}
                                placeholder='Enter description' value={blog?.Description}
                                onChange={(e) => { onChangeData('Description', e.target.value) }} />
                        </div>
                        <div className='margin20Top'>
                            <label>Content:</label>
                            <Editor2 onChange={(value) => { onChangeData('Content', value) }} content={content} />
                        </div>
                        <div className='margin20Top'>
                            <button className='btnPrimary' onClick={handlePublish} disabled={loading}>
                                <MdPublish className='mr-2' /> Publish
                            </button>
                        </div>
                        <Modal onOk={() => setPreviewHTML(false)} open={previewHTML} title='HTML Content' onCancel={() => setPreviewHTML(false)}
                            zIndex={1021} bodyStyle={{ background: "var(--bgCard)" }}>
                            <AceEditor
                                placeholder="Write something"
                                mode="html"
                                fontSize={14}
                                onLoad={(editor) => {
                                    editor.setReadOnly(true)
                                }}
                                width='100%'
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={formatter.render(blog?.Content || '')}
                                setOptions={{
                                    enableBasicAutocompletion: false,
                                    enableLiveAutocompletion: false,
                                    enableSnippets: false,
                                    showLineNumbers: true,
                                    wrap: true,
                                }} />
                        </Modal>
                        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} placement='right' title='Preview Blog'
                            bodyStyle={{ color: "black", margin: "0px", padding: "0px", background: "white" }}
                            closable={true}
                            closeIcon={<AiFillCloseCircle size='24px' />} width='100%' zIndex={1021}
                        >
                            <div className='ql-editor'>
                                <h2>
                                    {blog.Title}
                                </h2>
                                <div id='blog-preview-drawer'>
                                </div>
                            </div>
                        </Drawer>
                    </section>
                }
            </Card>
        </React.Fragment>
    )
}

export default AllBlogs