import { sharpelyLogoLight } from "Assets/images";
import { Drawer, Image } from "antd";
import { useHistory } from "react-router-dom";
import SidebarMain from "./SidebarMain";
import './sidebar.css';

export const DrawerSidebar = ({ drawer, setDrawer }) => {

    const history = useHistory();

    return (
        <Drawer
            placement={'right'}
            closable={false}
            onClose={() => setDrawer(false)}
            open={drawer}
            width='275px'
            styles={{ body: { margin: "0px", padding: "0px" } }}
            style={{ background: "var(--dark4)" }}
            className='drawer-sidebar'
        >
            <div className='d-flex align-items-center sticky-top' style={{ background: 'var(--dark4)', padding: "10px" }}
                onClick={() => history.push('/dashboard')}>
                <Image height='25px' src={sharpelyLogoLight}
                    preview={false} />
            </div>
            <SidebarMain onClose={() => setDrawer(false)} />
        </Drawer>
    )
}