import React from 'react';
import { amcList } from './Constants';

export default function AMCIcon({ amcName, type = "normal", height, width, style }) {

    return (
        <div style={{ alignSelf: "flex-start", ...style }}>
            <img
                src={(amcList.filter((s) => s.Name === amcName)).length !== 0 ? (amcList.filter((s) => s.Name === amcName))[0].imgSource : amcList[0].imgSource}
                style={{
                    height: height,
                    width: width
                }}
            />
        </div>
    );
}