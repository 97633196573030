function changeObjKey(dataf, keys, newKeys) {
  var data = JSON.parse(JSON.stringify(dataf))
  for (const [key, value] of Object.entries(data)) {
    keys.map((a, i) => {
      if (value[keys[i]] !== undefined) {
        value[newKeys[i]] = value[keys[i]];
      }
      // Assign new key
    })
  }
  return data;
}

function removeUnwantedKeyVal(dataf, keys) {
  var data = JSON.parse(JSON.stringify(dataf))
  data.forEach(function (v) {
    var keysF = Object.keys(v)
    keysF.map((key) => {
      if (!keys.includes(key)) {
        delete v[key]
      }
    })
  });
  return data;
}

function removeSelectedKeyVal(dataf, keys) {
  var data = JSON.parse(JSON.stringify(dataf))
  data.forEach(function (v) {
    var keysF = Object.keys(v)
    keysF.map((key) => {
      if (keys.includes(key)) {
        delete v[key]
      }
    })
  });
  return data;
}

export { changeObjKey, removeUnwantedKeyVal,removeSelectedKeyVal };

