import React, { createContext, useContext, useEffect, useState } from "react";
import { cyrb53 } from "../libs/cyrb53Hasher";
import db from "../libs/db";

const AuthContext = createContext(null);
const isAuthenticated = localStorage.getItem("isAdmin");
const ifadata = localStorage.getItem('ifa') || {"userData":""};
export const AuthContextProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(Number(isAuthenticated) === cyrb53("yesitisamintboxadmin") ? true : false);
    const [ifaData, setIfaData] = useState(JSON.parse(ifadata?.userData||"{}"));

    useEffect(() => {
        if (isAuth) {
            db.get(db.model.ifa).then((e) => {
                setIfaData(e.userData);
            })
        }
    }, [isAuth])
    useEffect(() => {
        if (isAuth) {
            document.body.classList.add('user-authorized');
        }
        else {
            document.body.classList.remove('user-authorized');
        }
    }, [isAuth])
    return (
        <AuthContext.Provider value={{ isAuth, setIsAuth, ifaData }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);