import { sharpelyLogoDark } from "Assets/images";
import React, { useEffect, useState } from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { cyrb53 } from "../../libs/cyrb53Hasher";
import db from "../../libs/db";
import ProfilePopover from "./ProfilePopover";
// import { sharpelyLogoDark } from "Assets/images";

export const Navbar = ({ collapsed, setCollapsed, drawer, setDrawer }) => {
    const [ifaData, setIfaData] = useState({})

    useEffect(() => {
        const isAuthenticated = localStorage.getItem("isAdmin");
        if (Number(isAuthenticated) === cyrb53("yesitisamintboxadmin")) {
            db.get(db.model.ifa).then((e) => {
                setIfaData(e.userData);
            })
        }
    }, [])
    return (
        <div style={{
            background: 'var(--white)', borderBottom: "1px solid var(--grey2)", display: "flex", flexDirection: "row", justifyContent: "space-between",
            padding: "1rem 1.25rem", position: "sticky", top: 0, zIndex: 1000
        }}>
            <div className="d-flex d-lg-none align-items-center justify-content-center ">
                <Link to="/"><img height={"35px"} src={sharpelyLogoDark} alt="logo" /></Link>
            </div>
            {React.createElement(collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold, {
                className: 'trigger d-none d-lg-inline-block', style: { fontSize: "20px", cursor: "pointer", color: "var(--black)" },
                onClick: () => { setCollapsed(!collapsed); document.body.classList.toggle('layout-collapsed') },
            })}
            <div className='d-flex align-items-center' style={{ gap: "15px", color: "var(--black)" }}>
                <ProfilePopover ifaData={ifaData} />
                <GiHamburgerMenu onClick={() => setDrawer(true)} cursor='pointer' className="d-lg-none" size='23px' />
            </div>
        </div>
    )
}