import { Image } from "antd";
import { sharpelyLogoLight } from "Assets/images";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import { cyrb53 } from "../../libs/cyrb53Hasher";
import db from "../../libs/db";
import AdminServices from "../../services/api/AdminServices";
import styles from './auth.module.css';

const Login = (props) => {
    const [email, setEmail] = React.useState('');
    const [otp, setOTP] = React.useState('');
    const [mainOTP, setmainOTP] = React.useState('');
    const [nextBtnPressed, setNextBtnPressed] = React.useState(false);
    const { setIsAuth, isAuth } = useAuth()
    const checkOTP = (e) => {
        e.preventDefault();
        if (Number(otp) === Number(mainOTP)) {
            try {
                localStorage.setItem("isAdmin", cyrb53("yesitisamintboxadmin"));
                setIsAuth(true);
                toast.success("Welcome back.");
                props.history.push("/dashboard")
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.error("Incorrect OTP");
        }
    }

    const sendOTP = async (id) => {
        console.log("ASDFASF", id)
        try {
            setmainOTP('')
            const res = await AdminServices.IFAlogin(id).then(res => res)
            console.log(res)
            if (res.type) {
                setNextBtnPressed(true);
                console.log("hey")
                toast.info("OTP send to your email!")
                setmainOTP(res.data.otpcode);
            } else {
                toast.error("Try again ...");
            }
        } catch (error) {
            console.log(error)
            toast.error("Try again ...");
        }
    };


    const onNextBtnPressed = async (e) => {
        e.preventDefault();
        try {
            const res = await AdminServices.ifaExists(email, "EMAIL").then((res) => res);
            if (res.type) {
                console.log("client exists API ", res.data)
                if (res.data.IFAId !== '') {
                    const userData = {
                        name: res.data.IFAName,
                        email: res.data.Email,
                        mobile: res.data.MobileNumber,
                        id: res.data.IFAId,
                        isLogged: true,
                    };
                    db.set(db.model.ifa, JSON.stringify({ userData }));
                    sendOTP(res.data.IFAId)
                } else {
                    setNextBtnPressed(false);
                    toast.error("Not a mintbox registered IFA");
                }
            } else {
                toast.warn("Please try again ...");
                setNextBtnPressed(false);
            }
        } catch (error) {
            toast.warn("Please try again ...");
            console.log("DSFDSFsda", error)
            setNextBtnPressed(false);
        }
    };
    if (isAuth) {
        return <Redirect to='/dashboard' />
    }
    return (
        <div className={styles.auth} >
            <div className={`${styles.container} rounded`}>
                <div>
                    <Image src={sharpelyLogoLight} alt="logo" height='40px' preview={false} />
                </div>
                <h4 className='mt-3 font-weight-normal'>
                    Hello! Let's get started
                </h4>
                <h6 className='font-weight-light mb-3'>Sign in to continue.</h6>
                <form
                    name="normal_login"
                    className="login-form w-100"
                    onSubmit={!nextBtnPressed ? (e) => onNextBtnPressed(e) : (e) => checkOTP(e)}
                >
                    {!nextBtnPressed ? <input value={email} onChange={(e) => setEmail(e.target.value)}
                        className='w-100 p-2' placeholder='Email' type='email' /> :
                        <input value={otp} onChange={(e) => setOTP(e.target.value)}
                            className='w-100 p-2' placeholder='OTP' type='pin' />
                    }
                    <br />
                    <button type='submit' className='w-100 mt-3 btn-primary rounded p-2'>
                        {nextBtnPressed ? "Login" : "Send OTP"}
                    </button>
                </form>
                <div className="text-center mt-4 font-weight-light" style={{ fontSize: "14px" }}>
                    Want to become sharpely IFA? <Link to="/register" className="text-primary" style={{ fontSize: "14px" }}>Register</Link>
                </div>
            </div>
        </div>
    )
}

export default Login