import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official";

const ClientGrowthChart = (chartData) => {
    // let clientGrowthChartData = clientGrowth?.map(d => [getMonth(d["Month"]), d["UserCount"]])
    // console.log("aksjhdfalkjhsd", clientGrowthChartData)
    const options = {
        chart: {
            backgroundColor: 'transparent',
            height: 270,
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: chartData?.map(pk => {
                let j = new Date();
                j.setFullYear(pk?.Year);
                j.setMonth(pk?.Month);
                return j.toLocaleString('en-IN', {
                    month: 'short',
                    year: 'numeric',
                    timeZone: 'Asia/Kolkata'
                });
            }),
            crosshair: true,
            labels: {
                style: {
                    color: "var(--black)"
                }
            }
        },
        yAxis: {
            min: 0,
            tickAmount: 5,
            gridLineDashStyle: 'longdash',
            title: {
                text: ''
            },
            labels: {
                style: {
                    color: "var(--black)"
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
            backgroundColor: "whitesmoke",
        },
        legend: {
            enabled: false
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
            }
        },

        credits: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        series: [{
            name: 'Users',
            data: chartData?.map((ele) => ele?.UserCount),
            color: "#fa5a5a"
        }]
    }

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>)
}

export default ClientGrowthChart