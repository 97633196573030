import moengage from "@moengage/web-sdk";
import { Dropdown, Modal, Table, Tag } from "antd";
import ActivityLoaderMintbox from "Assets/styles/components/ActivityLoaderMintbox";
import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { toast } from "react-toastify";
import { getClientAllSubs } from "../../libs/utils";
import AdminServices from "../../services/api/AdminServices";
import { CurrencyFormat } from "../Components/CurrencyFormat";
import CustomDrawer from "../Components/CustomDrawer";
import { formatDate } from "../shared/components/DateFormatter";

export const ClientSubscriptions = React.memo(({ clientId, drawerOpen, toggle = () => { }, client = {} }) => {

    const [subscriptions, setSubscriptions] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showIndSubTrans, setShowIndSubTrans] = React.useState(false);
    const [indSubTrans, setIndSubTrans] = React.useState(null);
    const [confirmActionModal, setConfirmActionModal] = React.useState(false);
    const [confirmActionData, setConfirmActionData] = React.useState({});

    const loadData = async () => {
        let res = await getClientAllSubs({ id: client?.ClientId, razorpayId: client?.RazorpayId })
        setSubscriptions(res);
        setLoading(false)
    };

    const getIndSubTrans = async (id) => {
        setIndSubTrans(null)
        var tr1 = await AdminServices.getPPTrans(clientId, id).then((res) => { return res })
        setIndSubTrans(tr1?.data?.filter((el) => el?.SubscriptionId === id))
        setShowIndSubTrans(true);
    }

    const triggerSubEvent = async (sub) => {
        let userTracked = await moengage.add_unique_user_id("SHARPELY-" + client?.ClientId);
        if (userTracked) {
            moengage.add_email(client.Email)
            moengage.add_mobile(client.MobileNumber)
            moengage.add_user_name(client.DisplayName)
            moengage.add_user_attribute("SharpelyAccessLevel", client.SharpelyAccessLevel);
            if (sub?.SubscriptionState === 'REVOKED') {
                moengage.track_event('SUBSCRIPTION_REVOKED', {
                    "subscriptionId": sub?.SubscriptionId,
                    "userId": client?.ClientId,
                    "planName": sub?.SubscriptionName,
                    "frequency": sub?.Frequency,
                    "isPlanChanged": false
                })
            }
            else if (sub?.SubscriptionState === 'CANCELLED') {
                moengage.track_event('CANCEL_SUBSCRIPTION', {
                    "subscriptionId": sub?.SubscriptionId,
                    "userId": client?.ClientId,
                    "planName": sub?.SubscriptionName,
                    "frequency": sub?.Frequency,
                    "isPlanChanged": false
                })
            }
            if (client?.IsSharpelyFreeTrial === 1) {
                let ftEndDate = new Date(client?.SharpelyFTEndDate);
                ftEndDate.setHours(0);
                ftEndDate.setMinutes(0);
                ftEndDate.setSeconds(0);
                let nextDueDate = new Date(client?.SharpelyPayDueDate);
                nextDueDate.setDate(nextDueDate.getDate() + 1);
                nextDueDate.setHours(0);
                nextDueDate.setMinutes(0);
                nextDueDate.setSeconds(0);
                if (ftEndDate >= nextDueDate) {
                    moengage.track_event('7_DAY_TRIAL_ENDED', {
                        "userId": client?.ClientId,
                        "subStatus": sub?.SubscriptionState,
                    })
                }
            }
            moengage.destroy_session();
            moengage.track_event('Logout');
            toast.info('Event triggered.')
        }
    }

    const debitSuccessEvent = async (obj) => {
        let t = await moengage.add_unique_user_id("SHARPELY-" + clientId)
        if (t) {
            moengage.add_email(client?.Email)
            moengage.add_mobile(client?.MobileNumber)
            moengage.add_user_name(client?.DisplayName)
            moengage.add_user_attribute("SharpelyAccessLevel", client?.SharpelyAccessLevel)
        }
        var tr1 = await AdminServices.getPPTrans(obj?.ClientId, obj?.SubscriptionId).then((res) => { return res })
        tr1 = tr1?.data?.filter((el) => el?.SubscriptionId === obj?.SubscriptionId) || [];
        tr1 = tr1?.filter((el) => el?.State === 'ACCEPTED')
        moengage.track_event('SUBSCRIPTION_DEBIT_SUCCESSFUL', {
            "subscriptionId": obj?.SubscriptionId,
            "amount": (obj?.Amount) / 100,
            "plan": obj?.SubscriptionName,
            "frequency": obj?.Frequency,
            "clientId": obj?.ClientId,
            "transactionNo": tr1?.length
        })
        moengage.destroy_session();
        moengage.track_event('Logout');
        toast.info('Event triggered.')
    }

    const initiateDebit = async (obj) => {
        let t = await moengage.add_unique_user_id("SHARPELY-" + obj?.ClientId)
        if (t) {
            moengage.add_email(client?.Email)
            moengage.add_mobile(client?.MobileNumber)
            moengage.add_user_name(client?.DisplayName)
            moengage.add_user_attribute("SharpelyAccessLevel", client?.SharpelyAccessLevel)
        }
        let d = {
            "clientId": obj?.ClientId,
            "subscriptionId": obj?.SubscriptionId,
            "amount": obj?.Amount
        }
        console.log(d)
        var tr1 = await AdminServices.getPPTrans(obj?.ClientId, obj?.SubscriptionId).then((res) => { return res })
        tr1 = tr1?.data?.filter((el) => el?.SubscriptionId === obj?.SubscriptionId) || [];
        tr1 = tr1?.filter((el) => el?.State === 'ACCEPTED')
        console.log(tr1)
        moengage.track_event('SUBSCRIPTION_DEBIT_INITIATED', {
            "subscriptionId": obj?.SubscriptionId,
            "amount": (obj?.Amount) / 100,
            "plan": obj?.SubscriptionName,
            "frequency": obj?.Frequency,
            "clientId": obj?.ClientId,
            "transactionNo": tr1?.length + 1
        })
        let res = await AdminServices.initiatePPDebit(d);
        if (res?.type === true) {
            toast.success('Debit initiated');
        }
        else {
            toast.error('Something went wrong.')
        }
        moengage.destroy_session();
        moengage.track_event('Logout');
    }

    const executeDebit = async (obj) => {
        // let t = await moengage.add_unique_user_id("SHARPELY-" + obj?.ClientId)
        // if (t) {
        //     moengage.add_email(client?.Email)
        //     moengage.add_mobile(client?.MobileNumber)
        //     moengage.add_user_name(client?.DisplayName)
        //     moengage.add_user_attribute("SharpelyAccessLevel", client?.SharpelyAccessLevel)
        // }
        let d = {
            "clientId": obj?.ClientId,
            "subscriptionId": obj?.SubscriptionId,
            // "amount": obj?.Amount
        }
        console.log(d)
        // var tr1 = await AdminServices.getPPTrans(obj?.ClientId, obj?.SubscriptionId).then((res) => { return res })
        // tr1 = tr1?.data?.filter((el) => el?.SubscriptionId === obj?.SubscriptionId) || [];
        // tr1 = tr1?.filter((el) => el?.State === 'ACCEPTED')
        // console.log(tr1)
        // moengage.track_event('SUBSCRIPTION_DEBIT_INITIATED', {
        //     "subscriptionId": obj?.SubscriptionId,
        //     "amount": (obj?.Amount) / 100,
        //     "plan": obj?.SubscriptionName,
        //     "frequency": obj?.Frequency,
        //     "clientId": obj?.ClientId,
        //     "transactionNo": tr1?.length + 1
        // })
        let res = await AdminServices.recurringExec(d);
        if (res?.type === true) {
            toast.success('Debit Executed');
        }
        else {
            toast.error('Something went wrong.')
        }
        // moengage.destroy_session();
        // moengage.track_event('Logout');
    }


    const firstDebitSuccessEvent = async (obj) => {
        let t = await moengage.add_unique_user_id("SHARPELY-" + clientId)
        if (t) {
            moengage.add_email(client?.Email)
            moengage.add_mobile(client?.MobileNumber)
            moengage.add_user_name(client?.DisplayName)
            moengage.add_user_attribute("SharpelyAccessLevel", client?.SharpelyAccessLevel)
        }
        moengage.track_event('SUBSCRIPTION_DEBIT_SUCCESSFUL', {
            "subscriptionId": obj?.SubscriptionId,
            "amount": (obj?.Amount) / 100,
            "plan": obj?.SubscriptionName,
            "frequency": obj?.Frequency,
            "clientId": clientId,
            "transactionNo": 1
        })
        moengage.destroy_session();
        moengage.track_event('Logout');
        toast.info('Event triggered.')
    }

    const transactionCols = [
        {
            title: "Date",
            dataIndex: "CreatedOn",
            align: 'left',
            // render: (text) => {
            //     return formatDate(text)
            // }
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            align: "right",
            render: (text) => {
                return CurrencyFormat(Number(text) / 100)
            }
        },
        {
            title: "Status",
            dataIndex: "State",
            align: "right",
            render: (text) => {
                return <Tag color={(['ACCEPTED', 'COMPLETED', 'SUCCESS', 'SUCCESSFUL'])?.includes(text) ? "var(--green)" : (['FAILED', 'CANCELLED'])?.includes(text) ? "var(--red)" :
                    "var(--blue)"}>{text}</Tag>
            }
        }
    ]

    const getSubscriptionActions = (sub) => {
        let temp = [
            {
                key: "InitiateDebit", label: "Initiate Debit", show: sub?.PGType === 'PhonePe',
                onClick: () => {
                    setConfirmActionModal(true);
                    setConfirmActionData({ value: "InitiateDebit", data: sub });
                }
            },
            {
                key: "ExecuteDebit", label: "Execute Debit", show: sub?.PGType === 'PhonePe',
                onClick: () => {
                    setConfirmActionModal(true);
                    setConfirmActionData({ value: "ExecuteDebit", data: sub });
                }
            },
            {
                key: "DebitSuccessEvent", label: "Trigger Debit Success Event", show: sub?.PGType === 'PhonePe',
                onClick: () => {
                    setConfirmActionModal(true);
                    setConfirmActionData({ value: "DebitSuccessEvent", data: sub });
                }
            },
            {
                key: "TriggerSubStatusEvent", label: "Trigger Sub Status Event",
                onClick: () => {
                    console.log(sub)
                    setConfirmActionModal(true);
                    setConfirmActionData({ value: "TriggerSubStatusEvent", data: sub });
                }
            },
            {
                key: "TriggerFirstDebitSuccess", label: "First Debit Success Event",
                onClick: () => {
                    firstDebitSuccessEvent(sub)
                }
            },
            {
                key: "Transactions", label: "See Transactions", show: sub?.PGType === 'PhonePe',
                onClick: () => {
                    getIndSubTrans(sub?.SubscriptionId)
                }
            }
        ]
        temp = temp?.filter((el) => el?.show !== false)?.map((el) => {
            let obj = {
                ...el
            }
            delete obj.show;
            return obj;
        }) || [];
        return temp;
    }

    const subscriptionCols = [
        {
            title: "Plan",
            dataIndex: "SubscriptionName",
            key: "SubscriptionName",
            align: "left"
        },
        {
            title: "ID",
            dataIndex: "SubscriptionId",
            key: "SubscriptionId",
            align: "right"
        },
        {
            title: "Status",
            dataIndex: "SubscriptionState",
            key: "SubscriptionState",
            align: "right",
            render: (text) => {
                return <Tag color={text === 'ACTIVE' ? "var(--green)" : (text === 'FAILED' || text === 'CANCELLED') ? "var(--red)" : "var(--blue)"} > {text}</Tag >
            },
            filters: [
                { text: "Active", value: "ACTIVE" },
                { text: "Authenticated", value: "AUTHENTICATED" },
                { text: "Expired", value: "EXPIRED" },
                { text: "Cancelled", value: "CANCELLED" },
                { text: "Paused", value: "PAUSED" },
                { text: "Revoked", value: "REVOKED" },
                { text: "Failed", value: "FAILED" },
                { text: "Created", value: "CREATED" }
            ],
            onFilter: (value, record) => record?.SubscriptionState === value
        },
        {
            title: "Frequency",
            dataIndex: "Frequency",
            key: "Frequency",
            filters: [
                { text: "Monthly", value: "monthly" },
                { text: "Yearly", value: "yearly" },
            ],
            onFilter: (value, record) => record?.Frequency === value
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            key: "Amount",
            render: (amt) => CurrencyFormat(amt / 100)
        },
        {
            title: "Date",
            dataIndex: "CreatedOn",
            key: 'CreatedOn',
            render: (text) => {
                return formatDate(text)
            }
        },
        {
            title: "PG",
            dataIndex: "PGType",
            key: 'PGType',
            filters: [
                { text: "RazorPay", value: "RazorPay" },
                { text: "PhonePe", value: "PhonePe" },
            ],
            onFilter: (value, record) => record?.PGType === value
        },
        {
            title: "Actions",
            dataIndex: "SubscriptionId",
            key: "SubscriptionId",
            render: (_, obj) => {
                return <Dropdown menu={{ items: getSubscriptionActions(obj) }} placement="bottomRight">
                    <span className="d-flex align-items-center textSM" style={{ gap: 2, cursor: "pointer" }}>
                        Actions
                        <FiChevronDown />
                    </span>
                </Dropdown>
            }
        }
    ]

    const confirmActionsData = [
        {
            key: 0, value: "InitiateDebit", title: "Do you really want to initiate debit?", note: "Note: This action is irreversible.", onConfirm: () => {
                initiateDebit(confirmActionData?.data)
            }
        },
        {
            key: 0, value: "ExecuteDebit", title: "Do you really want to execute debit?", note: "Note: This action is irreversible.", onConfirm: () => {
                executeDebit(confirmActionData?.data)
            }
        },
        {
            key: 1, value: "DebitSuccessEvent", title: "Are you sure you want to trigger debit successful event?", note: "Note: This action is irreversible.", onConfirm: () => {
                debitSuccessEvent(confirmActionData?.data)
            }
        },
        {
            key: 2, value: "TriggerSubStatusEvent", title: "Do you really want to trigger event?", note: "Note: This action is irreversible.", onConfirm: () => {
                triggerSubEvent(confirmActionData?.data)
            }
        }
    ]

    let selectedConfirmActionData = React.useMemo(() => {
        return confirmActionsData?.find((el) => el?.value === confirmActionData?.value) || {};
    }, [confirmActionData]);

    React.useEffect(() => {
        if (!drawerOpen) {
            setSubscriptions([]);
            setLoading(true);
        } else {
            loadData();
        }
    }, [drawerOpen, clientId])

    const ListEmptyComponent = () => {

        return (
            <div className="textSM dark3 w-500 my-4">
                No payment history found!
            </div>
        )
    }

    const RenderTrans = ({ data = [] }) => {

        return (
            <>{data?.length <= 0 ? <ListEmptyComponent /> :
                <Table dataSource={data} columns={transactionCols} pagination={false} />}
            </>
        )
    }

    return (
        <React.Fragment>
            <CustomDrawer isOpen={drawerOpen} toggle={toggle} title='Subscriptions'>
                {loading ? <ActivityLoaderMintbox /> : <>
                    {subscriptions?.length <= 0 ? <ListEmptyComponent /> :
                        <Table dataSource={subscriptions} columns={subscriptionCols} pagination={false} scroll={{ x: "max-content" }} />}
                    <CustomDrawer isOpen={showIndSubTrans} title='Transactions' toggle={() => {
                        setShowIndSubTrans(false);
                    }}>
                        {indSubTrans === null ? <ActivityLoaderMintbox /> : <RenderTrans data={indSubTrans} />}
                    </CustomDrawer>
                </>}
                <Modal open={confirmActionModal} onCancel={() => {
                    setConfirmActionModal(false);
                    setConfirmActionData({});
                }} footer={null} centered>
                    <div>
                        <div className="textMD w-500 dark4">
                            {selectedConfirmActionData?.title}
                        </div>
                    </div>
                    <div className="mt-2">
                        <span className="textXS dark3">{selectedConfirmActionData?.note}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-end" style={{ gap: '1.25rem', marginTop: '1.25rem' }}>
                        <button className="btnWhite" onClick={() => {
                            setConfirmActionModal(false);
                            setConfirmActionData({});
                        }}>Cancel</button>
                        <button className="btnBlack" onClick={() => {
                            if (selectedConfirmActionData?.onConfirm) {
                                selectedConfirmActionData.onConfirm()
                            }
                            setConfirmActionModal(false);
                            setConfirmActionData({});
                        }}>Confirm</button>
                    </div>
                </Modal>
            </CustomDrawer>
        </React.Fragment>
    )
})