import { Image } from "antd";
import { sharpelyLogoLight, sharpelyOnlyLogo } from "Assets/images";
import { useHistory } from "react-router-dom";
import './sidebar.css';
import SidebarMain from "./SidebarMain";

export const Sidebar = ({ collapsed }) => {
    const history = useHistory();
    return (
        <div style={{ transition: "all 0.2s ease-in-out", width: collapsed ? "5rem" : "17rem", background: "var(--dark4)", height: "100%", overflow: "auto" }} className="d-none d-lg-block">
            <div className='d-flex align-items-center sticky-top' style={{ background: "var(--dark4)", color: "var(--white)", padding: "1rem 1.25rem" }}
                onClick={() => history.push('/dashboard')}>
                {!collapsed ? <Image width={'auto'} height={'40px'} src={sharpelyLogoLight} preview={false} /> :
                    <Image height={'40px'} src={sharpelyOnlyLogo} preview={false} />}
            </div>
            <SidebarMain collapsed={collapsed} />
        </div>
    )
}