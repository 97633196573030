export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const baseImgURL = "https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/"

const amcList = [
  //   { Id: 0, Name: "by AMC", imgSource: require('../Assets/mintbox_icons/mintbox_logo_png.png') },
  { Id: 1, Name: "Aditya Birla Sun Life Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/ABC.jpg' },
  { Id: 2, Name: "HDFC Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/HDFC.png' },
  { Id: 3, Name: "ICICI Prudential Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/ICICI.png' },
  { Id: 5, Name: "SBI Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/SBI.png' },
  { Id: 6, Name: "Tata Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Tata.png' },
  { Id: 7, Name: "Invesco Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Invesco.png' },
  { Id: 8, Name: "Quantum Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Quantum.png' },
  { Id: 9, Name: "UTI Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/UTI.png' },
  { Id: 10, Name: "IDFC Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/IDFC.png' },
  { Id: 12, Name: "DSP Blackrock", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/DSP.png' },
  { Id: 13, Name: "Kotak Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Kotak.png' },
  { Id: 15, Name: "Mirae Asset Management", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Mirae.png' },
  { Id: 16, Name: "Franklin Templeton", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Franklin.png' },
  { Id: 18, Name: "Sundaram Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Sundaram.png' },
  { Id: 20, Name: "Motilal Oswal Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Motilal.png' },
  { Id: 22, Name: "HSBC Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/HSBC.png' },
  { Id: 23, Name: "Edelweiss Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Edelweiss.png' },
  { Id: 24, Name: "L&T Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/L&T.png' },
  { Id: 25, Name: "Axis Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/AXIS.png' },
  { Id: 26, Name: "BNP Paribas Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/BNP.png' },
  { Id: 27, Name: "IIFL Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/IIFL.png' },
  { Id: 28, Name: "Taurus Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Taurus.png' },
  { Id: 31, Name: "Baroda Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/BARODA.png' },
  { Id: 34, Name: "IDBI Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/IDBI.png' },
  { Id: 36, Name: "PPFAS Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/PPFAS.png' },
  { Id: 37, Name: "JM Financials Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/JM.png' },
  { Id: 38, Name: "LIC Nomura Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/LIC.png' },
  { Id: 39, Name: "Canara Robeco Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/CANARA.png' },
  { Id: 40, Name: "Principal Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Principal.png' },
  { Id: 41, Name: "Indiabull Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Indiabulls.png' },
  { Id: 42, Name: "Mahindra Manulife Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Mahindra.png' },
  { Id: 45, Name: "Bharti AXA Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/BOI.png' },
  { Id: 56, Name: "Nippon India Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Nippon.png' },
  { Id: 57, Name: "PGIM India Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/PGIM.png' },
  { Id: 58, Name: "Navi Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/NAVI.png' },
  { Id: 59, Name: "Shriram Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Shriram.png' },
  { Id: 60, Name: "Union KBC Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Union.png' },
  { Id: 61, Name: "Yes Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Yes.png' },
  { Id: 62, Name: "ITI Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/ITI.png' },
  { Id: 63, Name: "Quant Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Quant.png' },
  { Id: 64, Name: "Trust Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Trust.png' },
  { Id: 65, Name: "LIC Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/LIC.png' },
  { Id: 66, Name: "ICICI Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/ICICI.png' },
  { Id: 67, Name: "Mirae Asset Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Mirae.png' },
  { Id: 68, Name: "Kotak Mahindra Mutual Fund", imgSource: 'https://d3etfstcuyoos5.cloudfront.net/app-images/amc_icons/Kotak.png' },

]


export { amcList, baseImgURL };