import React, { Component } from 'react';
class Footer extends Component {
  render() {
    return (
      <footer className="footer" style={{
        // border: "0.5px solid #e5e5e5", 
        padding: 10, background: "var(--dark4)"
      }}>
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-light text-center text-sm-left d-block d-sm-inline-block" style={{ fontSize: "10px" }}>Copyright © <a href="https://mintbox.ai/" target="_blank" rel="noopener noreferrer" style={{ color: "var(--primary)" }}>Mintbox Solutions Pvt. Ltd. </a>{new Date().getFullYear()}</span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;