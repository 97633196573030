import React from 'react'

export default function GoalIcon({type,height,width}) {

    var goals = [
        {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/svg_icons/retire_goal.svg",
            goalType: 1,
            color: 'var(--secondaryGreen)'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/web-icons/home_goal.svg",
            goalType: 5,
            color: 'var(--secondaryGreen)'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/svg_icons/education_goal.svg",
            goalType: 3,
            color: 'var(--red)'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/svg_icons/marriage_goal.svg",
            goalType: 4,
            color: 'var(--red)'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/svg_icons/buyBike_goal.svg",
            goalType: 2,
            color: '#9F87E8'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/svg_icons/buyCar_goal.svg",
            goalType: 6,
            color: '#9F87E8'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/web-icons/vacation_goal.svg",
            goalType: 7,
            color: 'var(--gold)'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/web-icons/custom_goal.svg",
            goalType: 8,
            color: 'var(--gold)'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/web-icons/custom_goal.svg",
            goalType: undefined,
            color: 'var(--gold)'
        }, {
            icon: "https://d3etfstcuyoos5.cloudfront.net/app-images/web-icons/custom_goal.svg",
            goalType: 0,
            color: 'var(--gold)'
        }
    ]

    const goal = goals.find(g=>g.goalType === type)
  return(
    <div style={{ display:'flex', border: "1px solid " + goal?.color,padding:`calc(${height}*10/100) 0`, borderRadius: '4px', height, width, backgroundColor: goal?.color+'22'}}>
        <img src={goal?.icon} style={{height: `calc(${height}*80/100)`,margin:'auto'}}/>
    </div>
  )
}