import { DataServicePY } from "./DataServicePY";

const MINTBOX_CLIENT_ID = "Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm";

export default {

	get_goal_scenarios: async (data) => {
		return DataServicePY.post('/getGoalScenariosv1', data);
	},

	getGoalCorpus: async (data) => {
		return DataServicePY.post('/getGoalTargetCorpusv1', data);
	},

	getGoalSIP: async (data) => {
		return DataServicePY.post('/getGoalSIPv1', data);
	},

	getGoalDetails: async (data) => {
		return DataServicePY.post('/getGoalDetailsv1', data);
	},

	getGoalReturn: async (data) => {
		return DataServicePY.post('/getGoalReturnsv1', data);
	},

	getETFTrailingReturns: async (id) => {
		return DataServicePY.get(
			`/core/getETFTrailingReturns/${id}`
		);
	},

	getETFTrackingError: async (id) => {
		return DataServicePY.get(
			`/core/getETFTrackingError/${id}`
		);
	},

	updateBenchmarksNAV: async () => {
		return DataServicePY.post(
			`/updateBenchmarksNAV`
		);
	},

	updatePledgedData: async (data) => {
		return DataServicePY.post(
			`/core/updatePledgedData`, data
		);
	},

	updateFile: async (file, fileName) => {
		return DataServicePY.postFile(
			`/core/upload-file/fileName=` + fileName, file
		);
	},

	updateGSMData: async (data) => {
		return DataServicePY.post(
			`/core/updateGSMData`, data
		);
	},

	updateESMData: async (data) => {
		return DataServicePY.post(
			`/core/updateESMData`, data
		);
	},

	updateASMData: async (data) => {
		return DataServicePY.post(
			`/core/updateASMData`, data
		);
	},

	fetchSharpelyBenchmarkData: async () => {
		return DataServicePY.get(
			`/core/fetchSharpelyBenchmarkData`
		);
	},
	getEtfs: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV1/instrumentType=1"
		);
	},

	getMFs: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV1/instrumentType=0"
		);
	},
	getStocks: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV1/instrumentType=2"
		);
	},

	runEOD: async () => {
		return DataServicePY.get(
			"/core/eod/runEOD"
		)
	},
	loadFunctionMap: async () => {
		return DataServicePY.get(`/core/load_function_map`)
	},
	getFunctionDoc: async ({ fileName }) => {
		return DataServicePY.get(`/core/getFuncDescHtml?name=${fileName}`)
	}
};

