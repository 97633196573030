import { Card, Divider, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { MdPublish } from "react-icons/md";
import { toast } from 'react-toastify';
import AdminServices from '../../services/api/AdminServices';
import { CardHeader } from "../../Components/Atoms/CardHeader";

const CreateCategory = () => {
    const [category, setcategory] = useState('');
    const [description, setDescription] = useState('');
    const [allCategories, setAllCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const onChangecategory = (e) => setcategory(e.target.value);
    const onChangeDescription = (e) => setDescription(e.target.value);

    const handleValidation = () => {
        let temp = allCategories.filter((item) => item.Category.toLowerCase() === category.trim().toLowerCase());
        if (!category.trim()) {
            toast.warn("Enter valid category title!");
            return false;
        }
        else if (description.trim().split(' ').length > 100) {
            toast.warn("Max word limit of description is 100!");
            return false;
        }
        else if (temp.length) {
            toast.error("Category already exists!");
            return false;
        }
        else {
            return true;
        }
    }

    const getAllCategories = async () => {
        let res = await AdminServices.getBlogCategories().then(res => res);
        if (res.type === true) {
            setAllCategories(res.data?.filter((el)=>el?.IsKnowledgeBase!==1))
        }
    }

    const handlePublish = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (handleValidation()) {
            let data = { category: category.trim(), description: description.trim(),isKnowledgeBase:0 }
            let res = await AdminServices.saveBlogCategory(data).then(res => res);
            toast.success("Category published.")
            await getAllCategories();
        }
        setLoading(false)
    }

    React.useEffect(() => {
        getAllCategories();
    }, [])
    return (
        <React.Fragment>
            <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                <CardHeader heading={'Create category'} />
                <Divider style={{ margin: "1rem 0" }} />
                <div className='mb-4'>
                    <label>
                        Category Title:
                    </label>
                    <br />
                    <Input placeholder='Title' style={{ fontSize: "16px", padding: "5px 10px", width: "280px" }} className='mw-100 ' value={category}
                        onChange={onChangecategory} />
                </div>
                <div className='mb-4'>
                    <label>
                        Description:
                    </label>
                    <br />
                    <TextArea placeholder='Description' value={description} onChange={onChangeDescription} style={{
                        width: "100%", height: "200px", resize: "none", fontSize: "15px", padding: "8px 10px",
                    }} />
                </div>
                <div className='mb-2'>
                    <button className='btnPrimary' onClick={handlePublish} disabled={loading}>
                        <MdPublish className='mr-2' />Publish
                    </button>
                </div>
            </Card>
        </React.Fragment>
    )
}

export default CreateCategory;
