import moengage from "@moengage/web-sdk";
import { Dropdown, Modal, Table, Tag } from "antd";
import ActivityLoaderMintbox from "Assets/styles/components/ActivityLoaderMintbox";
import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { toast } from "react-toastify";
import AdminServices from "../../services/api/AdminServices";
import { CurrencyFormat } from "../Components/CurrencyFormat";
import CustomDrawer from "../Components/CustomDrawer";
import { formatDate } from "../shared/components/DateFormatter";

export const ClientOneTimePays = ({ clientId, drawerOpen, toggle = () => { }, client = {} }) => {

    const [transactions, setTransactions] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [confirmActionModal, setConfirmActionModal] = React.useState(false);
    const [confirmActionData, setConfirmActionData] = React.useState({});

    const loadData = async () => {
        var tr1 = await AdminServices.getPPTrans(clientId, "id").then((res) => { return res });
        console.log(tr1)
        setTransactions(tr1?.data)
        setLoading(false)
    }

    const triggerOneTimeEndEvent = async (oneTimeObj) => {
        let userTracked = await moengage.add_unique_user_id("SHARPELY-" + client?.ClientId);
        if (userTracked) {
            moengage.add_email(client.Email)
            moengage.add_mobile(client.MobileNumber)
            moengage.add_user_name(client.DisplayName)
            moengage.add_user_attribute("SharpelyAccessLevel", client.SharpelyAccessLevel);
            moengage.track_event('ONE_TIME_ENDED', {
                userId: client?.ClientId,
                planName: oneTimeObj?.Plan
            })
            moengage.destroy_session();
            moengage.track_event('Logout');
            toast.info('Event triggered.')
        }
    }

    const triggerOneTimePaySuccess = async (oneTimeObj) => {
        console.log(oneTimeObj)
        let userTracked = await moengage.add_unique_user_id("SHARPELY-" + client?.ClientId);
        if (userTracked) {
            console.log(userTracked, oneTimeObj, client);
            moengage.add_email(client.Email)
            moengage.add_mobile(client.MobileNumber)
            moengage.add_user_name(client.DisplayName)
            moengage.add_user_attribute("SharpelyAccessLevel", client.SharpelyAccessLevel);
            moengage.track_event('ONETIME_PAYMENT_PLAN_SUCCESSFUL', {
                amount: Number(oneTimeObj?.Amount) / 100,
                userId: client?.ClientId,
                plan: oneTimeObj?.Plan,
                period: 'Yearly'
            })
            console.log(moengage.isMoeLoaded());
            setTimeout(() => {
                moengage.destroy_session();
                moengage.track_event('Logout');
            }, 10000)
            toast.info('Event triggered.')
        }
    }

    const triggerOneTimeInitiated = async (oneTimeObj) => {
        let userTracked = await moengage.add_unique_user_id("SHARPELY-" + client?.ClientId);
        if (userTracked) {
            moengage.add_email(client.Email)
            moengage.add_mobile(client.MobileNumber)
            moengage.add_user_name(client.DisplayName)
            moengage.add_user_attribute("SharpelyAccessLevel", client.SharpelyAccessLevel);
            moengage.track_event('ONETIME_PAYMENT_PLAN_INITIATED', {
                amount: Number(oneTimeObj?.Amount) / 100,
                userId: client?.ClientId,
                plan: oneTimeObj?.Plan,
                period: 'Yearly'
            })
            moengage.destroy_session();
            moengage.track_event('Logout');
            toast.info('Event triggered.')
        }
    }

    const getOneTimeActions = (obj) => {
        let temp = [
            {
                key: "OneTimeInitiated", label: "Trigger Onetime initiated event",
                onClick: () => {
                    setConfirmActionData({ value: "OneTimeInitiated", data: obj })
                    setConfirmActionModal(true);
                }
            },
            {
                key: "OneTimePaySuccessful", label: "Trigger Onetime Payment Successful",
                onClick: () => {
                    setConfirmActionData({ value: "OneTimePaySuccessful", data: obj })
                    setConfirmActionModal(true);
                }
            },
            {
                key: "OneTimeEnded", label: "Trigger Onetime ended event",
                onClick: () => {
                    setConfirmActionData({ value: "OneTimeEnded", data: obj })
                    setConfirmActionModal(true);
                }
            }
        ];
        return temp;
    }

    React.useEffect(() => {
        if (!drawerOpen) {
            setTransactions([]);
            setLoading(true);
        } else {
            loadData();
        }
    }, [clientId, drawerOpen])

    const transactionCols = [
        {
            title: "Date",
            dataIndex: "CreatedOn",
            align: 'left',
            render: (text) => {
                return formatDate(text)
            }
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            align: "right",
            render: (text) => {
                return CurrencyFormat(Number(text) / 100)
            }
        },
        {
            title: "Status",
            dataIndex: "State",
            align: "right",
            render: (text) => {
                return <Tag color={(['ACCEPTED', 'COMPLETED', 'SUCCESS', 'SUCCESSFUL'])?.includes(text) ? "var(--green)" : (['FAILED', 'CANCELLED'])?.includes(text) ? "var(--red)" :
                    "var(--blue)"}>{text}</Tag>
            }
        },
        {
            title: "Actions",
            dataIndex: "MerchantTransactionId",
            key: "MerchantTransactionId",
            render: (_, obj) => {
                return <Dropdown menu={{ items: getOneTimeActions(obj) }} placement="bottomRight">
                    <span className="d-flex align-items-center textSM justify-content-end" style={{ gap: 2, cursor: "pointer" }}>
                        Actions
                        <FiChevronDown />
                    </span>
                </Dropdown>
            },
            align: "right"
        },
        // {
        //     title: "Events",
        //     dataIndex: "CreatedOn",
        //     align: "right",
        //     render: (_, obj) => {
        //         return <Popconfirm title='Do you really want to trigger event?' onConfirm={() => triggerOneTimeEndEvent(obj)} >
        //             <span style={{ color: "var(--primary)", cursor: "pointer" }}>Trigger one-time ended event</span>
        //         </Popconfirm>
        //     }
        // }
    ]

    const confirmActionsData = [
        {
            key: 0, value: "OneTimeEnded", title: "Do you really want to trigger event?", note: "Note: This action is irreversible.", onConfirm: () => {
                triggerOneTimeEndEvent(confirmActionData?.data)
            }
        },
        {
            key: 1, value: "OneTimePaySuccessful", title: "Do you really want to trigger event?", note: "Note: This action is irreversible.", onConfirm: () => {
                triggerOneTimePaySuccess(confirmActionData?.data)
            }
        },
        {
            key: 1, value: "OneTimeInitiated", title: "Do you really want to trigger event?", note: "Note: This action is irreversible.", onConfirm: () => {
                triggerOneTimeInitiated(confirmActionData?.data)
            }
        }
    ]

    let selectedConfirmActionData = React.useMemo(() => {
        return confirmActionsData?.find((el) => el?.value === confirmActionData?.value) || {};
    }, [confirmActionData]);

    return (
        <React.Fragment>
            <CustomDrawer isOpen={drawerOpen} toggle={toggle} title='One time payments'>
                {loading ? <ActivityLoaderMintbox /> : transactions?.length <= 0 ? <div className="textSM dark3 w-500 my-4">
                    No payment history found!
                </div> :
                    <Table dataSource={transactions} columns={transactionCols} pagination={false} />}
                <Modal open={confirmActionModal} onCancel={() => {
                    setConfirmActionModal(false);
                    setConfirmActionData({});
                }} footer={null} centered>
                    <div>
                        <div className="textMD w-500 dark4">
                            {selectedConfirmActionData?.title}
                        </div>
                    </div>
                    <div className="mt-2">
                        <span className="textXS dark3">{selectedConfirmActionData?.note}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-end" style={{ gap: '1.25rem', marginTop: '1.25rem' }}>
                        <button className="btnWhite" onClick={() => {
                            setConfirmActionModal(false);
                            setConfirmActionData({});
                        }}>Cancel</button>
                        <button className="btnBlack" onClick={() => {
                            if (selectedConfirmActionData?.onConfirm) {
                                selectedConfirmActionData.onConfirm()
                            }
                            setConfirmActionModal(false);
                            setConfirmActionData({});
                        }}>Confirm</button>
                    </div>
                </Modal>
            </CustomDrawer>
        </React.Fragment>
    )
}