
import pako from 'pako';
import AdminServices from '../services/api/AdminServices';
var base64 = require('base-64');

export function isSubsequence(subseq, str) {
    let i = 0;
    for (const char of str) {
        if (char?.toLowerCase() === subseq[i]?.toLowerCase()) {
            i++;
            if (i === subseq.length) {
                return true;
            }
        }
    }
    return false;
}

export const convertToJson = (data) => {
    const keys = data.keys
    const values = JSON.parse(data.values)

    const z = values.map(val => {
        var obj = {}
        keys.map((k, i) => {
            Object.assign(obj, { [k]: val[i] })
        })
        return obj
    })
    return JSON.stringify(z)
}

export const decodeData = (input) => {
    let compressedData = Uint8Array.from(base64.decode(input), (c) => c.charCodeAt(0));
    let decompressedData = pako.inflate(compressedData, { to: "string" });
    let jsonObject = JSON.parse(decompressedData?.replace(/NaN/g, null));
    return jsonObject;
}

const callPPAuthStatus = async (id) => {
    var tr1 = await AdminServices.getPPAuthRequests(id).then((res) => { return res })
    if (tr1?.data?.length) {
        await AdminServices.getPPAuthReqStatus(tr1?.data?.[0]?.AuthId).then((res) => { return res })
    }
    return {
        AuthId: tr1?.data?.[0]?.AuthId,
        CreatedOn: tr1?.data?.[0]?.CreatedOn
    }
}

export const getClientAllSubs = async ({ id, razorpayId }) => {
    try {
        let allPPSubs = await AdminServices.getPPSubscriptions(id);
        allPPSubs = allPPSubs?.data || [];
        if (allPPSubs?.length > 0) {
            allPPSubs = allPPSubs?.reverse();
            for (let i = 0; i < allPPSubs?.length; i++) {
                let ele = allPPSubs?.[i]
                ele = {
                    ...ele,
                    PGType: "PhonePe"
                }
                let temp = await callPPAuthStatus(ele?.SubscriptionId);
                if (temp?.CreatedOn) {
                    ele = {
                        ...ele,
                        ...temp
                    }
                }
                allPPSubs[i] = ele;
            }
        }
        let allRPSubs = await AdminServices.getRazorSubscriptionsByCustId({ id: razorpayId });
        allRPSubs = allRPSubs?.data || [];
        if (allRPSubs?.length > 0) {
            allRPSubs = allRPSubs?.reverse()
            let sharpelyPlans = await AdminServices.getRazorPlans();
            sharpelyPlans = sharpelyPlans?.data
            if (sharpelyPlans) {
                sharpelyPlans = JSON.parse(sharpelyPlans);
            }
            else {
                sharpelyPlans = {}
            }
            allRPSubs = allRPSubs?.map((el) => {
                let t = JSON.parse(el);
                let pl = sharpelyPlans?.items?.find((ele) => ele?.id === t?.plan_id)
                t = {
                    ...t,
                    SubscriptionState: String(t?.status)?.toUpperCase(),
                    SubscriptionId: t?.id,
                    CreatedOn: new Date(Number(t?.created_at) * 1000)?.toISOString(),
                    Frequency: pl?.period,
                    Amount: pl?.item?.amount,
                    SubscriptionName: pl?.item?.name,
                    PGType: "RazorPay"
                }
                return t
            })
        }
        let allSubs = [];
        if (allPPSubs?.length > 0) {
            allSubs = [...allPPSubs]
        }
        if (allRPSubs?.length > 0) {
            allSubs = [...allSubs, ...allRPSubs]
        }
        allSubs.sort((a, b) => {
            const dateA = a.CreatedOn ? new Date(a.CreatedOn).getTime() : Infinity;
            const dateB = b.CreatedOn ? new Date(b.CreatedOn).getTime() : Infinity;
            const currentDate = new Date();
            if (!a.CreatedOn && !b.CreatedOn) {
                return 0;
            } else if (!a.CreatedOn) {
                return 1;
            } else if (!b.CreatedOn) {
                return -1;
            }
            const diffA = Math.abs(currentDate.getTime() - dateA);
            const diffB = Math.abs(currentDate.getTime() - dateB);
            return diffA - diffB;
        });
        return allSubs;
    }
    catch (err) {
        console.log(err)
        return [];
    }
}