import { encrypt, decrypt } from "./SHA256Hasher";

const MODEL = {
  user: 'user',
  ifa: 'ifa',
  theme: 'theme',
  profileComplete: 'ProfileComp',
  userData: 'userData',
  appData: 'appData',
  notification: 'notification',
  dashboard:"dashboard",
  clientData:"clientData",
  smallcaseSDKToken:"smallcaseSDKToken",
  createMintbox: 'createMintbox',
  watchlist: 'watchlist',
  // all funds
  allMintboxes: 'allMintboxes',
  allEtfs: 'allEtfs',
  allMutualFunds:"allMutualFunds",
  allClients:'allClients',
  //portfolio
  portfolio: 'portfolio',
  portfolioPerformance:'portfolioPerformance',
  assetAllocation:'assetAllocation',
  portfolioXirr:"portfolioXirr",
  transactions:"transactions",
  sip:"sip",
  recentlyViewed:'recentlyViewed',
  riskProfile: 'riskProfile',
  createGoal: 'createGoal',
  dismissNotes: 'dismissNotes',
  dematBalance: 'dematBalance'
};

const db = {
  // hash : new SHA256Hasher(),
  model: MODEL,
  get: async (key, dec=false) => {
    key = MODEL[key] ? MODEL[key] : key;
    let data = localStorage.getItem(key)
    if(data === null){
      return null
    }
    if(dec) {
      data = decrypt(data);
    }
    // if (!data) return {}
    // const decryptedData = await db.hash.decrypt(new Uint8Array(data.split(",").map(Number)));
    // console.log(decryptedData);
    try {
      return JSON.parse(data)
    } catch (error) {
      return data
    }
  },
  set: async (key, val, enc=false) => {
    key = MODEL[key] ? MODEL[key] : key;
    if(enc) {
      val = encrypt(val)
    }
    // const enc = await db.hash.encrypt(val);
    const data = await localStorage.setItem(key, val);
    return data;
  },
  remove: async (key) => await localStorage.removeItem(key),
  clear: async () => {
    // const res = await AdminServices.getSmallcasetoken().then(rs => rs)
    // if (res.type) {
    //     window.sc.init({ smallcaseAuthToken: res.data })
    // }
    var aa = localStorage.getItem("lastEmail")
    var bb = localStorage.getItem("lastMobile")
    await localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("lastEmail",aa)
    localStorage.setItem("lastMobile",bb)
  },
};

export default db;