import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AssetAllocationChart = ({ assetAllocation }) => {
    var options = {
        chart: {
            type: 'pie',
            style: {
                fontFamily: 'sans-serif',
                fontWeight: 'normal'
            },
            backgroundColor: "transparent",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            height: 200
        },
        title: {
            text: ''
        },
        scrollbar: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'center',
            layout: 'vertical',
            itemMarginBottom: 10,
            itemStyle: {
                color: 'var(--black)',
                fontSize: 14,
                fontWeight: 'normal',
                fontFamily: 'sans-serif'
            }
        },

        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },

                showInLegend: true,
                size: 150,
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            valueSuffix: '%'
        },
        series: [
            {
                data: assetAllocation,
                name: "Assets"
            }
        ]
    };
    return (
        <div className='mt-2 mb-2'>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export default AssetAllocationChart;