import moengage from "@moengage/web-sdk";
import { Card, Divider, Dropdown, Input, Modal, Radio } from 'antd';
import ActivityLoaderMintbox from 'Assets/styles/components/ActivityLoaderMintbox';
import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { IoMdDownload } from "react-icons/io";
import { toast } from 'react-toastify';
import { CardHeader } from "../../Components/Atoms/CardHeader";
import { useAuth } from '../../context/AuthContext';
import AdminServices from '../../services/api/AdminServices';
import MyTable from '../Components/TableWithScroll';
import { formatDate } from '../shared/components/DateFormatter';
import { ClientOneTimePays } from './ClientOneTimePays';
import { ClientSubscriptions } from './ClientSubscriptions';
import { isSubsequence } from "libs/utils";

export default function ManageClients(props) {

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [reportloading, setreportLoading] = React.useState(true)
  const [displayData, setDisplayData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedClient, setSelectedClient] = React.useState('');
  const [showClientSubs, setShowClientSubs] = React.useState(false);
  const [showClientOneTimePay, setShowClientOneTimePay] = React.useState(false);
  const [appliedFilters, setAppliedFilters] = React.useState({});
  const [updateAccessModalParams, setUpdateAccessModalParams] = React.useState({ show: false, frequency: "", clientId: '', accessLevel: "" });
  const { ifaData } = useAuth();
  const tableRef = React.useRef();

  const getIFAClients = async (id) => {
    let res = await AdminServices.getIFAClients(id).then(res => res);
    if (res.type === true) {
      let aa = res.data;
      console.log(aa);
      if (!(ifaData?.email?.endsWith('@mintbox.ai'))) {
        aa = aa.filter((el) => {
          let d = new Date(el?.CreatedOn);
          let month = d.getMonth();
          let year = d.getFullYear();
          return year >= 2023 && month >= 6;
        })
      }
      setData(aa)
      setDisplayData(aa);
      setLoading(false)
      setreportLoading(false);
    }
  }

  React.useEffect(() => {
    getIFAClients(-1)
  }, [ifaData])

  const updateAccess = async () => {
    let client = data?.find((e) => e?.ClientId === updateAccessModalParams?.clientId)
    let userTracked = await moengage.add_unique_user_id("SHARPELY-" + client?.ClientId);
    if (userTracked) {
      moengage.add_email(client.Email)
      moengage.add_mobile(client.MobileNumber)
      moengage.add_user_name(client.DisplayName)
      moengage.add_user_attribute("SharpelyAccessLevel", client.SharpelyAccessLevel);
    }
    let res = await AdminServices.updateSharpelyAccess({ clientId: client?.ClientId, newAccessLevel: updateAccessModalParams?.accessLevel, frequency: updateAccessModalParams?.frequency });
    if (userTracked) {
      moengage.add_user_attribute("SharpelyAccessLevel", updateAccessModalParams?.accessLevel);
    }
    moengage.destroy_session();
    moengage.track_event('Logout');
    if (res.type === true) {
      toast.success('Access updated.')
    }
    else {
      toast.error('Something went wrong.');
    }
    setUpdateAccessModalParams({ show: false, frequency: "", clientId: '', accessLevel: "" })
  }

  const columns = [
    {
      title: 'Client Id',
      dataIndex: 'ClientId',
      key: 'ClientId',
      sorter: (a, b) => a.ClientId - b.ClientId,
      render: (text) => <div>{text}</div>,
      width: 100
    },
    {
      title: 'Client name',
      dataIndex: 'DisplayName',
      key: 'DisplayName',
      width: 150
    },
    {
      title: "Access level",
      dataIndex: "SharpelyAccessLevel",
      key: "SharpelyAccessLevel",
      filters: [
        { text: "Free", value: 1 },
        { text: "Lite", value: 2 },
        { text: "Pro", value: 3 },
        { text: "Enterprise", value: 4 }
      ],
      onFilter: (value, record) => record.SharpelyAccessLevel === value,
      width: 120
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      filterSearch: true,
      width: 150
    },
    {
      title: 'Mobile no.',
      dataIndex: 'MobileNumber',
      key: 'MobileNumber',
      width: 150
    },
    {
      title: "Newsletter",
      dataIndex: "IsNewsletterSubscribed",
      key: "IsNewsletterSubscribed",
      filters: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      onFilter: (value, record) => record.IsNewsletterSubscribed === value,
      width: 150
    },
    {
      title: 'Client since',
      dataIndex: 'CreatedOn',
      key: 'CreatedOn',
      render: (text) => <div>{formatDate(text)}</div>,
      width: 150
    },
    {
      title: "Next due on",
      dataIndex: "SharpelyPayDueDate",
      key: "SharpelyPayDueDate",
      render: (text) => <div>{formatDate(text)}</div>,
      sorter: (a, b) => {
        if (!a.SharpelyPayDueDate) return 1;
        if (!b.SharpelyPayDueDate) return -1;
        const dateA = new Date(a.SharpelyPayDueDate);
        const dateB = new Date(b.SharpelyPayDueDate);
        const currentDate = new Date();
        return Math.abs(dateA - currentDate) - Math.abs(dateB - currentDate);
      },
      width: 150
    },
    {
      title: "Subscriptions",
      dataIndex: 'ClientId',
      key: "ClientId",
      render: (id, obj) => <div onClick={() => {
        setSelectedClient(obj);
        setShowClientSubs(true);
      }} style={{ cursor: "pointer", color: "var(--blue)" }}>Show subscriptions</div>,
      width: 150
    },
    {
      title: "One time",
      dataIndex: 'ClientId',
      key: "ClientId",
      render: (id, obj) => <div onClick={() => {
        setSelectedClient(obj);
        setShowClientOneTimePay(true);
      }} style={{ cursor: "pointer", color: "var(--blue)" }}>Show</div>,
      width: 150
    },
    {
      title: "Update access",
      dataIndex: "ClientId",
      key: "ClientId",
      render: (id, obj) => {
        return (
          <Dropdown menu={{
            items: [{
              key: 1, value: 1, label: "Free", onClick: () => {
                setUpdateAccessModalParams({ show: true, clientId: id, accessLevel: 1, frequency: "" })
              }
            },
            {
              key: 2, value: 2, label: "Lite", onClick: () => {
                setUpdateAccessModalParams({ show: true, clientId: id, accessLevel: 2, frequency: "" })
              }
            },
            {
              key: 3, value: 3, label: "Pro", onClick: () => {
                setUpdateAccessModalParams({ show: true, clientId: id, accessLevel: 3, frequency: "" })
              }
            },
            {
              key: 4, value: 4, label: "Enterprise", onClick: () => {
                setUpdateAccessModalParams({ show: true, clientId: id, accessLevel: 4, frequency: "" })
              }
            },],
            style: {}
          }} >
            <span style={{ cursor: "pointer" }}>
              Update <FiChevronDown />
            </span>
          </Dropdown>
        )
      },
      width: 150
    }
  ];

  const exportToCsv = () => {
    const preventCols = ["One time", "Update access", "Subscriptions", "Next due on"];

    const filteredData = data?.filter((ele) => {
      if (Object.keys(appliedFilters || {})?.length === 0) {
        return true;
      }
      else {
        let c = Object.entries(appliedFilters || {})?.every(([key, val]) => Array.isArray(val) ? val?.includes(ele[key]) : val === null);
        return c;
      }
    })
    console.log(filteredData)
    const csvContent = [
      columns?.filter((el) => !preventCols.includes(el.title)).map((column) => column.title).join(','), // Header row
      ...filteredData.map((record) => columns?.filter((el) => !preventCols.includes(el.title)).map((column) => record[column.dataIndex]).join(',')),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'table_data.csv';
    link.click();
  };

  if (loading) {
    return <ActivityLoaderMintbox />
  }
  return (
    <React.Fragment>
      <Card style={{ borderRadius: "var(--borderRadius)" }}>
        <div className='d-flex align-items-center justify-content-between'>
          <CardHeader heading={'All clients'} />
          <Input
            type="text"
            placeholder="Search by client id / email"
            value={searchText}
            style={{ width: "15rem", background: "var(--grey4)" }}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button className='btnWhite' onClick={exportToCsv} style={{ marginLeft: "1.25rem" }}><IoMdDownload /></button>
        </div>
        <Divider style={{ margin: "1rem 0" }} />
        <MyTable tableRef={tableRef} paginationSize={50} columns={columns} dataSource={data.filter((item) => {
          if (!searchText && searchText !== 0) {
            return true;
          }
          // return item['Email']?.includes(searchText)
          return (item['ClientId'] === Number(searchText) || item['Email']?.includes(searchText) || isSubsequence(searchText, item['DisplayName']) || isSubsequence(searchText, String(item['MobileNumber'])));
        })} scroll={{ x: "max-content", y: "65vh" }} style={{
          border: "1px solid var(--grey2)",
          borderRadius: "var(--borderRadius)"
        }} onChange={(a, b, c, d) => {
          console.log(a, b, c, d)
          setAppliedFilters(b)
        }} />
      </Card>
      {showClientSubs && <ClientSubscriptions clientId={selectedClient?.ClientId} drawerOpen={showClientSubs} toggle={() => setShowClientSubs(false)} client={selectedClient} />}
      {showClientOneTimePay && <ClientOneTimePays clientId={selectedClient?.ClientId} client={selectedClient} drawerOpen={showClientOneTimePay} toggle={() => setShowClientOneTimePay(false)} />}
      <Modal open={updateAccessModalParams?.show} centered title={<div className="textLG w-700 dark4">Update access</div>} onCancel={() => {
        setUpdateAccessModalParams({ show: false, frequency: "", clientId: '', accessLevel: "" })
      }} onOk={() => {
        updateAccess();
      }}>
        <div>
          <div className="textSM w-500 dark3 mb-3">Select frequency</div>
          <Radio.Group value={updateAccessModalParams?.frequency} onChange={(e) => {
            setUpdateAccessModalParams(prev => {
              return {
                ...prev,
                frequency: e.target.value
              }
            })
          }}>
            <Radio value={''}>Today</Radio>
            <Radio value={'1M'}>1M</Radio>
            <Radio value={'6M'}>6M</Radio>
            <Radio value={'1Y'}>1Y</Radio>
          </Radio.Group>
        </div>
      </Modal>
    </React.Fragment>
  )
}