import React, { Suspense } from "react";
import Spinner from "../Components/Atoms/Spinner";
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminProtectedRoutes from './AdminProtectedRoutes';
import FunctionsDocumentation from "../app/sharpely/FunctionsDocumentation";
import EditCategory from '../app/CreateCategory/EditCategory';
import Login from '../Pages/UserPages/Login';
import Register1 from '../Pages/UserPages/Register';
import Dashboard from '../app/dashboard/Dashboard';
import ManageClients from '../app/client/ManageClients';
import ClientDetails from '../app/client/ClientDetails';
import Automation from '../app/automation/Automation';
import BankNPA from '../app/Stocks/BankNPA';
import AllBlogs from '../app/Blogs/AllBlogs';
import CreateBlog from '../app/Blogs/CreateBlog';
import AllCategories from '../app/Blogs/AllCategories';
import CreateBlogCategory from '../app/Blogs/CreateCategory';
import EditResource from '../app/CreateResource/EditResource';
import CreateSubCategory from '../app/CreateCategory/CreateSubCategory';
import CreateCategory from '../app/CreateCategory/CreateCategory';
import CreateResource from '../app/CreateResource/CreateResource';
import CreatePost from '../app/createDoc/createPost';
import PaidClientsDashboard from "../Pages/Clients/PaidClientsDashboard";

export default function AppRoutes() {

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register1} />
        <AdminProtectedRoutes exact path="/dashboard" component={Dashboard} />
        <AdminProtectedRoutes exact path="/clients/list" component={ManageClients} />
        <AdminProtectedRoutes exact path="/clients/details/:id" component={ClientDetails} />
        <AdminProtectedRoutes exact path="/automation" component={Automation} />
        <AdminProtectedRoutes exact path="/banks/banknpa" component={BankNPA} />
        <AdminProtectedRoutes path="/knowledgeBase/create" component={CreatePost} />
        <AdminProtectedRoutes path='/knowledgeBase/create-resource' component={CreateResource} />
        <AdminProtectedRoutes path='/knowledgeBase/create-category' component={CreateCategory} />
        <AdminProtectedRoutes path='/knowledgeBase/create-subcategory' component={CreateSubCategory} />
        <AdminProtectedRoutes path='/knowledgeBase/all-resources' component={EditResource} />
        <AdminProtectedRoutes path='/blogs/create-category' component={CreateBlogCategory} />
        <AdminProtectedRoutes path='/blogs/all-categories' component={AllCategories} />
        <AdminProtectedRoutes path='/blogs/create-blog' component={CreateBlog} />
        <AdminProtectedRoutes path='/blogs/all-blogs' component={AllBlogs} />
        <AdminProtectedRoutes path='/clients/paid-clients-dashboard' component={PaidClientsDashboard} />
        <AdminProtectedRoutes path='/knowledgeBase/edit-category' component={EditCategory} />
        <AdminProtectedRoutes path='/sharpely/functions-docs' component={FunctionsDocumentation} />
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
}