import React from 'react';

export const useIsMobile = () => {

    const [isMobileInit, setIsMobileInit] = React.useState(window.matchMedia('(max-width:760px)').matches);

    React.useEffect(() => {
        let matches = window.matchMedia('(max-width:760px)');
        setIsMobileInit(matches.matches);

        const handleResize = () => {
            let matches = window.matchMedia('(max-width:760px)');
            setIsMobileInit(matches.matches);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return isMobileInit;
}