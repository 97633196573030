import { Segmented } from 'antd';
import React from 'react';

export default function CustomSegmentedTab({ options = [], isBlock = true, onChange = () => { }, value = '', style = {}, className = '', flexVal = 1, size = 'middle', }) {

    const onChangeVal = (i) => {
        onChange(i)
    }
    if (options?.length) {
        return <div><Segmented block={isBlock}
            value={value}
            size={size}
            className={'w-500 ' + className}
            style={{ padding: '0.5rem', lineHeight: "unset", flex: flexVal, alignItems: "center", textAlign: "center", ...style }}
            onChange={(e) => onChangeVal(e)}
            options={
                options
            }
        /></div>
    }
}