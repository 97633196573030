import React from 'react';
import AMCIcon from './AMCIcon';

function ConstituentsTable({ fundConstituents, type = '' }) {
    const displayAssetClass = (id) => {
        const assetClasses = [
            {
                name: 'Indian Equity',
                color: '#294C88'
            },
            {
                name: 'Gold',
                color: '#FAAD3B'
            },
            {
                name: 'Foreign Equity',
                color: '#F97B30'
            },
            {
                name: 'Debt',
                color: '#00A6A4'
            },
            {
                name: 'Cash',
                color: '#74BA85'
            },
            {
                name: 'Silver',
                color: '#ABABAB'
            }
        ];
        if (fundConstituents) {
            const fundsArray = fundConstituents.filter(fund => fund.AssetClassId === id)
            const totalAllocation = fundsArray.reduce((total, fund) => Number(fund.CurrentWeightage) + total, 0);
            const assetClassName = assetClasses[id - 1].name
            const assetColor = assetClasses[id - 1].color

            if (fundsArray.length === 0) { return <></> }
            else return <>
                <tr style={{backgroundColor:assetColor+'88', color:'#fff'}}>
                    <th colSpan={3}>{assetClassName}</th>
                    <th colSpan={4}>{Number(totalAllocation).toFixed(1)}%</th>
                </tr>
                {fundsArray.map((fund, i) => {
                    return (
                        <tr style={{backgroundColor:assetColor+'11'}} key={i}>
                            <td><AMCIcon amcName={fund.AMCName} height='20px' width='20px'/></td>
                            <td>{fund.ETFName}</td>
                            <td>{fund.FundCode}</td>
                            <td>{Number(fund.CurrentWeightage).toFixed(1)}%</td>
                            <td>{fund.MintboxETFBalance}</td>
                            <td>{fund.Units}</td>
                            <td>{fund.NAV}</td>
                        </tr>
                    )
                })
                }
            </>
        }
    }
    return (
        <>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr style={{color:'#fff'}}>
                            <th></th>
                            <th>Fund</th>
                            <th>Fund code</th>
                            <th>Weightage</th>
                            <th>Balance</th>
                            <th>Units</th>
                            <th>NAV</th>
                        </tr>
                    </thead>
                    <tbody>
                        { displayAssetClass(1) }
                        { displayAssetClass(2) }
                        { displayAssetClass(3) }
                        { displayAssetClass(4) }
                        { displayAssetClass(5) }
                        { displayAssetClass(6) }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ConstituentsTable