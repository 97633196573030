import React from 'react';
import { Spin } from 'antd';

const ActivityLoaderMintbox = ({ type = '', margin = '10%' }) => {

  return (
    <div style={{ marginTop: margin, marginBottom: margin }}>
      <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spin style={{ color: "var(--primary)" }} />
      </div>
    </div>
  )
}

export default React.memo(ActivityLoaderMintbox)