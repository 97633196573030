import React from "react";
import AdminServices from "../../services/api/AdminServices";
import ActivityLoaderMintbox from "../../Components/ActivityLoaderMintbox";
import { Row, Col } from 'reactstrap';
import { Card, Divider, Tag } from 'antd';
import { CardHeader } from "Components/Atoms/CardHeader";
import { TbPremiumRights } from "react-icons/tb";
import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official";
import { useIsMobile } from "CustomHooks/useIsMobile";
import { CurrencyFormat } from "Components/CurrencyFormat";

const PaidClientsGrowthChart = ({ plan = '', data = [] }) => {

    let yearKey = plan ? `${plan}StartYear` : "StartYear";
    let monthKey = plan ? `${plan}StartMonth` : "StartMonth";
    let valueKey = plan ? `Total${plan}Clients` : "Clients";

    const categories = data?.map((ele) => {
        if (ele?.[yearKey] && ele?.[monthKey]) return `${ele?.[yearKey]}-${ele?.[monthKey]}`;
        return 'Initial'
    })

    const options = {
        title: {
            text: ""
        },
        credits: {
            enabled: false
        },
        chart: {
            height: 300,
            type: "column"
        },
        xAxis: {
            categories: categories,
            title: ""
        },
        yAxis: {
            title: ""
        },
        legend: {
            enabled: false
        },
        series: [
            {
                name: "Clients",
                data: data?.map((el) => el?.[valueKey]),
                color: 'var(--primary)'
            }
        ]
    }

    return (
        <React.Fragment>
            <Card>
                <div className="w-500 textMD dark4">
                    {plan ? `${plan} clients growth` : 'Paid clients growth'}
                </div>
                <div style={{ marginTop: "1rem" }}>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
            </Card>
        </React.Fragment>
    )
}

const MonthlyRevenueChart = ({ data = [] }) => {

    let yearKey = "AmountYear";
    let monthKey = "AmountMonth";

    const categories = data?.map((ele) => {
        if (ele?.[yearKey] && ele?.[monthKey]) return `${ele?.[yearKey]}-${ele?.[monthKey]}`;
        return 'Initial'
    })

    const options = {
        title: {
            text: ""
        },
        credits: {
            enabled: false
        },
        chart: {
            height: 400,
            type: "column"
        },
        xAxis: {
            categories: categories,
            title: ""
        },
        yAxis: {
            title: ""
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        series: [
            {
                name: "Amount",
                data: data?.map((el) => el?.Amount),
                color: 'var(--primary)',
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return CurrencyFormat(this.y)
                    }
                }
            }
        ]
    }

    return (
        <React.Fragment>
            <Card>
                <div className="w-500 textMD dark4">
                    Monthly revenue
                </div>
                <div style={{ marginTop: "1rem" }}>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
            </Card>
        </React.Fragment>
    )
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default function PaidClientsDashboard() {

    const [dashboardData, setDashboardData] = React.useState({})
    const [loading, setLoading] = React.useState(true);
    const isMobile = useIsMobile();

    const loadPPDashboard = async () => {
        let res = await AdminServices.getPPDashboardData();
        if (res?.type === true) {
            let data = JSON.parse(res.data);
            let temp = {};
            data.forEach((ele) => {
                let key = Object.keys(ele || {});
                temp[key] = ele[key];
            })
            console.log(temp)
            setDashboardData(temp);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        loadPPDashboard();
    }, []);

    const allPlansPaidClients = [
        { key: 1, label: "Lite", value: 2 },
        { key: 2, label: "Pro", value: 3 },
        { key: 3, label: "Black", value: 4 }
    ]

    if (loading) {
        return (
            <div>
                <ActivityLoaderMintbox />
            </div>
        )
    }
    return (
        <React.Fragment>
            <div className='normalContainer'>
                <Row lg={4} md={4} sm={2} xs={1} noGutters>
                    <Col>
                        <Card>
                            <div className="d-flex align-items-start justify-content-between">
                                <div className="textMD w-500 dark4">Paid clients</div>
                                <Tag color='#dc143c' className='p-1'>
                                    <TbPremiumRights size='16px' />
                                </Tag>
                            </div>
                            <Divider style={{ margin: "0.5rem 0" }} />
                            <div className="textLG w-700 dark4">{dashboardData?.TotalAccessClients?.[0]?.TotalAccessClients}</div>
                        </Card>
                    </Col>
                    {allPlansPaidClients?.map((ele) => {
                        return (
                            <Col key={ele.key}>
                                <Card key={ele.key}>
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div className="textMD w-500 dark4">{ele.label} clients</div>
                                        <Tag color='#dc143c' className='p-1'>
                                            <TbPremiumRights size='16px' />
                                        </Tag>
                                    </div>
                                    <Divider style={{ margin: "0.5rem 0" }} />
                                    <div className="textLG w-700 dark4">{dashboardData?.ClientAccess?.find((el) => el?.SharpelyAccessLevel === ele?.value)?.AccessClients}</div>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
                <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "1.25rem" }}>
                    <PaidClientsGrowthChart data={dashboardData?.OnboardData} />
                    <PaidClientsGrowthChart plan="Lite" data={dashboardData?.LiteClients} />
                    <PaidClientsGrowthChart plan="Pro" data={dashboardData?.ProClients} />
                    <PaidClientsGrowthChart plan="Black" data={dashboardData?.BlackClients} />
                </div>
                <MonthlyRevenueChart data={dashboardData?.Amount} />
            </div>
        </React.Fragment>
    )
}