import { ConfigProvider } from 'antd';
import React from 'react';
import { useAuth } from "../context/AuthContext";
import AppRoutes from "../Routes/AppRoutes";
import Footer from "./Footer";
import { Navbar } from "./Navbar/Navbar";
import { DrawerSidebar } from "./Sidebar/Drawer";
import { Sidebar } from "./Sidebar/Sidebar";

const MainLayout = () => {

    const [collapsed, setCollapsed] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const { isAuth } = useAuth();

    return (
        <ConfigProvider theme={{
            token: {
                colorPrimary: "#007AFF"
            }
        }}>
            <main style={{ display: "flex", flexDirection: "row", width: "100vw", height: "100vh" }}>
                {isAuth ? <Sidebar collapsed={collapsed} /> : null}
                <section style={{ flex: 1, height: "100%", overflow: "auto" }}>
                    {isAuth ? <Navbar collapsed={collapsed} setCollapsed={setCollapsed} drawer={drawer} setDrawer={setDrawer} /> : null}
                    <DrawerSidebar drawer={drawer} setDrawer={setDrawer} />
                    <div style={{ minHeight: 'calc(100vh - 50px)', color: "var(--black)", padding: isAuth ? "1.25rem" : "0rem", backgroundColor: "var(--grey3)" }}>
                        <AppRoutes />
                    </div>
                    {isAuth ? <Footer /> : null}
                </section>
            </main>
        </ConfigProvider>
    );
};

export default MainLayout;