import React from "react";
import { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';

const Editor = ({ onChange, description, desc_id }) => {
    const { quill, quillRef, Quill } = useQuill({
        modules: { blotFormatter: {} }
    });

    if (Quill && !quill) {
        // const BlotFormatter = require('quill-blot-formatter');
        Quill.register('modules/blotFormatter', BlotFormatter);
    }

    useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(`${description}`);
        }
    }, [desc_id])
    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldContents) => {
                onChange(quill.root.innerHTML)
                let currrentContents = quill.getContents();
            });
        }
    }, [quill, Quill]);

    return (
        <div>
            <div ref={quillRef} style={{ height: "300px", overflow: "scroll", color: "black" }} />
        </div>
    );
};

export default Editor;
