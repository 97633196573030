import { Card, Divider, Select } from "antd";
import ActivityLoaderMintbox from "Assets/styles/components/ActivityLoaderMintbox";
import React from "react";
import { MdPublish } from "react-icons/md";
import { toast } from "react-toastify";
import { CardHeader } from "../../Components/Atoms/CardHeader";
import { isSubsequence } from "../../libs/utils";
import AdminServices from "../../services/api/AdminServices";
import AdminServicesPY from "../../services/api/AdminServicesPY";
import { Editor2 } from "../Blogs/Editor";
// const urlMetadata = require("url-metadata");

export default function FunctionsDocumentation() {

    const [loading, setLoading] = React.useState(true);
    const [functions, setFunctions] = React.useState([]);
    const [selectedFunction, setSelectedFunction] = React.useState({});
    const [description, setDescription] = React.useState('');
    const [publishingContent, setPublishingContent] = React.useState(false);
    const [loadInitContent, setLoadInitContent] = React.useState(false);
    const [initContent, setInitContent] = React.useState('');

    const loadFunctions = async () => {
        setLoading(true);
        let res = await AdminServicesPY.loadFunctionMap();
        let func_map = JSON.parse(res?.data?.replace(/NaN/g, null)?.replace(/Infinity/g, null))
        setFunctions(func_map || []);
        setLoading(false);
    }

    const publishContent = async (e) => {
        e.preventDefault();
        if (!description) {
            toast.error('Content is empty!');
            return;
        }
        setPublishingContent(true);
        try {
            const blob = new Blob([description], { type: 'text/html' });
            const file = new File([blob], `${selectedFunction?.sh_name}.html`, { type: 'text/html' });
            const formData = new FormData();
            formData.append('file', file);
            let res = await AdminServices.updateToS3({ fileName: file?.name, folderName: "functions-docs", data: formData })
            if (res?.type === true) {
                toast.success('Published.')
                await AdminServicesPY.getFunctionDoc({ fileName: selectedFunction?.sh_name });
            }
            else {
                toast.error('Something went wrong!');
            }
        } catch (error) {
            toast.error('Something went wrong!');
            console.log(error)
        }
        setPublishingContent(false);
    }

    const loadInitialContent = async () => {
        if (selectedFunction?.sh_name) {
            setLoadInitContent(true);
            try {
                let t = await AdminServicesPY.getFunctionDoc({ fileName: selectedFunction?.sh_name });
                if (t?.data?.status_code === 400) {
                    setDescription('');
                    setInitContent('');
                }
                else if (t.type === true) {
                    setDescription(t.data)
                    setInitContent(t.data);
                }
                else {
                    setDescription('');
                    setInitContent('');
                }
            } catch (error) {
                setDescription('');
                setInitContent('');
            }
            setLoadInitContent(false);
        }
        else {
            setDescription('')
            setInitContent('');
        }
    }

    React.useEffect(() => {
        loadFunctions();
    }, []);

    React.useEffect(() => {
        loadInitialContent();
    }, [selectedFunction])

    if (loading) {
        return <ActivityLoaderMintbox />
    }
    return (
        <React.Fragment>
            <Card bodyStyle={{ padding: "1rem" }} style={{ padding: 0 }}>
                <CardHeader heading={'Edit/Update Functions Documentation'} />
                <Divider style={{ margin: "1rem 0" }} />
                <div>
                    <div className="textSM w-500 dark3 mb-2">Select function</div>
                    <Select options={functions?.map((el) => {
                        return {
                            ...el,
                            key: el?.sh_name,
                            value: el?.sh_name,
                            label: el?.sh_name,
                        }
                    })} style={{ width: "min(100%, 20rem)" }} placeholder='Select function' value={selectedFunction?.sh_name} onChange={((e) => {
                        let t = functions?.find((el) => el?.sh_name === e);
                        setSelectedFunction(t);
                    })} showSearch filterOption={(input, option) => {
                        return isSubsequence(input, option?.display_name) || isSubsequence(input, option?.sh_name)
                    }} />
                </div>
                <div className="margin20Top">
                    <div className="textSM w-500 dark3 mb-2">Content</div>
                    <Editor2 onChange={(e) => setDescription(e)} content={initContent} />
                </div>
                <div className='mt-3'>
                    <button className='btnPrimary' onClick={publishContent} disabled={publishingContent}>
                        <MdPublish className='mr-2' /> Publish
                    </button>
                </div>
            </Card>
        </React.Fragment>
    )
}