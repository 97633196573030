import React from 'react';
import { AiFillCaretDown, AiFillCaretUp, AiOutlineDown, AiOutlineRight, AiOutlineUp } from "react-icons/ai";
import { CurrencyFormat } from './CurrencyFormat';
import MintboxIcon from './MintboxIcon';
import RedGreenText from './RedGreenText';
import AMCIcon from './AMCIcon';
import FundConstituents from './FundConstituents';
import ConstituentsTable from './ConstituentsTable';

const FundCard = ({ fundName, mintboxId = 1, AMCName, balance = 0, previousBalance = 0, xirr = 0, type = 3, mintboxConstituents }) => {

  const [viewConstituents, setViewConstituents] = React.useState(false)
  let change = 0;
  let changePercentage = 0;
  if (previousBalance !== 0 && previousBalance !== '') {
    change = balance - previousBalance;
    changePercentage = change * 100 / previousBalance;
  }
  // return (
  //   <>
  //     <div style={{ padding: "1%", borderBottom: "1px solid var(--border)" }}>
  //       <div style={{ display: "flex", gap: "4%" }}>
  //         <div className='d-none d-sm-block'>
  //           {
  //             type === 1 || type === 2 ?
  //               <AMCIcon amcName={AMCName} height='40px' width='40px' />
  //               :
  //               <MintboxIcon id={mintboxId} height='40px' width='40px' />
  //           }
  //         </div>
  //         <div style={{ flex: "1" }}>
  //           <div style={{ marginBottom: "4px" }}>{fundName}</div>
  //           <div className='text-muted'>
  //             <span>1 Day </span>
  //             {
  //               change > 0 ?
  //                 <span style={{ color: 'var(--primary)' }}><AiFillCaretUp size={20} style={{ verticalAlign: "bottom" }} /> {CurrencyFormat(change, "long", 2)} ({changePercentage.toFixed(2)}%)</span>
  //                 :
  //                 change < 0 ?
  //                   <span style={{ color: 'var(--danger)' }}><AiFillCaretDown size={20} style={{ verticalAlign: "bottom" }} /> {CurrencyFormat(change, "long", 2)} ({changePercentage.toFixed(2)}%)</span>
  //                   :
  //                   <span> {CurrencyFormat(change, "long", 2)} ({changePercentage}%)</span>
  //             }
  //           </div>
  //         </div>
  //         <div style={{ textAlign: 'right' }}>
  //           <div style={{ marginBottom: "4px" }}>
  //             {CurrencyFormat(balance, "short")}
  //           </div>
  //           <div className='text-muted mb-2'>
  //             <RedGreenText text={xirr.toFixed(2) + '%'} value={xirr} />
  //           </div>
  //           {
  //             type === 3 ?
  //               viewConstituents ?
  //                 <a className='text-muted' onClick={() => setViewConstituents(false)}>
  //                   Hide Constituents<AiOutlineUp size={14} style={{ marginLeft: "4px" }} />
  //                 </a>
  //                 :
  //                 <a className='text-muted' onClick={() => setViewConstituents(true)}>
  //                   View Constituents<AiOutlineDown size={14} style={{ marginLeft: "4px" }} />
  //                 </a>
  //               :
  //               <></>
  //           }
  //         </div>
  //       </div>
  //       {
  //         type === 3 && viewConstituents ?
  //           <div className="aligner-wrapper">
  //             <ConstituentsTable fundConstituents={mintboxConstituents} />
  //           </div>
  //           :
  //           <></>
  //       }
  //     </div>
  //   </>
  // );
  return <div className='border-bottom'>
    <div className="preview-item">
      <div className="preview-thumbnail">
        {
          type === 1 || type === 2 ?
            <AMCIcon amcName={AMCName} height='40px' width='40px' />
            :
            <MintboxIcon id={mintboxId} height='40px' width='40px' />
        }
      </div>
      <div className="preview-item-content d-sm-flex flex-grow">
        <div className="flex-grow">
          <h6 className="preview-subject">{fundName}</h6>
          <div className='text-muted'>
            <span>1 Day </span>
            {
              change > 0 ?
                <span style={{ color: 'var(--primary)' }}><AiFillCaretUp size={20} style={{ verticalAlign: "bottom" }} /> {CurrencyFormat(change, "long", 2)} ({changePercentage.toFixed(2)}%)</span>
                :
                change < 0 ?
                  <span style={{ color: 'var(--danger)' }}><AiFillCaretDown size={20} style={{ verticalAlign: "bottom" }} /> {CurrencyFormat(change, "long", 2)} ({changePercentage.toFixed(2)}%)</span>
                  :
                  <span> {CurrencyFormat(change, "long", 2)} ({changePercentage}%)</span>
            }
          </div>
        </div>
        <div className="mr-auto text-sm-right pt-2 pt-sm-0">
          <div style={{ marginBottom: "4px" }}>
            {CurrencyFormat(balance, "short")}
          </div>
          <div className='text-muted mb-2'>
            <RedGreenText text={xirr.toFixed(2) + '%'} value={xirr} />
          </div>
          {
            type === 3 ?
              viewConstituents ?
                <a className='text-muted' onClick={() => setViewConstituents(false)}>
                  Hide Constituents<AiOutlineUp size={14} style={{ marginLeft: "4px" }} />
                </a>
                :
                <a className='text-muted' onClick={() => setViewConstituents(true)}>
                  View Constituents<AiOutlineDown size={14} style={{ marginLeft: "4px" }} />
                </a>
              :
              <></>
          }
        </div>
      </div>
    </div>
    {
      type === 3 && viewConstituents ?
        <div className="aligner-wrapper pb-4">
          <ConstituentsTable fundConstituents={mintboxConstituents} />
        </div>
        :
        <></>
    }
  </div>
};

export default FundCard;
