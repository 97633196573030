import { Card, Divider } from "antd";
import ActivityLoaderMintbox from "Assets/styles/components/ActivityLoaderMintbox";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CardHeader } from "../../Components/Atoms/CardHeader";
import AdminServices from "../../services/api/AdminServices";

const AllCategories = () => {

    const [allCategories, setAllCategories] = useState([])
    const [loading, setLoading] = useState(true)

    const getAllCategories = async () => {
        let res = await AdminServices.getBlogCategories().then(res => res);
        if (res.type === true) {
            setAllCategories(res.data?.filter((el) => el?.IsKnowledgeBase !== 1))
        }
        setLoading(false);
    }

    const handleDelete = async (id) => {
        let res = await AdminServices.deleteBlogCategory(id);
        if (res?.type === true) {
            toast.info('Category deleted.')
            setAllCategories(prev => prev.filter((el) => el.UID !== id));
            await getAllCategories();
        }
        else {
            toast.error('Something went wrong.')
        }
        console.log(res);
    }
    useEffect(() => {
        getAllCategories();
    }, [])

    return (
        <React.Fragment>
            <Card style={{ padding: 0 }} bodyStyle={{ padding: "1rem" }}>
                <CardHeader heading={'Create category'} />
                <Divider style={{ margin: "1rem 0" }} />
                {loading ? <ActivityLoaderMintbox /> : <div style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: "1.25rem" }}>
                    {allCategories?.map((item, i) => (
                        <Card style={{ padding: "0rem", background: "var(--grey3)", border: "1px solid var(--grey2)" }} hoverable={false} key={i}
                            bodyStyle={{ height: "100%", padding: "1rem" }}>
                            <div className="d-flex flex-column justify-content-between" style={{ gap: "0.5rem", height: "100%" }}>
                                <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
                                    <div className='textMD w-500'>
                                        {item.Category}
                                    </div>
                                    <Divider style={{ margin: "0" }} />
                                    <div className='textSM'>
                                        {item.Description}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end" style={{ gap: "0.75rem" }}>
                                    <button className="btnRed" onClick={() => handleDelete(item.UID)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>}
            </Card>
        </React.Fragment>
    )
}

export default AllCategories