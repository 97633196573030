import React from "react";
import { Redirect, Route } from "react-router-dom";
import { cyrb53 } from "../libs/cyrb53Hasher";

function AdminProtectedRoutes({ component: Component, ...restOfProps }) {

    const isAuthenticated = localStorage.getItem("isAdmin");
    const ifaData = localStorage.getItem('ifa');
    const ifaEmail = JSON.parse(ifaData||"{}")?.userData?.email
    return (
        <Route
            {...restOfProps}
            render={(props) =>ifaEmail?.endsWith('@mintbox.ai')?<Component {...props} />:
                Number(isAuthenticated) === cyrb53("yesitisamintboxadmin") && restOfProps?.path==='/clients/list' ? <Component {...props} /> : 
                Number(isAuthenticated) === cyrb53("yesitisamintboxadmin")?<Redirect to='/clients/list' />:
                <Redirect to="/login" />
            }
        />
    );
}

export default AdminProtectedRoutes;
