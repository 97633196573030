import React, { useRef } from 'react';
import { Table } from 'antd';
import { v4 } from 'uuid';

function withScrollToTop(WrappedComponent) {
    return function WithScrollToTop(props) {
        const tableRef = useRef(null);
        const uniqueId = v4();
        const handlePageChange = (page, pageSize) => {
            const tableTop = tableRef.current.getBoundingClientRect().top + window.pageYOffset - 80;
            window.scrollTo({ top: tableTop, behavior: "smooth" });
            if (props.onChange) {
                props.onChange(page, pageSize);
            }
        };

        return (
            <div ref={tableRef} id={uniqueId}>
                <WrappedComponent
                    {...props}
                    ref={props?.tableRef ? props?.tableRef : null}
                    pagination={{
                        defaultPageSize: props.paginationSize || 10,
                        position: ['bottomCenter'],
                        showSizeChanger: false,
                        showPrevNextJumpers: true,
                        className: "textSM",
                        hideOnSinglePage: true,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
                            }
                            if (type === 'next') {
                                return <a {...originalElement.props} className="textSM mx-2">Next</a>;
                            }
                            return originalElement;
                        },
                        ...props.pagination,
                        onChange: handlePageChange
                    }}
                />
            </div>
        );
    };
}

const MyTable = withScrollToTop(Table);

export default MyTable;
