import React from "react";
import { Drawer } from "antd";

const CustomDrawer = ({ title, isOpen, toggle, children, width = "52.6rem" }) => {

    const onClose = () => {
        toggle();
    }

    return <Drawer title={title} placement={window.innerWidth > 760 ? "right" : "bottom"} onClose={onClose} open={isOpen} push={false}
        footerStyle={{ textAlign: 'right', borderTop: 'none', padding: '1.25rem' }}
        width={window.innerWidth > 760 ? width : '100vw'}
        height={window.innerWidth > 760 ? "100vh" : '80vh'}
        bodyStyle={{ padding: '0 1.25rem' }} closable={false}
        footer={<button className="btnBlack ms-auto mobileButton" onClick={onClose}>Close</button>}
        headerStyle={{ padding: '1.25rem', borderBottom: 'none' }}>
        {children}
    </Drawer>
}

export default CustomDrawer