function kFormatter(val) {
    if (val >= 10000000) {val = `₹ ${(val / 10000000).toFixed(2)} cr`}
    else if (val >= 100000) {val = `₹ ${(val / 100000).toFixed(2)} lac`}
    else if (val >= 1000) {val = `₹ ${(val / 1000).toFixed(2)} k`}
    else {val = normalFormatter(val)}
    return val.replace('-', '');
  }
  
  function normalFormatter(num,fractionDigits=0) {
    const a = new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: fractionDigits,
    }).format(num);
    return `₹ ${a}`;
  }
  
  export function CurrencyFormat(num=0, type="long",fractionDigits=0) {
    return type === "short" ? kFormatter(Number(num),fractionDigits) : normalFormatter(Number(num),fractionDigits)
  }
  