/* eslint-disable import/no-anonymous-default-export */
import db from "../../libs/db";
import { DataService } from "./DataService";
import { DataServiceOLDAPI } from "./DataServiceOLDAPI";

const MINTBOX_CLIENT_ID = "Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm";

const FamilyMemberAPIs = {
  addMember: async (id, email) =>
    DataService.post(`/dashboard/addMember?clientId=${id}&email=${email}`),
  removeMember: async (id, email) =>
    DataService.post(`/dashboard/removeMember?clientId=${id}&email=${email}`),
};

export default {
  FamilyMemberAPIs,
  //admin api

  getAUM: async (id = -1) => {
    return DataService.get("/Admin/getAum?ifaId=" + id);
  },

  getClientCount: async (id = -1) => {
    return DataService.get("/Admin/getClientCount?ifaId=" + id);
  },

  getClientGrowth: async (id = -1) => {
    return DataService.get("/Admin/getClientGrowth?ifaId=" + id);
  },

  getAumGrowth: async (id = -1) => {
    return DataService.get("/Admin/getAUMGrowth?ifaId=" + id + "&isFresh=false");
  },

  getAumByAmc: async (id = -1) => {
    return DataService.get("/Admin/getAUMByAMC?ifaId=" + id);
  },

  getAumByCategory: async (id = -1) => {
    return DataService.get("/Admin/getAUMByCategory?ifaId=" + id);
  },

  getAumBySubCategory: async (id = -1) => {
    return DataService.get("/Admin/getAUMBySubCategory?ifaId=" + id);
  },
  getAumByFund: async (id = -1) => {
    return DataService.get("/Admin/getAUMByFund?ifaId=" + id);
  },

  addBankAccount: async (data) =>
    DataService.post("/SignUp/addBankAccount", data),
  updateBankAccount: async (data) =>
    DataService.post("/SignUp/updateBankAccount", data),

  getAccessToken: async () => {
    return DataService.get("/Auth/getToken?clientId=" + MINTBOX_CLIENT_ID);
  },

  getClient: async (id) => {
    return DataService.get("/Dashboard/getClient?clientid=" + id);
  },

  emailExist: async (id, data, optionalHeader) => {
    return DataService.get(
      "/SignUp/EmailExists?email=" + id,
      data,
      optionalHeader
    );
  },
  clientExists: async (value, type) => {
    return DataService.get("/SignUp/ClientExists?type=" + type + "&value=" + value);
  },
  ifaExists: async (value, type = "email") => {
    return DataService.get("/IFA/IFAExists?type=" + type + "&value=" + value);
  },
  generateOTP: async (value, type) => {
    return DataService.post("/SignUp/generateOTP?type=" + type + "&value=" + value);
  },
  login: async (clientId) => {
    return DataService.post("/SignUp/login?clientId=" + clientId);
  },
  IFAlogin: async (ifaId) => {
    return DataService.post("/IFA/login?ifaid=" + ifaId);
  },
  loginwithPass: async (data) => {
    return DataService.post("/SignUp/login", data);
  },
  validatePin: async (data) => {
    return DataService.post("/SignUp/validatePIN", data);
  },
  sendOTP: async (phone, email) => {
    return DataService.post("/SignUp/generateOTP?mobileNumber=" + phone + "&email=" + email);
  },

  forgotPass: async (data) => {
    return DataService.post("/SignUp/generatePasswordLink", data);
  },

  getDashboardData: async () => {
    return DataService.get(
      "/Dashboard/getDashboardContent"
    );
  },

  getPortfolio: async (id) => {
    const res = await DataService.get("/portfolio/getPortfolioSnapshot?clientId=" + id + "&ifaId=0&isFresh=false").then(res => res);
    if (res.type) {
      const a = await JSON.parse(res.data);
      let aa = a.reduce((aa, f) => { return { ...aa, ...f } }, {})
      // await db.set(db.model.portfolio, JSON.stringify(aa))
      return aa
    }
    else {
      // toast.error("error")
      return null
    }
  },

  getPortfolioHoldings: async (id) => {
    const res = await DataService.get("/portfolio/getPortfolioHoldings?clientId=" + id + "&ifaId=0&isFresh=false").then(res => res);
    if (res.type) {
      const a = await JSON.parse(res.data);
      let aa = a.reduce((aa, f) => { return { ...aa, ...f } }, {})
      // await db.set(db.model.portfolioHoldings, JSON.stringify(aa))
      return aa
    }
    else {
      // toast.error("error")
      return null
    }
  },

  getPortfolioXIRRv1: async (id) => {
    const res = await DataService.get("/portfolio/getportfolioXIRRv1?clientId=" + id).then(res => res);
    if (res.type) {
      const a = await JSON.parse(res.data);
      const aa = a.reduce((aa, f) => { return { ...aa, ...f } }, {})
      // await db.set(db.model.portfolioXirr, JSON.stringify(aa))
      return aa
    }
    else {
      // toast.error("error")
      return null
    }
  },

  getPortfolioPerformancev1: async (id) => {
    const res = await DataService.get("/portfolio/getPortfolioPerformancev1?clientId=" + id + "&isFresh=false").then(res => res);
    if (res.type) {
      const a = await JSON.parse(res.data);
      let aa = a.reduce((aa, f) => { return { ...aa, ...f } }, {})
      // await db.set(db.model.portfolioPerformance, JSON.stringify(aa))
      return aa
    }
    else {
      // toast.error("error")
      return null
    }
  },

  getPortfolioAssetAllocation: async (id) => {
    const res = await DataService.get("/portfolio/getPortfolioAssets?clientId=" + id + "&isFresh=false").then(res => res);
    if (res.type) {
      const aa = res.data
      // await db.set(db.model.assetAllocation, JSON.stringify(aa))
      return aa
    }
    else {
      return null
    }
  },


  getClientGoals: async (id) => {
    const res = await DataService.get("/Dashboard/getClientGoals?clientid=" + id).then(res => res);
    // console.log("asddsa", res)
    if (res.type) {
      const a = res.data
      if (a !== null) {
        // await db.set(db.model.clientGoals, JSON.stringify(a))
        return a
      }
    }
    else {
      // toast.error("error")
      return null
    }
  },

  getTransactions: async (id) => {
    const res = await DataService.get(`/portfolio/getTransactions?clientId=${id}&isFresh=false`).then(res => res);
    // console.log("transactions", res)
    if (res.type) {
      const aa = res.data
      return aa
    } else {
      return null
    }
  },
  getScheduledOrders: async (id, ifaid) => {
    const res = await DataService.get(`/Dashboard/getScheduledOrders?clientId=${id}`).then(res => res);
    // console.log("check orders", res)
    if (res.type) {
      const aa = res.data
      return aa
    } else {
      return null
    }
  },


  getRecentlyViewed: async (id) => {
    return DataService.get("/Dashboard/getRecentlyViewed?clientId=" + id)
  },

  updateRecentlyViewed: async (data) => {
    return DataService.post("/Dashboard/updateRecentlyViewed", data);
  },

  getGoalByID: async (goalID) => {
    return DataService.get(`/Dashboard/getGoal?goalId=${goalID}`);
  },
  updateGoal: async (data) => {
    return DataService.post("/Dashboard/addGoal", data);
  },
  updateGoalName: async (goalID, goalName) => {
    return DataService.post(`/Dashboard/updateGoalName?goalId=${goalID}&goalName=${goalName}`);
  },

  portfolioDecrypt: async (encryptedString) => {
    return DataService.get(`/Portfolio/decrypt?encryptedString=${encryptedString}`);
  },

  rebalanceMintbox: async (clientId, mintboxId, goalID) => {
    return DataService.get(`/mintbox/getMintboxRebalanceData?clientId=${clientId}&MintboxId=${mintboxId}&GoalId=${goalID}`);
  },

  getScheduledSIPData: async (clientId, mintboxId, goalID) => {
    return DataService.get(`/mintbox/getScheduledSIPData?clientId=${clientId}&MintboxId=${mintboxId}&GoalId=${goalID}`);
  },

  getScheduledETFSIPData: async (clientId, mintboxId, fundcode) => {
    return DataService.get(`/mintbox/getScheduledSIPData?clientId=${clientId}&MintboxId=${mintboxId}&fundCode=${fundcode}`);
  },
  updateScheduledOrders: async (data) => {
    return DataService.post(`/Order/updateScheduledOrder`, data);
  },
  createMintbox: async (data) => {
    return DataService.post("/Mintbox/insertMintbox", data)
  },

  getAllMintboxes: async (id) => {
    return DataService.get(
      "/Mintbox/getAllMintboxes?clientId=" + id
    );
  },

  getAllETFs: async () => {
    return DataService.get("/Dashboard/getETFs")
  },

  getETFsById: async (ids) => {
    return DataService.get(
      "/Dashboard/getETFs?symbols=" + ids
    );
  },

  getETFQuantities: async (data) => {
    return DataService.post(
      "/StrategyAutomation/getFundQuantity", data
    );
  },

  getETFInfoBySymbol: async (id) => {
    return DataService.get("/Dashboard/getETFInfoBySymbol?symbol=" + id)
  },

  getFundByFundCode: async (id) => {
    return DataService.get(
      "/Dashboard/getFundByFundCode?fundCode=" + id
    );
  },

  getPeerReturnsByFundCode: async (id) => {
    return DataService.get(
      "/Dashboard/getPeerReturnsByFundCode?fundCode=" + id
    );
  },
  getRollingReturnsByFundCode: async (id) => {
    return DataService.get(
      "/Dashboard/getRollingReturnsByFundCode?fundCode=" + id
    );
  },

  getTopHoldings: async (id) => {
    return DataService.get(
      "/dashboard/getCompaniesByFundCode?fundCode=" + id
    );
  },

  getSectorHoldings: async (id) => {
    return DataService.get(
      "/dashboard/getFundSectorsByFundCode?fundCode=" + id
    );
  },

  getMintboxById: async (id) => {
    return DataService.get(
      "/Mintbox/getMintboxById?mintboxId=" + id
    );
  },

  getMintboxFunds: async (userid, mintboxid) => {
    return DataService.get(`/portfolio/getMintboxPortfolio?clientId=${userid}&mintboxId=${mintboxid}`)
  },

  getMintboxConstituentsById: async (id) => {
    return DataService.get(
      "/Mintbox/getMintboxConstituentsById?mintboxId=" + id
    );
  },

  getXirr: async (data) => {
    return DataService.post(
      "/Dashboard/getXIRR", data
    );
  },

  getFundsData: async () => {
    return DataService.get("/dashboard/getFundsMaster?appId=" + MINTBOX_CLIENT_ID);
  },

  validateToken: async (data) => {
    return DataService.post("/SignUp/validatePasswordLink", data);
  },

  savePass: async (data) => {
    return DataService.post("/SignUp/savePassword", data);
  },

  createUser: async (data) => {
    return DataService.post("/SignUp/openAccount", data);
  },

  createIFA: async (data) => {
    return DataService.post("/IFA/openAccount", data);
  },

  getIFAClients: async (id = -1) => {
    return DataService.get("/IFA/getIFAClients?ifaId=" + id);
  },

  uploadDoc: async (id, data) => {
    return DataService.postImg("/SignUp/saveDocument?id=" + id, data);
  },

  enableMf: async (data) => {
    return DataService.post("/Dashboard/activateMutualFunds?email=" + data);
  },

  completeprofile: async (data) => {
    return DataService.post("/SignUp/completeAccount", data);
  },

  saveMFPIN: async (id, pin) => {
    return DataService.post(`/SignUp/savePin?clientId=${id}&pin=${pin}`);
  },

  checkkyc: async (data) => {
    return DataServiceOLDAPI.post("/Signzy/checkKYC", data);
  },

  verifyifsc: async (id) => {
    return DataService.get("/SignUp/getBankByIFSC?ifsc=" + id);
  },
  verifyZip: async (id) => {
    return DataService.get("/SignUp/getInfoByZip?zip=" + id);
  },
  getTaxStatus: async () => {
    return DataService.get("/bse/getTaxStaus");
  },

  getAnnualIncome: async () => {
    return DataService.get("/bse/getAnnualIncome");
  },

  getCountries: async () => {
    return DataService.get("/bse/getCountries");
  },

  getIdentificationType: async () => {
    return DataService.get("/bse/getIdentificationType");
  },

  getOccupation: async () => {
    return DataService.get("/bse/getOccupation");
  },

  getSourceofWealth: async () => {
    return DataService.get("/bse/getSourceofWealth");
  },

  getStates: async () => {
    return DataService.get("/bse/getStates");
  },

  getBanks: async () => {
    return DataService.get("/bse/getbanks");
  },

  createCustomGoal: async (data) => {
    return DataService.post("/Dashboard/addGoal", data);
  },
  addRisk: async (data) => {
    return DataService.post("/Dashboard/addRisk", data);
  },
  addEducationGoal: async (data) => {
    return DataService.post("/Dashboard/addEducationGoal", data);
  },
  addRetirmentGoal: async (data) => {
    return DataService.post("/Dashboard/addRetirementGoal", data);
  },

  getRiskScore: async (data) => {
    return DataService.post("/Dashboard/getRiskScore", data);
  },

  // watchlist 

  getWatchlist: async (id) => {
    return DataService.get(
      "/Dashboard/getWatchListInfo?clientId=" + id
    );
  },

  // smallcase 
  getSmallcasetoken: async (authid) => {
    return DataService.get("/SmallCase/getToken?authId=" + authid);
  },
  saveSmallCaseAuthId: async (data) => {
    return DataService.post("/SmallCase/saveAuthId", data);
  },
  saveSmallCaseOrder: async (id, data) => {
    return DataService.post("/SmallCase/saveOrderResponse?clientId=" + id, data);
  },
  // getSmallcaseTransactionId: async (data, authId = "") => {
  // 	return DataService.post("/SmallCase/getTransactionId?authId=" + authId, data);
  // },
  getSmallcaseTransactionId: async (data, authId = "", mintboxId = 0, goalId = 0) => {
    console.log("asjdnkaskdaksdkjasd")
    return DataService.post(`/SmallCase/getTransactionId?authId=${authId}&mintboxId=${mintboxId}&goalId=${goalId}`, data);
  },
  saveTransactionLogs: async (data) => {
    return DataService.post("/feeds/logTransaction", data);
  },

  getHoldings: async (id, authId = "") => {
    return DataService.get(`/SmallCase/fetchHoldings?clientId=${id}&authId=${authId}`);
  },


  updateHoldings: async (id) => {
    return DataService.post(`/portfolio/updateHoldings?clientId=${id}`);
  },
  //   updateComment: async (data) => {
  //     return DataService.post('/comment/update', data);
  //   },

  //   getComment: async (data) => {
  //     return DataService.post('/comment/listing', data);
  //   },

  //   getHomeBlockDiscover: async (data) => {
  //     return DataService.post('/block/home', data);
  //   }

  sendPushNotification: async (data) => {
    return DataService.post(`/Notification/sendPushNotification`, data);
  },

  getPushNotifications: async (id) => {
    return DataService.get(`/Notification/getNotificationsByClient?clientId=` + id);
  },

  getPortfolioReport: async (id) => {
    return DataService.get(`/Reports/getPortfolioReport?clientId=` + id);
  },

  getTransactionReport: async (id) => {
    return DataService.get(`/Reports/getTransactionReport?clientId=` + id);
  },

  deactivateAccount: async (id) => {
    return DataService.post(`/Signup/deleteAccount?clientId=` + id);
  },

  getRewardsStatus: async (id) => {
    return DataService.get(`/Dashboard/getRewardsStatus?clientId=` + id);
  },

  sendAppLinks: async (num) => {
    return DataService.post(`/Content/sendAppLinks?mobileNumber=` + num);
  },

  updateBenchmarkTRI: async (data) => {
    return DataService.post(`/Feeds/insertBenchmarkRecord`, data);
  },


  updateVRTable: async (data) => {
    return DataService.post(`/ValueResearch/updateTables`, data);
  },
  getInstrumentDesc: async (instrumentType) => {
    return DataService.get(`/Content/getInstrumentDesc?isFresh=true&instrumentType=` + instrumentType)
  },
  updateInstrumentDesc: async (data) => {
    return DataService.post(`/Content/updateInstrumentDesc`, data)
  },
  saveBlogCategory: async (data) => {
    return DataService.post(`/Content/saveBlogCategory`, data)
  },
  getBlogCategories: async () => {
    return DataService.get(`/Content/getBlogCategories`);
  },
  saveBlogSubCategory: async (data) => {
    return DataService.post(`/Content/saveBlogSubCategory`, data);
  },
  getBlogSubCategories: async () => {
    return DataService.get(`/Content/getBlogSubCategories`);
  },
  saveBlog: async (data) => {
    return DataService.post(`/Content/saveBlog`, data)
  },
  getBlogs: async () => {
    return DataService.get(`/Content/getBlogs`)
  },
  readBlogFile: async (fileName) => {
    return DataService.get(`/AWS/readFile?bucketName=mintbox-assets&folderName=blogs&fileName=` + fileName)
  },
  deleteBlog: async (blogId) => {
    return DataService.post(`/Content/deleteBlog?blogId=${blogId}`)
  },
  deleteBlogCategory: async (categoryId) => {
    return DataService.post(`/Content/deleteBlogCategory?categoryId=${categoryId}`)
  },
  deleteBlogSubCategory: async (subCategoryId) => {
    return DataService.post(`/Content/deleteBlogSubCategory?subCategoryId=${subCategoryId}`)
  },
  grantEarlyAccess: async (email, accessId = 1) => {
    return DataService.post(`/SignUp/grantEarlyAccess?email=${email}&accessId=${accessId}`)
  },
  // Razorpay
  createSubscription: async (data) => {
    return DataService.post(`/Payment/createSubscription`, data)
  },
  getSubscriptions: async (skip = 0) => {
    return DataService.get(`/Payment/getSubscriptions?skip=${skip}`)
  },
  cancelSubscription: async (id) => {
    return DataService.post(`/Payment/cancelSubscription`, id)
  },
  createClient: async (data) => {
    return DataService.post(`/Payment/createClient`, data)
  },
  insertSharpelyBenchData: async (data) => {
    return DataService.post(`/Dashboard/updateIndexPrices`, data)
  },
  updateAccess: async (data) => {
    return DataService.post(`/Dashboard/updateAccess`, data)
  },
  getAllClients: async (count = 10, skip = 0) => {
    return DataService.get(`/Payment/getAllClients?count=${count}&skip=${skip}`)
  },
  getSubscriptionsByClientId: async (id) => {
    return DataService.get(`/Payment/getSubscriptionsByClientId?id=${id}`)
  },
  getSubscriptionById: async (id) => {
    return DataService.get(`/Payment/getSubscriptionById?id=${id}`)
  },
  uploadImg: async (fileName, data) => {
    return DataService.postImg(`/AWS/updateToS3?filename=${fileName}&bucketName=mintbox-assets&folderName=app-images/blogimages`, data)
  },
  insUpBanksNPA: async (data) => {
    return DataService.post(`/Dashboard/InsUpBanksNPA`, data)
  },
  getPPSubscriptions: async (id) => {
    return DataService.get(`/Payment/getPPSubscriptions?clientId=${id}`)
  },
  getPPTrans: async (id, subId) => {
    return DataService.get(`/Payment/getPPTransactions?clientId=${id}&subscriptionId=${subId}`)
  },
  getPPAuthReqStatus: async (id) => {
    return DataService.get(`/Payment/getPPAuthReqStatus?authRequestId=${id}`)
  },
  getPPAuthRequests: async (id) => {
    return DataService.get(`/Payment/getPPAuthRequests?subscriptionId=${id}`)
  },
  initiatePPDebit: async (data) => {
    return DataService.post(`/Payment/recurringInit`, data)
  },
  recurringExec: async (data) => {
    return DataService.post(`/Payment/recurringExec`, data)
  },
  createRazorClient: async (data) => {
    return DataService.post(`/Payment/razor/createClient`, data);
  },
  getRazorPlans: async () => {
    return DataService.get(`/Payment/razor/getPlans`);
  },
  getRazorPlanById: async ({ id }) => {
    return DataService.get(`/Payment/razor/getPlanById?id=${id}`)
  },
  getRazorSubsriptions: async ({ skip = 0 }) => {
    return DataService.get(`/Payment/razor/getSubscriptions?skip=${skip}`)
  },
  getRazorSubscriptionById: async ({ id }) => {
    return DataService.get(`/Payment/razor/getSubscriptionById?id=${id}`)
  },
  getRazorSubscriptionsByCustId: async ({ id }) => {
    return DataService.get(`/Payment/razor/getSubscriptionsByCustId?id=${id}`)
  },
  createRazorSubscription: async (data) => {
    return DataService.post(`/Payment/razor/createSubscription`, data)
  },
  updateRazorSubscription: async ({ data, subId }) => {
    return DataService.post(`/Payment/razor/updateSubscription?subId=${subId}`, data)
  },
  cancelRazorSubscription: async ({ id }) => {
    return DataService.post(`/Payment/razor/cancelSubscription?id=${id}`)
  },
  pauseRazorSubscription: async ({ id }) => {
    return DataService.post(`/Payment/razor/pauseSubscription?id=${id}`)
  },
  resumeRazorSubscription: async ({ id }) => {
    return DataService.post(`/Payment/razor/resumeSubscription?id=${id}`)
  },
  createRazorPaymentLink: async (data) => {
    return DataService.post(`/Payment/razor/createPaymentLink`, data)
  },
  getAllRazorPaymentLinks: async () => {
    return DataService.get(`/Payment/razor/getAllPaymentLinks`)
  },
  getRazorPaymentLinkById: async ({ id }) => {
    return DataService.get(`/Payment/razor/getPaymentLinkById?id=${id}`)
  },
  sendRazorPaymentLinkReminder: async ({ id, medium }) => {
    return DataService.post(`/Payment/razor/sendPaymentLinkReminder?id=${id}&medium=${medium}`)
  },
  updateToS3: async ({ fileName, folderName, data }) => {
    return DataService.postImg(`/AWS/updateToS3?filename=${fileName}&bucketName=mintbox-assets&folderName=${folderName}`, data)
  },
  readFile: async ({ fileName, folderName }) => {
    return DataService.get(`/AWS/readFile?bucketName=mintbox-assets&folderName=${folderName}&fileName=` + fileName)
  },
  updateSharpelyAccess: async (data) => {
    return DataService.post(`/Dashboard/updateSharpelyAccess`, data);
  },
  getPPDashboardData: async () => {
    return DataService.get(`/Payment/getPPDashboardData`);
  }
};
